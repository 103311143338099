import React, { useEffect, useState } from "react"; // Import necessary React hooks and components
import gasStation from "../../assets/icons/Gas-Station.png"; // Import asset images for icons
import newTransactionImg from "../../assets/icons/new-transaction-img.png";
import searchIcon from "../../assets/icons/search.png";
import filterIcon from "../../assets/icons/filterIcon.png";
import { UserDetails } from "../popUp/home-page-popUp/UserDetails"; // Import pop-up components
import { AddFuelTransaction } from "../popUp/home-page-popUp/AddFuelTransaction";
import { AddNewTransaction } from "../popUp/home-page-popUp/AddNewTransaction";
import { useForm } from "react-hook-form"; // Import useForm hook from react-hook-form
import * as yup from "yup"; // Import Yup for form validation schema
import { yupResolver } from "@hookform/resolvers/yup"; // Resolver to connect Yup schema to react-hook-form
import { useAppDispatch, useAppSelector } from "../../features/hook"; // Custom hook for dispatching actions
import {
  findTransaction,
  getCategories,
  getTransactionList,
  getUserDetails,
  getUserCategories,
} from "../../features/redux-toolkit/transactionSlice"; // Action for getting categories from Redux store
import "./overView.css"; // Import the component's CSS
import { IPaymentdetails } from "../../features/redux-toolkit/authSlice";

/*
 * Name: IncomeExpenseOverView.tsx
 * Description: Navbar component providing navigation and logout functionality.
 * Version: 1.0.0
 */

// Define the structure of the form's data
interface FormValues {
  transactionType?: string;
  documentNumber?: string;
  fromDate?: Date;
  toDate?: Date;
  state?: string;
  description?: string;
  category?: {
    id?: number;
    name?: string;
  };
  subCategory?: {
    id?: number;
    name?: string;
  };
  fromAmount?: number;
  toAmount?: number;
  paidUsing?: string;
}

// Validation schema for the form using Yup
const schema = yup.object().shape({
  transactionType: yup.string().optional(), // Type field is optional
  fromDate: yup
    .date()
    .nullable()
    .optional()
    .typeError("Invalid date format")
    .max(new Date().toISOString(), "Date cannot be in the future")
    .test("valid-date", "Invalid date", (value) => {
      return !value || value instanceof Date;
    }),
  toDate: yup
    .date()
    .nullable()
    .optional()
    .max(new Date().toISOString(), "Date cannot be in the future")
    .typeError("Invalid date format"),
  documentNumber: yup.string().optional(), // Document number is optional
  category: yup
    .object()
    .shape({
      id: yup
        .number()
        .nullable()
        .test(
          "is-selected",
          "Select a valid category.",
          (value: any) => value === null || value > 0
        ),
      name: yup.string().optional(),
    })
    .nullable(),
  subCategory: yup
    .object()
    .nullable()
    .shape({
      id: yup
        .number()
        .nullable()
        .test(
          "is-selected",
          "Select a valid subcategory.",
          (value: any) => value === null || value > 0
        ),
      name: yup.string().optional(),
    })
    .nullable()
    .test(
      "subcategory-depends-on-category",
      "Subcategory is required if a category is selected.",
      (subCategory: any, context) => {
        const category = context.parent.category;
        if (category && category.id) {
          return subCategory && subCategory.id; // Ensure subcategory is valid if category is selected
        }
        return true; // Pass validation if no category is selected
      }
    ),
  paidUsing: yup.string().optional(),
  state: yup.string().optional(),
  description: yup.string().optional(),
  fromAmount: yup
    .string()
    .optional()
    .test(
      "maxDecimalPlaces",
      "Amount should have at most two decimal places.",
      (value) => {
        if (!value) return true; // Pass validation if the value is empty (optional field).
        return /^\d+(\.\d{1,2})?$/.test(value.trim()); // Ensure no spaces and validate decimal places.
      }
    ),
  toAmount: yup
    .string()
    .optional()
    .test(
      "maxDecimalPlaces",
      "Amount should have at most two decimal places.",
      (value) => {
        if (!value) return true; // Pass validation if the value is empty (optional field).
        return /^\d+(\.\d{1,2})?$/.test(value.trim()); // Ensure no spaces and validate decimal places.
      }
    )
    .when("fromAmount", (fromAmount: any, schema) => {
      if (fromAmount) {
        return schema.test(
          "greaterThanOrEqual",
          "To Amount should be greater than or equal to From Amount.",
          (toAmount: any) => {
            // Convert both values to numbers for comparison.
            if (!toAmount) return true; // Pass validation if toAmount is empty
            const from = parseFloat(fromAmount);
            const to = parseFloat(toAmount);
            if (isNaN(from) || isNaN(to)) return true; // Skip if not valid numbers
            return to >= from;
          }
        );
      }
      return schema; // If fromAmount is not provided, keep the original schema.
    }),
});

// Main functional component for the overview page
export const IncomeExpenseOverView = () => {
  const dispatch = useAppDispatch(); // Get dispatch function from custom hook
  const { categories } =
    useAppSelector((state: any) => state.transaction) || []; // Access categories from the store
  const { userPayment } =
    useAppSelector((state: any) => state.transaction) || []; // Access payments from the store
  const [isFilterOpen, setFilterOpen] = useState(false); // Manage filter section visibility
  const [subCategories, setSubCategories] = useState(
    [{ id: 0, name: "" }] // Default subcategories from the first category
  );

  // Function to toggle the filter section
  const openFilterArea = () => {
    setFilterOpen((prev) => !prev);
  };

  // Use the form hook for handling form state and validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema), // Apply Yup validation schema
    defaultValues: {
      category: { id: null, name: "" },
      subCategory: { id: null, name: "" },
      toDate: null,
      fromDate: null,
    },
  });

  const watchedValues = watch(); // Watch form values to detect changes

  // Check if any field is filled
  const isAnyFieldFilled = Object.values(watchedValues).some((value: any) => {
    if (
      typeof value === "object" &&
      value !== null &&
      !Array.isArray(value) &&
      !(value instanceof Date) &&
      value.hasOwnProperty("id")
    ) {
      return value.id !== null && value.id !== undefined && value.id !== 0;
    }
    return value !== "" && value !== null && value !== undefined;
  });

  // Handle category change and update subcategories based on the selected category
  const handleCategoryChange = (event: any) => {
    const selectedCategoryId = parseInt(event.target.value);
    const selectedCategory = categories.find(
      (cat: any) => cat.id === selectedCategoryId
    );

    if (selectedCategory) {
      setValue("category", {
        id: selectedCategory.id,
        name: selectedCategory.name,
      });
      clearErrors("category");

      // Set the subcategory based on the selected category
      setSubCategories(selectedCategory.subCategory || []);

      // Set the default subcategory based on the first subcategory of the selected category
      if (
        selectedCategory.subCategory &&
        selectedCategory.subCategory.length > 0
      ) {
        setValue("subCategory", {
          id: selectedCategory.subCategory[0].id,
          name: selectedCategory.subCategory[0].name,
        });
        clearErrors("subCategory.id");
        clearErrors("subCategory.name");
      }
    } else {
      setSubCategories([]);
      setValue("subCategory", {
        id: 0,
        name: "",
      });
    }
  };

  const handleFromDate = (event: any) => {
    const fromDate = new Date(event.target.value); // Ensure it's a Date object
    const toDate = getValues("toDate");

    // Check if To Date is not selected or invalid
    if (!toDate) {
      setError("toDate", {
        type: "manual",
        message: "Select To Date",
      });
    } else if (fromDate > toDate) {
      // Check if From Date is greater than To Date
      setValue("toDate", event.target.value); // Reset To Date to match From Date
      setError("toDate", {
        type: "manual",
        message: "To Date should be greater than or equal to From Date",
      });
    } else {
      clearErrors("toDate"); // Clear errors if valid
    }
  };

  const handleToDateChange = (event: any) => {
    const toDate = new Date(event.target.value); // Ensure it's a Date object
    const fromDate = getValues("fromDate");

    if (fromDate && toDate && fromDate > toDate) {
      setValue("toDate", fromDate); // Resetting toDate if invalid
      setError("toDate", {
        type: "manual",
        message: "To Date should be greater than or equal to From Date",
      });
    } else {
      clearErrors("toDate"); // Clear errors if valid
    }
  };

  // Handle subcategory change and update the subcategory value
  const handleSubCategoryChange = (event: any) => {
    const selectedSubCategoryId = parseInt(event.target.value);

    const selectedSubCategory = subCategories.find(
      (subCat: any) => subCat.id === selectedSubCategoryId
    );

    if (selectedSubCategory) {
      setValue("subCategory", {
        id: selectedSubCategory.id,
        name: selectedSubCategory.name,
      });
      clearErrors("subCategory.name");
    }
  };

  // Clear the form filters
  const handleClearFilters = () => {
    reset(); // Reset form values to default
    setSubCategories([]);
    if (isAnyFieldFilled) {
      dispatch(getTransactionList());
    }
  };

  const formatDate = (dateObj: any) => {
    const date = new Date(dateObj);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };
  // Submit the form data
  const onSubmit = (data: any) => {
    // collapse find window
    setFilterOpen(false);
    // Map the data to set the necessary transformations
    if (data.category.id > 0) {
    } else {
      data.category = { id: null, name: "" };
      data.subCategory = { id: null, name: "" };
    }
    if (data.fromDate && data.toDate) {
      data.fromDate = formatDate(data.fromDate);
      data.toDate = formatDate(data.toDate);
    }
    data.toAmount = parseFloat(data.toAmount) || "";
    data.fromAmount = parseFloat(data.fromAmount) || "";
    // Filter the data to keep only fields with a value
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => {
        if (value && typeof value === "object" && "id" in value) {
          // Handle objects with `id` property
          return value?.id !== undefined && value?.id !== null;
        }
        // Handle primitive values
        return value !== null && value !== undefined && value !== "";
      })
    );

    dispatch(findTransaction(filteredData));
  };

  // Ensure two decimal places for numeric inputs
  const handleTwoPrecision = (e: any, fieldName: keyof FormValues) => {
    let value = e.target.value.replace(/[^0-9.]|\.(?=.*\.)/g, ""); // Allow only numbers and one decimal point
    if (value === "") {
      setValue(fieldName, "", { shouldValidate: true });
      return;
    }
    const regex = /^\d+(\.\d{0,2})?$/; // Match numbers with 1 or 2 decimal places
    if (regex.test(value)) {
      setValue(fieldName, value, { shouldValidate: true });
    }
  };

  useEffect(() => {
    // Only fetch user-specific categories
    dispatch(getUserCategories());
  }, []);

  useEffect(() => {
    dispatch(getUserDetails()); // Fetch payments on component mount
  }, []);

  return (
    <>
      <div className="container-flued px-4">
        <div className="row ">
          <div className="col-12 d-flex justify-content-start justify-content-md-center align-items-start align-items-md-center py-5 px-3 w-100 flex-column flex-md-row">
            <div className="overview-title">INCOME & EXPENSE OVERVIEW</div>
            <div className="overview-line "></div>
          </div>

          <div className="col-12 d-flex justify-content-start gap-4">
            <button
              className="custom-gradient-btn gap-3 d-flex align-items-center"
              data-bs-toggle="modal"
              data-bs-target="#incomeExpenseModal"
            >
              <img src={newTransactionImg} alt="" />
              Add New Transaction
            </button>
            <button
              className="custom-ghost-btn gap-3 d-flex align-items-center"
              data-bs-toggle="modal"
              data-bs-target="#createFuelTransactionModal"
            >
              <img src={gasStation} alt="" />
              Add Fuel Transaction
            </button>
          </div>
        </div>

        {/* Filter Section */}
        <div className="row pt-5">
          <div className="col-12 custom-box-shadow">
            <div className="filter-title d-flex justify-content-start align-items-center gap-3"
              onClick={openFilterArea}>
              Find
              <img
                src={filterIcon} alt=""
                className="custom-pointer"
              />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={`filter-section col-12  ${isFilterOpen ? "open" : "closed"
                }`}
            >
              <div className="filter-row">
                <div className="col-12 col-sm-6 col-md-3">
                  <label className="label-text large" title="Type (Income or Expense)">Type (Income or Expense)</label>
                  <select
                    className="custom-select-popup"
                    {...register("transactionType")}
                  >
                    <option value="">Select Type</option>
                    <option value="INCOME">Income</option>
                    <option value="EXPENSE">Expense</option>
                    <option value="EXPENSE_FUEL">{"Expense ( Fuel )"}</option>
                  </select>
                </div>
                <div className="col-12 col-sm-6 col-md-3">
                  <label className="label-text">From Date</label>
                  <input
                    type="date"
                    id="fromDate"
                    onInput={(event) => {
                      handleFromDate(event);
                    }}
                    className="custom-select-popup date-input-color"
                    {...register("fromDate", { valueAsDate: true })} // use valueAsDate to convert to Date object
                  />
                  {errors.fromDate && (
                    <p className="error-text">{errors.fromDate.message}</p>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-md-3">
                  <label className="label-text">To Date</label>
                  <input
                    type="date"
                    className="custom-select-popup date-input-color"
                    onInput={(event) => {
                      handleToDateChange(event);
                    }}
                    {...register("toDate", { valueAsDate: true })} // use valueAsDate to convert to Date object
                  />
                  {errors.toDate && (
                    <p className="error-text">{errors.toDate.message}</p>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-md-3">
                  <label className="label-text">Document No</label>
                  <input
                    type="text"
                    placeholder="Enter Document Number"
                    className="custom-select-popup"
                    {...register("documentNumber")}
                  />
                </div>
              </div>

              <div className="filter-row">
                <div className="col-12 col-sm-6 col-md-3">
                  <label className="label-text">Category</label>
                  <select
                    className="custom-select-popup"
                    id="category"
                    {...register("category.id")} // Registering category id for validation
                    onChange={handleCategoryChange} // Trigger handleCategoryChange on selection
                  >
                    <option value="" disabled>
                      Select Category
                    </option>
                    {categories.map((cat: any) => (
                      <option key={cat.id} value={cat.id}>
                        {cat.name}
                      </option>
                    ))}
                    {errors.category && (
                      <p className="error-text">{errors.category.message}</p>
                    )}
                    {errors.category?.id && (
                      <p className="error-text">{errors.category.id.message}</p>
                    )}
                  </select>
                </div>
                <div className="col-12 col-sm-6 col-md-3">
                  <label className="label-text">Sub-Category</label>
                  <select
                    className="custom-select-popup"
                    id="subCategory"
                    {...register("subCategory.id")} // Register subcategory ID
                    onChange={handleSubCategoryChange} // Trigger subcategory change handler
                  >
                    <option value="" disabled>
                      Select Sub-Category
                    </option>
                    {subCategories.length > 0 ? (
                      <>
                        {subCategories.map((subCat: any) => (
                          <option key={subCat.id} value={subCat.id}>
                            {subCat.name}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option value="">Select Sub-Category</option>
                    )}
                  </select>
                  {errors.subCategory?.name && (
                    <p className="error-text">
                      {errors.subCategory.name.message}
                    </p>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-md-3">
                  <label className="label-text"> Paid / Received Using</label>
                  <select
                    className="form-select custom-select-popup"
                    id="payment"
                    {...register("paidUsing")} // Registering the input for form handling
                  >
                    <option value="">Select payment option</option>
                    {userPayment &&
                      userPayment.map((payment: IPaymentdetails, index: number) => (
                        <option key={index} value={`${payment.id}`}>
                          {`${payment.paymentType}-XXXXXX${payment.paymentAccountNumber.slice(-4)}`}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-12 col-sm-6 col-md-3">
                  <label className="label-text">State</label>
                  <select
                    className="custom-select-popup"
                    {...register("state")}
                  >
                    <option value="">Select State</option>
                    <option value="New South Wales">New South Wales</option>
                    <option value="Victoria">Victoria</option>
                    <option value="Queensland">Queensland</option>
                    <option value="Western Australia">Western Australia</option>
                    <option value="South Australia">South Australia</option>
                    <option value="Tasmania">Tasmania</option>
                    <option value="Australian Capital Territory">
                      Australian Capital Territory
                    </option>
                    <option value="Northern Territory">
                      Northern Territory
                    </option>
                  </select>
                </div>
              </div>

              <div className="filter-row">
                <div className="col-12 col-sm-6 col-md-3">
                  <label className="label-text">Description</label>
                  <input
                    type="text"
                    className="custom-select-popup"
                    placeholder="Enter Description"
                    {...register("description")}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-3">
                  <label className="label-text" htmlFor="fromAmount">
                    From Amount
                  </label>
                  <input
                    value={getValues("fromAmount")}
                    className="custom-select-popup"
                    id="fromAmount"
                    placeholder="0"
                    onInput={(e: any) => handleTwoPrecision(e, "fromAmount")}
                    {...register("fromAmount")}
                  />
                  {errors.fromAmount && (
                    <p className="error-text">{errors.fromAmount.message}</p>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-md-3">
                  <label className="label-text" htmlFor="toAmount">
                    To Amount
                  </label>
                  <input
                    className="custom-select-popup"
                    value={getValues("toAmount")}
                    placeholder="0"
                    id="toAmount"
                    onInput={(e: any) => handleTwoPrecision(e, "toAmount")}
                    {...register("toAmount")}
                  />
                  {errors.toAmount && (
                    <p className="error-text">{errors.toAmount.message}</p>
                  )}
                </div>
                <div
                  className="col-12 col-sm-6 col-md-3 flex-column flex-md-row "
                  style={{
                    // marginTop: "28px",
                    gap: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    type="button"
                    onClick={handleClearFilters}
                    style={{ height: "44px" }}
                    className="custom-ghost-btn btn-center d-flex align-items-center justify-content-center"
                  >
                    Clear Filters
                  </button>
                  <button
                    style={{ height: "44px" }}
                    type="submit"
                    disabled={!isAnyFieldFilled}
                    className={
                      isAnyFieldFilled
                        ? "custom-gradient-btn  btn-center  gap-3 d-flex align-items-center justify-content-center"
                        : "custom-gradient-btn opacity-70 btn-center  gap-3 d-flex align-items-center justify-content-center"
                    }
                  >
                    {/* btn-center */}
                    <img src={searchIcon} alt="" /> Search
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <UserDetails />
      <AddFuelTransaction />
      <AddNewTransaction />
    </>
  );
};
