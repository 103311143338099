import { BrowserRouter, Routes, Route } from "react-router-dom"; // Importing React Router components for routing
import { PageNotFound } from "./app/components/constant/page-not-found/PageNotFound"; // Importing PageNotFound component
import { Login } from "./app/components/login-page/Login"; // Importing Login component
import { HomePage } from "./app/components/home-page/HomePage"; // Importing HomePage component
import { useAuth0 } from "@auth0/auth0-react"; // Importing Auth0 for authentication management
import { Loader } from "./app/components/constant/loader/Loader"; // Importing Loader component for loading state
import { useAppDispatch, useAppSelector } from "./app/features/hook";
import { Alerts } from "./app/components/constant/Alerts/Alerts";
import { useEffect } from "react";
import { userIsRegister } from "./app/features/redux-toolkit/authSlice";

/*
 * Name: App.tsx
 * Description: Main application component that handles routing and authentication state.
 * Version: 1.0.0
 */

function App() {
  const { user, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0(); // Destructuring user info and auth state from Auth0
  const { alertStatus, alertData, isPending } =
    useAppSelector((state: any) => state.transaction) || false; // Access categories from the store
  const dispatch = useAppDispatch(); // Get dispatch function from custom hook
  useEffect(() => {
    const fetchIdToken = async () => {
      try {
        if (isAuthenticated && user) {
          const claims = await getIdTokenClaims();
          const token = claims?.__raw;
          if (token) {
            if (!localStorage.getItem("_auth0Token")) {
              localStorage.setItem("_auth0Token", token);
              dispatch(userIsRegister()); // Dispatch only if not already done
            }
            localStorage.setItem("email", user.email || "");
          }
        }
      } catch (error) {
        console.error("Error fetching ID Token:", error);
      }
    };
  
    if (isAuthenticated && user) {
      fetchIdToken();
    }
  }, [isAuthenticated, user]);
  console.log(
    user,
    "user",
    isAuthenticated,
    "isAuthenticated",
    isLoading,
    "isLoading"
  );

  // Storing login status in localStorage if a user is authenticated
  if (user) {
    const login = "true"; // Setting login status
    localStorage.setItem("_auth0", login); // Storing login status in localStorage
    localStorage.setItem("username", `${user.nickname}`); // Storing login status in localStorage
  }

  return (
    <>
      {isLoading || isPending ? ( // Display loader while authentication state is loading  And API coles
        <>
          <Loader /> {/* Loader component */}
        </>
      ) : (
        <></>
      )}
      {alertStatus ? ( // Display loader while authentication state is loading
        <Alerts
          icon={alertData?.icon}
          title={alertData?.title}
          text={alertData?.text}
          confirmButtonText={alertData?.confirmButtonText}
        />
      ) : (
        <></>
      )}
      <BrowserRouter>
        <Routes>
          {/* {isAuthenticated || localStorage.getItem("_auth0") ? ( // Check if user is authenticated or has login status in localStorage */}
          {isAuthenticated || localStorage.getItem("_auth0") ? ( // Check if user is authenticated or has login status in localStorage
            <>
              <Route path="/" element={<HomePage />} />{" "}
              {/* Route for HomePage */}
              <Route path="*" element={<PageNotFound />} />{" "}
              {/* Catch-all route for PageNotFound */}
            </>
          ) : (
            <>
              <Route path="*" element={<PageNotFound />} />{" "}
              {/* Catch-all route for PageNotFound for unauthenticated users */}
              <Route path="/" element={<Login />} />{" "}
              {/* Route for Login page */}
            </>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App; // Exporting the App component
