import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../features/hook';
import { getYearToDateTotals } from '../../features/redux-toolkit/transactionSlice';
import './YearToDateTotals.css';

export const YearToDateTotals: React.FC = () => {
    const dispatch = useAppDispatch();
    const { yearToDateTotals } = useAppSelector((state: any) => state.transaction);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        dispatch(getYearToDateTotals());
    }, [dispatch]);

    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: 'AUD',
            minimumFractionDigits: 2
        }).format(amount);
    };

    // Calculate financial year
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const financialYear = currentDate.getMonth() >= 6 ? currentYear : currentYear - 1;
    const nextYear = financialYear + 1;
    const mobileFinancialYearLabel = `FY ${financialYear}/${nextYear.toString().slice(2)}`;
    const desktopFinancialYearLabel = `Financial Year ${financialYear}/${nextYear} to Date`;

    // Calculate net position
    const netIncome = (yearToDateTotals.netIncome || 0);
    const netIncomeClass = netIncome >= 0 ? 'positive' : 'negative';

    return (
        <div className="year-to-date-container">
            {/* Mobile View */}
            <div className="mobile-view">
                <div className="mobile-summary" onClick={() => setIsExpanded(!isExpanded)}>
                    <div className="mobile-summary-content">
                        <div className="fy-label">{mobileFinancialYearLabel}</div>
                        <div className={`net-position ${netIncomeClass}`}>
                            <span className="label">Total</span> {formatCurrency(netIncome)}
                        </div>
                    </div>
                    <div className="expand-button">
                        <span className="expand-text">{isExpanded ? 'Show Less' : 'Show Details'}</span>
                        <i className={`fas fa-chevron-${isExpanded ? 'up' : 'down'}`}></i>
                    </div>
                </div>
                
                {isExpanded && (
                    <div className="mobile-details">
                        <div className="mobile-section">
                            <div className="section-title">Income</div>
                            <div className="detail-row">
                                <span>Total</span>
                                <span className="positive">{formatCurrency(yearToDateTotals.totalIncomeWithGst || 0)}</span>
                            </div>
                            <div className="detail-row">
                                <span>GST</span>
                                <span className="positive">{formatCurrency(yearToDateTotals.totalIncomeGst || 0)}</span>
                            </div>
                            <div className="detail-row">
                                <span>Total ex GST</span>
                                <span className="positive">{formatCurrency(yearToDateTotals.totalIncomeWithoutGst || 0)}</span>
                            </div>
                        </div>
                        <div className="mobile-section">
                            <div className="section-title">Expenses</div>
                            <div className="detail-row">
                                <span>Total</span>
                                <span className="negative">{formatCurrency(yearToDateTotals.totalExpensesWithGst || 0)}</span>
                            </div>
                            <div className="detail-row">
                                <span>GST</span>
                                <span className="negative">{formatCurrency(yearToDateTotals.totalExpensesGst || 0)}</span>
                            </div>
                            <div className="detail-row">
                                <span>Total ex GST</span>
                                <span className="negative">{formatCurrency(yearToDateTotals.totalExpensesWithoutGst || 0)}</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Desktop View */}
            <div className="desktop-view">
                <div className="desktop-summary">
                    <div className="fy-label">{desktopFinancialYearLabel}</div>
                    <div className="desktop-totals">
                        <div className="total-item">
                            <span className="total-label">Income</span>
                            <span className="total-amount">
                                <span className="label">Total</span>
                                <span className="value">
                                    <span className="positive">{formatCurrency(yearToDateTotals.totalIncomeWithGst || 0)}</span>
                                </span>
                            </span>
                            <span className="total-amount">
                                <span className="label">GST</span>
                                <span className="value">
                                    <span className="positive">{formatCurrency(yearToDateTotals.totalIncomeGst || 0)}</span>
                                </span>
                            </span>
                            <span className="total-amount">
                                <span className="label">Total ex GST</span>
                                <span className="value">
                                    <span className="positive">{formatCurrency(yearToDateTotals.totalIncomeWithoutGst || 0)}</span>
                                </span>
                            </span>
                        </div>
                        <div className="total-item">
                            <span className="total-label">Expenses</span>
                            <span className="total-amount">
                                <span className="label">Total</span>
                                <span className="value">
                                    <span className="negative">{formatCurrency(yearToDateTotals.totalExpensesWithGst || 0)}</span>
                                </span>
                            </span>
                            <span className="total-amount">
                                <span className="label">GST</span>
                                <span className="value">
                                    <span className="negative">{formatCurrency(yearToDateTotals.totalExpensesGst || 0)}</span>
                                </span>
                            </span>
                            <span className="total-amount">
                                <span className="label">Total ex GST</span>
                                <span className="value">
                                    <span className="negative">{formatCurrency(yearToDateTotals.totalExpensesWithoutGst || 0)}</span>
                                </span>
                            </span>
                        </div>
                        <div className="total-item net">
                            <span className="total-label">Income less Expenses</span>
                            <span className={`total-amount ${netIncomeClass}`}>{formatCurrency(netIncome)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}; 