import React, { useEffect } from "react";
import Navbar from "./Navbar";
import SystemSetUp from "../popUp/SystemSetUp";
import { useAppSelector, useAppDispatch } from "../../features/hook";
import DataTable from "../data-table/DataTable";
import { IncomeExpenseOverView } from "../income-expense-overView/IncomeExpenseOverView";
import { userIsRegister } from "../../features/redux-toolkit/authSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { YearToDateTotals } from "../year-to-date/YearToDateTotals";

/*
 * Name: HomePage.tsx
 * Description: Main page component that renders the navbar or setup form based on authentication status.
 * Version: 1.0.0
 */

export const HomePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { validAuthZero } = useAppSelector((state: any) => state.transaction);
  const { userIsRegister: isUserRegistered } = useAppSelector((state: any) => state.auth);
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    // Check if we have an auth token but haven't checked registration
    const auth0Token = localStorage.getItem("_auth0Token");
    if (auth0Token && !isUserRegistered && !localStorage.getItem("systemFormStatus")) {
      dispatch(userIsRegister());
    }
  }, [dispatch, isUserRegistered]);

  return (
    <>
      {/* Conditionally rendering the SystemSetUp or Navbar based on authentication status */}
      {/* {!validAuthZero ||
      localStorage.getItem("systemFormStatus") === "false" ? ( */}

      {/* localStorage?.getItem("systemFormStatus") === "true" || validAuthZero */}
      {localStorage?.getItem("systemFormStatus") === "true" || validAuthZero ? (
        <>
          <div className="bg-image-white d-block">
            <Navbar />
            <IncomeExpenseOverView />
            <div style={{ paddingBottom: "120px" }}> {/* Add padding to prevent content from being hidden behind the sticky totals */}
              <DataTable />
            </div>
            <YearToDateTotals />
          </div>
        </>
      ) : (
        <>
          <SystemSetUp />
        </>
      )}
    </>
  );
};
