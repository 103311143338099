import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Column } from "primereact/column";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import "file-saver";
import "jspdf-autotable";

import printIcon from "../../assets/icons/print.png";
import resetIcon from "../../assets/icons/reset-grid-img.png";
import exportIcon from "../../assets/icons/export-csv-icon.png";
import {
  getTransactionList,
  transactionById,
} from "../../features/redux-toolkit/transactionSlice";
import { useAppDispatch, useAppSelector } from "../../features/hook";
import "./dataTable.css";
import { Calendar } from "primereact/calendar";
import { openModal } from "../../utils/modalUtils";
import { formatDate } from "../../utils/dateUtils";
import "jspdf-autotable"; // Import the plugin for autoTable

/*
 * Name: DataTableCom.tsx
 * Description: Displays a dynamic, sortable table for transactions (Income/Expense) with pagination, search, and export functionality.
 *              Features include the ability to export data to Excel and PDF, a global search filter, and sorting by multiple columns.
 *              The component also supports displaying transaction details like type, category, subcategory, amount, GST, and dates.
 * Version: 1.0.0
 */

function DataTableCom() {
  // React hook to manage global filter state (search input)
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [metaKey, setMetaKey] = useState(true);
  const dataTableRef = React.useRef<any>(null); // Reference for DataTable

  // Extract transaction list from Redux store using selector
  const { transactionList } = useAppSelector((state: any) => state.transaction);

  // Dispatch function to trigger actions in Redux
  const dispatch = useAppDispatch();

  // Fetch transaction list from the server when the component mounts
  useEffect(() => {
    dispatch(getTransactionList()); // Fetch data
  }, []); // Empty dependency array ensures this runs only once on mount

  const exportPdf = () => {
    if (transactionList.length > 0) {
      const { jsPDF } = require("jspdf");
      require("jspdf-autotable");

      const doc = new jsPDF();

      // Define the columns for the table
      const exportColumns = [
        { title: "Transaction", dataKey: "id" },
        { title: "Type", dataKey: "transactionType" },
        { title: "Document No.", dataKey: "documentNumber" },
        { title: "Description", dataKey: "description" },
        { title: "Cross Reference", dataKey: "crossReference" },
        { title: "Category", dataKey: "category.category" },
        { title: "Sub-category", dataKey: "subCategory.subCategory" },
        { title: "Date", dataKey: "date" },
        { title: "Suburb", dataKey: "suburb" },
        { title: "Income Amount", dataKey: "incomeAmount" },
        { title: "Income GST", dataKey: "incomeGst" },
        { title: "Expense Amount", dataKey: "expenseAmount" },
        { title: "Expense GST", dataKey: "expenseGst" },
      ];

      // Function to safely access nested keys
      const getNestedValue = (obj: any, key: any) => {
        return key
          .split(".")
          .reduce((acc: any, part: any) => acc && acc[part], obj);
      };

      // Prepare table rows by mapping the data keys from transactionList
      const tableData = transactionList.map((row: any) =>
        exportColumns.map((col) => {
          if (col.dataKey === "date" && getNestedValue(row, col.dataKey)) {
            return formatDate(getNestedValue(row, col.dataKey)); // Format the date if it's the 'date' field
          }
          return getNestedValue(row, col.dataKey) || ""; // Safely retrieve nested values or return empty string
        })
      );

      // Generate the table in the PDF with styles
      doc.autoTable({
        head: [exportColumns.map((col) => col.title)], // Extract column titles for the header
        body: tableData, // Use prepared rows
        styles: {
          fontSize: 5, // Adjust font size
          cellPadding: 1.5, // Adjust padding for better table spacing
          halign: "center", // Align header text to the center
          valign: "middle", // Align text vertically in the middle
        },
        headStyles: {
          fillColor: [0, 51, 102], // Dark blue color for headers
          textColor: [255, 255, 255], // White text color in headers
        },
        bodyStyles: {
          fillColor: [255, 255, 255], // White color for the body rows
          textColor: [0, 0, 0], // Black text for body
        },
      });

      // Format current date for file name
      const formattedDate = formatDate(new Date()); // Assuming formatDate is defined
      doc.save(`transaction_list_${formattedDate}.pdf`);
    }
  };

  const resetGrid = () => {
    // Clear global filter
    setGlobalFilter(null);

    // Reset DataTable filters
    if (dataTableRef.current) {
      dataTableRef.current.reset();
    }

    // Optionally re-fetch the transaction list
    dispatch(getTransactionList());
  };

  // Export Excel functionality
  const exportExcel = () => {
    if (transactionList.length > 0) {
      // Dynamically import 'xlsx' library to create Excel files
      import("xlsx").then((xlsx) => {
        // Convert transaction list into an Excel worksheet
        const flattenedData = transactionList.map((transaction: any) => ({
          Transaction: transaction.id,
          Type: transaction.transactionType,
          DocumentNumber: transaction.documentNumber,
          Description: transaction.description,
          CrossReference: transaction.crossReference,
          Category: transaction.category?.category || "", // Extract category name directly
          SubCategory: transaction.subCategory?.subCategory || "", // Extract subCategory name directly
          Date: formatDate(transaction.date),
          Suburb: transaction.suburb || "",
          IncomeAmount: transaction.incomeAmount,
          ExpenseGst: transaction.expenseGst,
          ExpenseAmount: transaction.expenseAmount,
          IncomeGst: transaction.incomeGst,
        }));

        // Convert the flattened data to an Excel worksheet
        const worksheet = xlsx.utils.json_to_sheet(flattenedData);

        // Create a workbook containing the worksheet
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };

        // Write workbook to a buffer (array) and trigger file download
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        // Call the helper function to save the file
        saveAsExcelFile(excelBuffer, "transaction_list");
      });
    }
  };

  // Helper function to save Excel file using FileSaver (currently commented out)
  const saveAsExcelFile = (buffer: any, fileName: string) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        const EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], { type: EXCEL_TYPE });
        const formattedDate = formatDate(new Date()); // Pass Date object
        module.default.saveAs(
          data,
          `${fileName}_export_${formattedDate}${EXCEL_EXTENSION}`
        );
      }
    });
  };

  // Open modal programmatically or ensure button toggles it and show transaction
  const showTransaction = async (data: any) => {
    const transactionId = {
      id: data.id,
    };

    try {
      if (
        data.transactionType === "EXPENSE" ||
        data.transactionType === "INCOME"
      ) {
        const response: any = await dispatch(transactionById(transactionId));
        console.log("Transaction response:", response.payload);
        
        if (response && response.payload && response.payload.status === 200) {
          openModal("incomeExpenseModal");
        } else {
          console.error(
            "Failed to load transaction:",
            response.payload?.statusText || "Unknown error"
          );
        }
      } else if (data.transactionType === "EXPENSE_FUEL") {
        const response: any = await dispatch(transactionById(transactionId));
        console.log("Fuel transaction response:", response.payload);
        
        if (response && response.payload && response.payload.status === 200) {
          openModal("createFuelTransactionModal");
          console.log("EXPENSE_FUEL transaction loaded successfully.");
        } else {
          console.error(
            "Failed to load EXPENSE_FUEL transaction:",
            response.payload?.statusText || "Unknown error"
          );
        }
      } else {
        console.warn("Unsupported transaction type:", data.transactionType);
        alert("Transaction type not supported.");
      }
    } catch (error) {
      console.error("An error occurred while showing the transaction:", error);
    }
  };

  // Add currency formatter function
  const formatCurrency = (value: any) => {
    if (value === null || value === undefined) return '';
    return new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: 2
    }).format(value);
  };

  // Add template functions for GST columns
  const incomeGstTemplate = (rowData: any) => {
    return formatCurrency(rowData.incomeGst);
  };

  const expenseGstTemplate = (rowData: any) => {
    return formatCurrency(rowData.expenseGst);
  };

  const incomeAmountTemplate = (rowData: any) => {
    return formatCurrency(rowData.incomeAmount);
  };

  const expenseAmountTemplate = (rowData: any) => {
    return formatCurrency(rowData.expenseAmount);
  };

  // Header component containing filter and export options
  const header = (
    <div className="row">
      <div className="col-12 custom-box-shadow d-flex justify-content-between px-4 flex-column flex-sm-row row-gap-4 row-gap-sm-0">
        <div className="col-sm-4 col-md-4 filter-title d-flex justify-content-around justify-content-sm-start align-items-center gap-3 px-0 text-black ">
          <span>Filter Results</span>

          {/* Search input field with an icon */}
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => setGlobalFilter(e.target.value)} // Update filter state on input change
              placeholder="Search..."
            />
          </IconField>
        </div>

        <div className="col-sm-8 col-md-8 d-flex align-items-center justify-content-around justify-content-md-end gap-3 gap-sm-4">
          {/* Reset button (not functional in the code) */}
          <div className="search-nav-btn gap-3">
            <img
              src={resetIcon} // Reset icon image
              alt="Reset"
              onClick={resetGrid}
              data-pr-tooltip="CSV" // Tooltip for the reset button
              className="custom-pointer"
            />
            <span className="search-btn-text">Reset Grid</span>
          </div>

          <span className="search-btn-divider">{"|"}</span>

          {/* Export to Excel button */}
          <div className="search-nav-btn gap-3">
            <img
              src={exportIcon} // Export icon image
              alt="Export"
              onClick={exportExcel} // Call exportExcel when clicked
              data-pr-tooltip="XLS" // Tooltip for the export button
              className="custom-pointer"
            />
            <span className="search-btn-text">Export</span>
          </div>

          <span className="search-btn-divider">{"|"}</span>

          {/* Export to PDF (Print) button */}
          <div className="search-nav-btn gap-3">
            <img
              src={printIcon} // Print icon image
              alt="Print"
              onClick={exportPdf} // Call exportPdf when clicked
              data-pr-tooltip="PDF" // Tooltip for the print button
              className="custom-pointer"
            />
            <span className="search-btn-text">Print</span>
          </div>
        </div>
      </div>
    </div>
  );

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const dateBodyTemplate = (rowData: any) => {
    return formatDate(rowData.date);
  };

  return (
    <>
      <div className="container-flued px-4">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <DataTable
                ref={dataTableRef} // Attach reference
                paginator
                scrollHeight="600px"
                scrollable
                rows={10} // Number of rows per page
                value={transactionList} // Data to be displayed in the table
                header={header} // Table header
                globalFilter={globalFilter} // Filter input for global search
                tableStyle={{ minWidth: "50rem" }} // Minimum width for the table
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" // Custom pagination template
                rowsPerPageOptions={[10, 25, 50]} // Options for rows per page
                dataKey="id" // Unique key for each row (e.g., for sorting or selection)
                emptyMessage="No data found." // Message displayed if no data is available
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" // Information about the current page
                selectionMode="single"
                selection={selectedProduct}
                onSelectionChange={(e) => showTransaction(e.value)}
                metaKeySelection={metaKey}
              >
                {/* Transaction column - ID of the transaction */}
                <Column field="id" header="Transaction" sortable />

                {/* Transaction Type column */}
                <Column field="transactionType" header="Type" sortable />

                {/* Document Number column */}
                <Column field="documentNumber" header="Document No." sortable />

                {/* Description column */}
                <Column field="description" header="Description" sortable />

                {/* Cross Reference column */}
                <Column
                  field="crossReference"
                  header="Cross Reference"
                  sortable
                />

                {/* Category column */}
                <Column field="category.category" header="Category" sortable />

                {/* Sub-Category column */}
                <Column
                  field="subCategory.subCategory"
                  header="Sub-Category"
                  sortable
                />

                {/* Date column - format the date */}
                <Column
                  field="date"
                  header="Date"
                  sortable
                  filterField="date"
                  dataType="date"
                  body={dateBodyTemplate}
                  filterElement={dateFilterTemplate}
                />

                {/* Income Amount column */}
                <Column 
                  field="incomeAmount" 
                  header="Income Amount" 
                  sortable 
                  body={incomeAmountTemplate}
                />

                {/* Income GST column */}
                <Column 
                  field="incomeGst" 
                  header="Income GST" 
                  sortable 
                  body={incomeGstTemplate}
                />

                {/* Expense Amount column */}
                <Column
                  field="expenseAmount"
                  header="Expense Amount"
                  sortable
                  body={expenseAmountTemplate}
                />

                {/* Expense GST column */}
                <Column 
                  field="expenseGst" 
                  header="Expense GST" 
                  sortable 
                  body={expenseGstTemplate}
                />

                {/* Suburb column - moved to last */}
                <Column field="suburb" header="Suburb" sortable />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DataTableCom;
