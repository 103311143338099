import { useAuth0 } from "@auth0/auth0-react"; // Importing Auth0 for authentication management
import { Link } from "react-router-dom"; // Importing Link for navigation
import profileIcon from "../../assets/icons/default-image.png";
import logOutIcon from "../../assets/icons/logOut.svg";

/*
 * Name: Navbar.tsx
 * Description: Navbar component providing navigation and logout functionality.
 * Version: 1.0.0
 */

const Navbar = () => {
  const { logout } = useAuth0(); // Auth0 hook for handling user logout

  // Function to handle user logout
  const logOUT = () => {
    // Removing authentication tokens and other relevant data from local storage
    localStorage.removeItem("_auth0");
    localStorage.removeItem("user");
    localStorage.removeItem("systemFormStatus");
    localStorage.clear();
    // Logging out the user and redirecting to the home page
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <>
      {/* Container for the background image and navbar */}
      <nav className="navbar bg-body-tertiary p-0">
        <div className="container-fluid custom-navbar px-4">
          {/* Link to the home page with the brand name */}
          <Link to="/" className="navbar-brand d-flex align-items-baseline">
            <h1 className="text-white">OpsBase</h1>
          </Link>

          <div className="d-flex align-items-center gap-4">
            <div
              className="d-flex align-items-center gap-3 custom-pointer"
              data-bs-toggle="modal"
              data-bs-target="#userDetailsModal"
            >
              <span className="profile-nav-name">Profile</span>
              <img src={profileIcon} alt="profile-pic" />
            </div>
            <span className="profile-nav-name">Logout</span>
            <img
              src={logOutIcon}
              alt="logOut"
              className="logout-image custom-pointer"
              onClick={logOUT}
            />
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
