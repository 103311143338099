import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Column } from "primereact/column";
import "file-saver";
import "jspdf-autotable";

import {
  addTransaction,
  clearTransactionDetails,
  getRegularTransactionList,
  getTransactionList,
  getYearToDateTotals,
  hideAlert,
  showAlert,
  transactionById
} from "../../features/redux-toolkit/transactionSlice";
import { useAppDispatch, useAppSelector } from "../../features/hook";
import "./dataTable.css";
import { Calendar } from "primereact/calendar";
import { formatDate, today } from "../../utils/dateUtils";
import "jspdf-autotable"; // Import the plugin for autoTable
import { DisabledField } from "../constant/fields/DisabledField";
import { closeModal } from "../../utils/modalUtils";

interface RegularTransactionTableProps {
  setShowRegularTransactionTable: (value: boolean) => void;
  closeRef: any;
}


/*
 * Name: DataTableCom.tsx
 * Description: Displays a dynamic, table for transactions (Income/Expense) with pagination, search, and export functionality.
 *              Features include the ability to export data to Excel and PDF, a global search filter, and sorting by multiple columns.
 *              The component also supports displaying transaction details like type, category, subcategory, amount, GST, and dates.
 * Version: 1.0.0
 */

function RegularTransactionTable({ setShowRegularTransactionTable, closeRef }: RegularTransactionTableProps) {
  // React hook to manage global filter state (search input)
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  
  // Get user payment methods from Redux store
  const { userPayment } = useAppSelector((state: any) => state.transaction);

  // State for selected payment method
  const [selectedPaymentId, setSelectedPaymentId] = useState<string>("");

  // Get current time in HH:MM:SS format
  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const [documentNumber, setDocumentNumber] = useState<string>("");
  const [gst, setGst] = useState<string>("");
  const [incomeOrExpenseAmount, setIncomeOrExpenseAmount] = useState<string>("");
  const [crossReference, setCrossReference] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [transactionDate, setTransactionDate] = useState<string>(today);
  const [transactionTime, setTransactionTime] = useState<string>(getCurrentTime());

  // Validation error states
  const [amountError, setAmountError] = useState<string>("");
  const [gstError, setGstError] = useState<string>("");
  const [dateError, setDateError] = useState<string>("");
  const [descriptionError, setDescriptionError] = useState<string>("");
  const [paymentMethodError, setPaymentMethodError] = useState<string>("");

  const [showRegularTransactionView, setShowRegularTransactionView] = useState<boolean>(true);
  const [showRegularTransactionForm, setShowRegularTransactionForm] = useState<boolean>(false);
  const [metaKey, setMetaKey] = useState(true);
  const dataTableRef = React.useRef<any>(null); // Reference for DataTable

  // Extract transaction list from Redux store using selector
  const { regularTransactionList, transactionDetails } = useAppSelector((state: any) => state.transaction);

  // Dispatch function to trigger actions in Redux
  const dispatch = useAppDispatch();

  // Fetch transaction list from the server when the component mounts
  useEffect(() => {
    dispatch(getRegularTransactionList()); // Fetch data
  }, []); // Empty dependency array ensures this runs only once on mount

  const onChangeDocumentNumber = (documentNumber: string) => {
    setDocumentNumber(documentNumber);
  }

  const onChangeIncomeOrExpenseAmount = (amount: string) => {
    // Clear validation error when user starts typing
    setAmountError("");
    
    // Only allow numbers and decimal point
    if (amount === "" || /^[0-9]*\.?[0-9]*$/.test(amount)) {
      setIncomeOrExpenseAmount(amount);
      
      // Calculate GST if amount is valid
      if (amount && !isNaN(parseFloat(amount))) {
        const amountValue = parseFloat(amount);
        const gstRate = 0.10;
        const gstValue = Number((amountValue * (gstRate * 100) / (100 + (gstRate * 100))).toFixed(2));
        setGst(gstValue.toFixed(2));
        setGstError(""); // Clear GST error when amount is valid
      }
    }
  }

  const onChangeGST = (amount: string) => {
    // Clear validation error when user starts typing
    setGstError("");
    
    // Only allow numbers and decimal point
    if (amount === "" || /^[0-9]*\.?[0-9]*$/.test(amount)) {
      setGst(amount);
    }
  }

  const onChangeCrossReference = (crossReference: string) => {
    setCrossReference(crossReference);
  }

  const onChangeDescription = (description: string) => {
    // Clear validation error when user starts typing
    setDescriptionError("");
    setDescription(description);
  }

  const onChangeTransactionDate = (date: string) => {
    // Clear validation error when user selects a date
    setDateError("");
    setTransactionDate(date);
  }

  // Handles success after adding a transaction
  const addedSuccessfully = (responseData: any, closeRef: any) => {
    // Check if responseData and savedTransaction exist before accessing id
    if (responseData && responseData.savedTransaction && responseData.savedTransaction.id) {
      closeRef.current.click();
      const successAlert = {
        icon: "success",
        title: "Added successfully",
        text: responseData.message,
        confirmButtonText: "OK",
      };
      dispatch(clearTransactionDetails());
      setShowRegularTransactionTable(false);
      setShowRegularTransactionView(false);
      dispatch(showAlert(successAlert)); // Show success alert
      dispatch(getTransactionList()); // Refresh transaction list
      dispatch(getYearToDateTotals()); // Refresh year-to-date totals
    } else {
      // Handle case where savedTransaction doesn't exist
      closeRef.current.click();
      const successAlert = {
        icon: "success",
        title: "Operation completed",
        text: responseData.message || "Transaction processed successfully",
        confirmButtonText: "OK",
      };
      dispatch(clearTransactionDetails());
      setShowRegularTransactionTable(false);
      setShowRegularTransactionView(false);
      dispatch(showAlert(successAlert)); // Show success alert
      dispatch(getTransactionList()); // Refresh transaction list
      dispatch(getYearToDateTotals()); // Refresh year-to-date totals
    }
  };

  const onSubmitRegularTransaction = () => {
    // Check if transactionDetails is defined
    if (!transactionDetails) {
      console.error("Transaction details are undefined or null");
      const errorAlert = {
        icon: "error",
        title: "Error",
        text: "Transaction details are missing. Please try again.",
        confirmButtonText: "OK",
      };
      dispatch(showAlert(errorAlert));
      return;
    }

    // Reset all validation errors
    setAmountError("");
    setGstError("");
    setDateError("");
    setDescriptionError("");
    setPaymentMethodError("");

    // Flag to track validation status
    let hasValidationErrors = false;

    // Validate description
    if (!description.trim()) {
      setDescriptionError("Description is required");
      hasValidationErrors = true;
    }

    // Validate date
    if (!transactionDate) {
      setDateError("Date is required");
      hasValidationErrors = true;
    }

    // Validate amount
    if (!incomeOrExpenseAmount || parseFloat(incomeOrExpenseAmount) <= 0) {
      setAmountError("Amount is required and must be greater than 0");
      hasValidationErrors = true;
    }

    // Validate GST
    if (gst === undefined || gst === null || gst === "") {
      setGstError("GST is required (enter 0 if not applicable)");
      hasValidationErrors = true;
    }

    // Validate payment method for EXPENSE type
    if (transactionDetails.transactionType === "EXPENSE") {
      // Check if we have any payment methods available
      if (userPayment && userPayment.length > 0) {
        // We have payment methods available, so one must be selected
        if (!selectedPaymentId) {
          // No payment method selected from dropdown
          if (!transactionDetails.payUsing) {
            // No payment method in transaction details either
            setPaymentMethodError("Payment method is required for expenses");
            hasValidationErrors = true;
          } else {
            // Check if the payment method in transaction details exists in the dropdown
            const paymentExists = userPayment.some((payment: any) => 
              payment.id.toString() === transactionDetails.payUsing.toString()
            );
            
            if (!paymentExists) {
              setPaymentMethodError("The previously selected payment method is no longer available. Please select a valid payment method.");
              hasValidationErrors = true;
            }
          }
        } else {
          // Payment method selected from dropdown - verify it exists in the list
          const paymentExists = userPayment.some((payment: any) => 
            payment.id.toString() === selectedPaymentId.toString()
          );
          
          if (!paymentExists) {
            setPaymentMethodError("The selected payment method is not valid. Please select a valid payment method.");
            hasValidationErrors = true;
          }
        }
      } else if (!transactionDetails.payUsing) {
        // No payment methods available and none in transaction details
        setPaymentMethodError("No payment methods available. Please add a payment method first.");
        hasValidationErrors = true;
      }
    }

    // If there are validation errors, stop submission
    if (hasValidationErrors) {
      return;
    }

    // Create the transaction object
    const transaction: any = {
      "documentNumber": documentNumber,
      "description": description,
      "date": `${transactionDate}T${transactionTime || '00:00:00'}`, // Format: yyyy-MM-ddThh:mm:ss
      "expenseAmount": transactionDetails.transactionType === "EXPENSE" ? parseFloat(incomeOrExpenseAmount || "0") * 100 : null,
      "expenseGst": transactionDetails.transactionType === "EXPENSE" ? parseFloat(gst || "0") * 100 : null,
      "incomeAmount": transactionDetails.transactionType === "INCOME" ? parseFloat(incomeOrExpenseAmount || "0") * 100 : null,
      "incomeGst": transactionDetails.transactionType === "INCOME" ? parseFloat(gst || "0") * 100 : null,
      "crossReference": crossReference,
      "payUsing": selectedPaymentId || transactionDetails.payUsing, // Use the selected payment ID if available
      "category": transactionDetails.category,
      "subCategory": transactionDetails.subCategory,
      "transactionType": transactionDetails.transactionType
    };

    console.log("regularTransactionPayload : {}", transaction);

    dispatch(hideAlert());

    dispatch(addTransaction({
      body: transaction,
      isRegularTransaction: false
    }))
      .then((res: any) => {
        console.log("Response payload: ", res.payload);
        closeModal("incomeExpenseModal");
        if (!res.payload || !res.payload.success) {
          handleError(res.payload?.success || false, res.payload?.error || "Unknown error");
          closeRef.current.click();
        } else {
          // Only call addedSuccessfully if the response is successful
          addedSuccessfully(res.payload, closeRef);
        }
      })
      .catch((error: any) => {
        console.error("Error submitting transaction:", error);
        handleError(false, "An error occurred while submitting the transaction");
        closeRef.current.click();
      });
  }

  // Handles errors during transaction process
  const handleError = (success: boolean, error: string) => {
    if (!success) {
      closeModal("incomeExpenseModal");
      const errorAlert = {
        icon: "error",
        title: `${error}`,
        text: "Something went wrong. Please try again.",
        confirmButtonText: "Cancel",
      };
      dispatch(showAlert(errorAlert)); // Show error alert
    }
  }


  // populate form data
  const populateTransactionForm = (transactionDetails: any) => {
    if (!transactionDetails) {
      console.error("Transaction details are undefined or null");
      return;
    }

    setDocumentNumber(transactionDetails?.documentNumber || "");
    
    // GST values are already converted to dollars in the Redux slice
    if (transactionDetails.transactionType === "INCOME") {
      setGst(transactionDetails?.incomeGst ? parseFloat(transactionDetails?.incomeGst).toFixed(2) : "0.00");
    } else if (transactionDetails.transactionType === "EXPENSE") {
      setGst(transactionDetails?.expenseGst ? parseFloat(transactionDetails?.expenseGst).toFixed(2) : "0.00");
    } else {
      setGst(transactionDetails?.gst ? parseFloat(transactionDetails?.gst).toFixed(2) : "0.00");
    }
    
    // Handle the case where both incomeAmount and expenseAmount might be undefined
    if (transactionDetails?.incomeAmount) {
      setIncomeOrExpenseAmount(parseFloat(transactionDetails.incomeAmount).toFixed(2));
    } else if (transactionDetails?.expenseAmount) {
      setIncomeOrExpenseAmount(parseFloat(transactionDetails.expenseAmount).toFixed(2));
    } else {
      setIncomeOrExpenseAmount("");
    }
    
    // Set the selected payment method
    if (transactionDetails.paymentSource && transactionDetails.paymentSource.id) {
      setSelectedPaymentId(transactionDetails.paymentSource.id.toString());
    } else if (transactionDetails.payUsing) {
      setSelectedPaymentId(transactionDetails.payUsing.toString());
    } else {
      setSelectedPaymentId("");
    }
    
    setCrossReference(transactionDetails?.crossReference || "");
    setDescription(transactionDetails?.description || "");
  }


  // Open modal programmatically or ensure button toggles it and show transaction
  const showTransaction = async (data: any) => {
    const transactionId = {
      id: data.id,
    };

    try {
      if (
        data.transactionType === "EXPENSE" ||
        data.transactionType === "INCOME"
      ) {
        const response: any = await dispatch(transactionById(transactionId));
        console.log("Transaction response:", response.payload);

        // Check if response and payload exist
        if (response && response.payload && response.payload.data) {
          populateTransactionForm(response.payload.data);

          if (response.payload?.status === 200) {
            setShowRegularTransactionView(false);
            setShowRegularTransactionForm(true);
          } else {
            console.error(
              "Failed to load transaction:",
              response.payload?.statusText || "Unknown error"
            );
          }
        } else {
          console.error("Invalid response format:", response);
        }
      } else {
        console.warn("Unsupported transaction type:", data.transactionType);
        alert("Transaction type not supported.");
      }
    } catch (error) {
      console.error("An error occurred while showing the transaction:", error);
    }
  };

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const dateBodyTemplate = (rowData: any) => {
    return formatDate(rowData.date);
  };

  // Add currency formatter function
  const formatCurrency = (value: any) => {
    if (value === null || value === undefined) return '';
    return new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: 2
    }).format(value);
  };

  // Add template functions for GST columns
  const incomeGstTemplate = (rowData: any) => {
    return formatCurrency(rowData.incomeGst);
  };

  const expenseGstTemplate = (rowData: any) => {
    return formatCurrency(rowData.expenseGst);
  };

  const incomeAmountTemplate = (rowData: any) => {
    return formatCurrency(rowData.incomeAmount);
  };

  const expenseAmountTemplate = (rowData: any) => {
    return formatCurrency(rowData.expenseAmount);
  };

  // console.log("transactionDetails : ", transactionDetails);

  return (
    <>
      <div className="container-flued px-4">
        <div className="row">
          <div className="col-12">
            <div className="card">
              {showRegularTransactionView &&
                <DataTable
                  ref={dataTableRef} // Attach reference
                  scrollHeight="600px"
                  scrollable
                  value={regularTransactionList} // Data to be displayed in the table
                  globalFilter={globalFilter} // Filter input for global search
                  tableStyle={{ minWidth: "50rem" }} // Minimum width for the table
                  dataKey="id" // Unique key for each row (e.g., for sorting or selection)
                  emptyMessage="No data found." // Message displayed if no data is available
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" // Information about the current page
                  selectionMode="single"
                  selection={selectedProduct}
                  onSelectionChange={(e) => showTransaction(e.value)}
                  metaKeySelection={metaKey}
                >
                  {/* Transaction column - ID of the transaction */}
                  <Column field="id" header="Transaction" />

                  {/* Transaction Type column */}
                  <Column field="transactionType" header="Type" />

                  {/* Document Number column */}
                  <Column field="documentNumber" header="Document No." />

                  {/* Description column */}
                  <Column field="description" header="Description" />

                  {/* Cross Reference column */}
                  <Column
                    field="crossReference"
                    header="Cross Reference"

                  />

                  {/* Category column */}
                  <Column field="category.category" header="Category" />

                  {/* Sub-Category column */}
                  <Column
                    field="subCategory.subCategory"
                    header="Sub-Category"

                  />

                  {/* Date column - format the date */}
                  <Column
                    field="date"
                    header="Date"

                    filterField="date"
                    dataType="date"
                    body={dateBodyTemplate}
                    filterElement={dateFilterTemplate}
                  />

                  {/* Income Amount column */}
                  <Column field="incomeAmount" header="Income Amount" body={incomeAmountTemplate} />

                  {/* Income GST column */}
                  <Column field="incomeGst" header="Income GST" body={incomeGstTemplate} />

                  {/* Expense Amount column */}
                  <Column field="expenseAmount" header="Expense Amount" body={expenseAmountTemplate} />

                  {/* Expense GST column */}
                  <Column field="expenseGst" header="Expense GST" body={expenseGstTemplate} />

                  {/* Suburb column - moved to last */}
                  <Column field="suburb" header="Suburb" />
                </DataTable>
              }
              {showRegularTransactionForm &&
                <>
                  <div className="row row-gap-4">
                    {/* Type */}
                    <div className="col-md-6">
                      <label
                        htmlFor="transactionType"
                        className={`col-form-label custom-label-popup ${transactionDetails?.id ? "" : "mandatory"
                          }`}
                      >
                        Type (Income or Expense)
                      </label>
                      <DisabledField
                        value={transactionDetails.transactionType || ""}
                      />
                    </div>

                    {/* Document No */}
                    <div className="col-md-6">
                      <label
                        htmlFor="documentNumber"
                        className={`col-form-label   ${transactionDetails?.id ? "" : "mandatory"
                          }`}
                      >
                        Document No
                      </label>
                      <>
                        <input
                          className="form-control custom-input-popup"
                          id="documentNumber"
                          placeholder="Enter Document No"
                          value={documentNumber}
                          onChange={(event: any) => onChangeDocumentNumber(event.target.value)}
                        />
                        <p className="error-text">
                          {/* {errors.documentNumber.message} */}
                        </p>
                      </>
                    </div>

                    <div className="col-md-6">
                      <label
                        htmlFor="amount"
                        className="col-form-label custom-label-popup mandatory"
                      >
                        Amount ($) (Inc GST If Applicable)
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">$</span>
                        <input
                          value={incomeOrExpenseAmount}
                          className={`form-control custom-input-popup ${amountError ? 'is-invalid' : ''}`}
                          id="amount"
                          onChange={(event: any) => onChangeIncomeOrExpenseAmount(event.target.value)}
                          onBlur={(event: any) => {
                            // Format to exactly 2 decimal places on blur
                            if (event.target.value && !isNaN(parseFloat(event.target.value))) {
                              const formattedValue = parseFloat(event.target.value).toFixed(2);
                              setIncomeOrExpenseAmount(formattedValue);
                              
                              // Recalculate GST with the formatted value
                              const amountValue = parseFloat(formattedValue);
                              const gstRate = 0.10;
                              const gstValue = Number((amountValue * (gstRate * 100) / (100 + (gstRate * 100))).toFixed(2));
                              setGst(gstValue.toFixed(2));
                            }
                          }}
                          placeholder="0"
                        />
                      </div>
                      {amountError && (
                        <div className="text-danger small mt-1">{amountError}</div>
                      )}
                    </div>

                    {/* GST Component */}
                    <div className="col-md-6">
                      <label
                        htmlFor="gst"
                        className="col-form-label custom-label-popup mandatory"
                      >
                        GST Component ($)
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">$</span>
                        <input
                          className={`form-control custom-input-popup ${gstError ? 'is-invalid' : ''}`}
                          id="gst"
                          onChange={(event: any) => onChangeGST(event.target.value)}
                          onBlur={(event: any) => {
                            // Format to exactly 2 decimal places on blur
                            if (event.target.value && !isNaN(parseFloat(event.target.value))) {
                              setGst(parseFloat(event.target.value).toFixed(2));
                            }
                          }}
                          value={gst}
                          placeholder="0"
                        />
                      </div>
                      {gstError && (
                        <div className="text-danger small mt-1">{gstError}</div>
                      )}
                    </div>

                    {/* Paid Using */}
                    <div className="col-md-6">
                      {transactionDetails?.transactionType === "EXPENSE" &&
                        <>
                          <label
                            htmlFor="payUsing"
                            className="col-form-label custom-label-popup mandatory"
                          >
                            Paid Using
                          </label>

                          <select
                            className={`form-select custom-select-popup ${paymentMethodError ? 'is-invalid' : ''}`}
                            id="payUsing"
                            value={selectedPaymentId}
                            onChange={(e) => {
                              const newPaymentId = e.target.value;
                              setSelectedPaymentId(newPaymentId);
                              setPaymentMethodError(""); // Clear error when user selects a payment method
                            }}
                          >
                            <option value="">Select Payment Method</option>
                            {userPayment.map((payment: any) => (
                              <option key={payment.id} value={payment.id}>
                                {`${payment.paymentAccountNumber} - ${payment.paymentType}`}
                              </option>
                            ))}
                          </select>
                          {paymentMethodError && (
                            <div className="text-danger small mt-1">{paymentMethodError}</div>
                          )}
                        </>
                      }
                    </div>

                    {/* Cross Reference */}
                    <div className="col-md-6">
                      <label
                        htmlFor="crossReference"
                        className="col-form-label custom-label-popup"
                      >
                        Cross Reference
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input-popup"
                        id="crossReference"
                        value={crossReference}
                        onChange={(event: any) => onChangeCrossReference(event.target.value)}
                        placeholder="Enter Cross Reference"
                      />
                    </div>

                    {/* Description */}
                    <div className="col-lg-12 col-md-12">
                      <label
                        htmlFor="description"
                        className="col-form-label custom-label-popup mandatory"
                      >
                        Description
                      </label>
                      <textarea
                        className={`form-control custom-description ${descriptionError ? 'is-invalid' : ''}`}
                        id="description"
                        spellCheck
                        value={description}
                        onChange={(event: any) => onChangeDescription(event.target.value)}
                        placeholder="Enter Description"
                        rows={4}
                      />
                      {descriptionError && (
                        <div className="text-danger small mt-1">{descriptionError}</div>
                      )}
                    </div>

                    {/* Date */}
                    <div className="col-md-6">
                      <label
                        htmlFor="date"
                        className="col-form-label custom-label-popup mandatory"
                      >
                        Date
                      </label>
                      <input
                        type="date"
                        className={`form-control custom-input-popup date-input-color ${dateError ? 'is-invalid' : ''}`}
                        id="date"
                        value={transactionDate}
                        onChange={(event: any) => onChangeTransactionDate(event.target.value)}
                        max={today}
                      />
                      {dateError && (
                        <div className="text-danger small mt-1">{dateError}</div>
                      )}
                    </div>

                    {/* Category */}
                    <div className="col-md-6">
                      <label
                        htmlFor="category"
                        className={`col-form-label custom-label-popup ${transactionDetails?.id ? "" : "mandatory"
                          }`}
                      >
                        Category
                      </label>{" "}
                      <DisabledField
                        value={transactionDetails?.category?.category || ""}
                      />
                    </div>

                    {/* Sub-Category */}
                    <div className="col-md-6">
                      <label
                        htmlFor="subCategory"
                        className={`col-form-label custom-label-popup ${transactionDetails?.id ? "" : "mandatory"
                          }`}
                      >
                        Sub-Category
                      </label>{" "}
                      <DisabledField
                        value={
                          transactionDetails?.subCategory?.subCategory || ""
                        }
                      />
                    </div>

                    {/* Upload Receipt */}
                    <div className="col-md-6">
                      {/* Upload Receipt */}
                      <div className="col-md-12">
                        <label
                          // htmlFor="receipt-file"
                          className="col-form-label custom-label-popup"
                        >
                          Upload Receipt
                        </label>
                        <DisabledField
                          value={
                            transactionDetails?.receiptFile || "Not Available"
                          }
                        />
                      </div>
                    </div>

                  </div>

                  <div className="modal-footer trasnaction-modal p-0 pt-4 pb-4">
                    <div>
                      <button type="button" name="btn-save-transaction" className="custom-gradient-btn"
                        onClick={() => onSubmitRegularTransaction()}
                      >
                        <i className="fa-solid fa-floppy-disk"></i> Save
                      </button>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default RegularTransactionTable;

