import { useForm } from "react-hook-form"; // Importing useForm from react-hook-form for form management
import * as yup from "yup"; // Importing yup for schema validation
import { yupResolver } from "@hookform/resolvers/yup"; // Importing yupResolver to integrate yup with react-hook-form
import "./systemSetUp.css";
import { useNavigate } from "react-router-dom"; // Importing useNavigate for navigation
// Importing the Redux action for authentication validation
// import { useAppDispatch, useAppSelector } from "../../features/hook"; // Importing custom hook to select state from Redux
import { useAppDispatch } from "../../features/hook"; // Importing custom hook to select state from Redux
import { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { systemValidAuthZero } from "../../features/redux-toolkit/transactionSlice";
import { addUserDetails, IPaymentMethod } from "../../features/redux-toolkit/authSlice";
import plusIcon from "./../../assets/icons/Plus-Icon.svg";
import minusIcon from "./../../assets/icons/Minus-Icon.svg";
import { ERROR_BLANK_PAYMENT_ACCOUNT_NUMBER, ERROR_BLANK_PAYMENT_TYPE } from "../../utils/MessageConstants";

/*
 * Name: SystemSetUp.tsx
 * description: Component for setting up system configurations with form validation.
 * Version: 1.0.0
 */

// Form values interface matching the schema
interface FormValues {
  businessName: string;
  emailId?: string;
  abn: string;
  acn?: string;
  phoneNumber: string;
  address: {
    streetAddress?: string;
    poBoxAddress?: string;
    suburb: string;
    postcode: string;
    state: string;
  };
  vehicleDetails: Array<{
    registrationNo: string;
    description: string;
  }>;
  vehicleDetails2: Array<{
    registrationNo?: string;
    description?: string;
  }>;
}

// Validation schema using yup
const schema = yup.object().shape({
  abn: yup
    .string()
    .required("ABN is required")
    .length(11, "ABN should be 11 digits."),
  acn: yup
    .string()
    .optional()
    .test("is-valid-acn", "ACN should be 9 digits.", (value) => {
      return !value || value.length === 9;
    }),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(/^[0-9]{10}$/, "Invalid Phone No."),
  businessName: yup
    .string()
    .required("Business Name is required")
    .min(2, "Business Name should be at least 2 characters."),
  emailId: yup.string().optional(),
  address: yup.object({
    streetAddress: yup.string().optional(),
    poBoxAddress: yup.string().optional(),
    suburb: yup.string().required("Suburb or locality is required"),
    postcode: yup
      .string()
      .required("Postcode is required")
      .matches(/^[0-9]{4}$/, "Postcode should be a valid 4-digit number."),
    state: yup.string().required("State is required"),
  }),
  vehicleDetails: yup
    .array()
    .of(
      yup.object().shape({
        registrationNo: yup
          .string()
          .required("Registration Number is required.")
          .min(6, "Registration Number should be at least 6 characters.")
          .max(17, "Registration Number should be no more than 17 characters."),

        description: yup.string().required("description is required"),
      })
    )
    .default([]),
  vehicleDetails2: yup
    .array()
    .of(
      yup.object().shape({
        registrationNo: yup.string().optional(),
        description: yup.string().optional(),
      })
    )
    .default([])
});

// Add type declarations for Places API
declare global {
  interface Window {
    google: {
      maps: {
        places: {
          Autocomplete: new (
            input: HTMLInputElement,
            opts?: {
              componentRestrictions?: { country: string };
              fields?: string[];
              types?: string[];
            }
          ) => any;
        };
      };
    };
  }
}

interface GoogleAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface GooglePlace {
  address_components?: GoogleAddressComponent[];
  formatted_address?: string;
}

// Update the autocomplete styles to ensure visibility
const autocompleteStyles = `
.pac-container {
  background-color: #fff;
  position: fixed !important;
  z-index: 9999;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 2px;
}

.pac-item {
  cursor: pointer;
  padding: 8px 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 14px;
  color: #515151;
  display: block !important;
}

.pac-item:hover {
  background-color: #f5f5f5;
}

.pac-item-selected {
  background-color: #ebf2fe;
}

.pac-matched {
  font-weight: bold;
}
`;

// Define interfaces for category and subcategory
interface CategoryData {
  name: string;
  description?: string;
  error?: string;
  subcategories: SubcategoryData[];
}

interface SubcategoryData {
  name: string;
  description?: string;
  error?: string;
}

const SystemSetUp = () => {
  // const { user, isAuthenticated, isLoading } = useAuth0();
  const { user } = useAuth0();
  // const { validAuthZero } = useAppSelector((state: any) => state.transaction);
  // const [vehicleCount, setVehicleCount] = useState<number | "">("");
  const [isVehicleSectionExpanded, setIsVehicleSectionExpanded] = useState(false);
  const [isPaymentSectionExpanded, setIsPaymentSectionExpanded] = useState(false);
  const [isCategorySectionExpanded, setIsCategorySectionExpanded] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([
    {
      paymentAccountNumber: "",
      paymentType: "",
      paymentAccountNumberErrorMessage: "",
      paymentTypeErrorMessage: ""
    }
  ]);
  // Add state for categories and subcategories
  const [categories, setCategories] = useState<CategoryData[]>([
    {
      name: "",
      description: "",
      error: "",
      subcategories: [
        {
          name: "",
          description: "",
          error: ""
        }
      ]
    }
  ]);
  const authEmailId = localStorage.getItem("email");
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setValue,
    watch
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      vehicleDetails: [],
      vehicleDetails2: [],
    },
  });

  // Add effect to watch for errors and expand sections
  useEffect(() => {
    if (errors.vehicleDetails?.[0]?.registrationNo || errors.vehicleDetails?.[0]?.description) {
      setIsVehicleSectionExpanded(true);
    }
  }, [errors.vehicleDetails]);

  // Add effect to validate the form on submit
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // Only run validation when a field changes
      if (type === 'change' && name) {
        // Check if there are any errors in the form
        if (Object.keys(errors).length > 0) {
          console.log("Form errors:", errors);

          // Expand vehicle section if there are errors in vehicle details
          if (errors.vehicleDetails || errors.vehicleDetails2) {
            setIsVehicleSectionExpanded(true);
          }

          // Expand address section if there are errors in address
          if (errors.address) {
            // No specific section for address, but we can log it
            console.log("Address errors:", errors.address);
          }
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, errors]);

  const getVehicleCountText = (count: number) => {
    return `${count} ${count === 1 ? 'vehicle' : 'vehicles'}`;
  };

  const getCategoryCountText = (count: number) => {
    return `${count} ${count === 1 ? 'category' : 'categories'}`;
  };

  // Add function to add a new category
  const addCategory = () => {
    setCategories([...categories, {
      name: "",
      description: "",
      error: "",
      subcategories: [
        {
          name: "",
          description: "",
          error: ""
        }
      ]
    }]);
  };

  // Add function to remove a category
  const removeCategory = (index: number) => {
    const updatedCategories = [...categories];
    updatedCategories.splice(index, 1);
    setCategories(updatedCategories);
  };

  // Add function to add a subcategory to a specific category
  const addSubcategory = (categoryIndex: number) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories.push({
      name: "",
      description: "",
      error: ""
    });
    setCategories(updatedCategories);
  };

  // Add function to remove a subcategory
  const removeSubcategory = (categoryIndex: number, subcategoryIndex: number) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories.splice(subcategoryIndex, 1);
    setCategories(updatedCategories);
  };

  // Add function to update category data
  const updateCategory = (categoryIndex: number, field: string, value: string) => {
    const updatedCategories = [...categories];

    // Check for duplicate category name if field is 'name'
    if (field === 'name' && value.trim()) {
      const isDuplicate = updatedCategories.some(
        (cat, index) => index !== categoryIndex && cat.name.toLowerCase() === value.trim().toLowerCase()
      );

      if (isDuplicate) {
        updatedCategories[categoryIndex].error = 'Category name already exists. Please use a unique name.';
        updatedCategories[categoryIndex] = {
          ...updatedCategories[categoryIndex],
          [field]: value
        };
        setCategories(updatedCategories);
        return;
      }
    }

    updatedCategories[categoryIndex] = {
      ...updatedCategories[categoryIndex],
      [field]: value
    };

    // Clear error when name is provided and not a duplicate
    if (field === 'name' && value.trim()) {
      updatedCategories[categoryIndex].error = '';
    }

    setCategories(updatedCategories);
  };

  // Add function to update subcategory data
  const updateSubcategory = (categoryIndex: number, subcategoryIndex: number, field: string, value: string) => {
    const updatedCategories = [...categories];

    // Check for duplicate subcategory name within the same category if field is 'name'
    if (field === 'name' && value.trim()) {
      const isDuplicate = updatedCategories[categoryIndex].subcategories.some(
        (subcat, index) => index !== subcategoryIndex && subcat.name.toLowerCase() === value.trim().toLowerCase()
      );

      if (isDuplicate) {
        updatedCategories[categoryIndex].subcategories[subcategoryIndex].error =
          'Subcategory name already exists in this category. Please use a unique name.';
        updatedCategories[categoryIndex].subcategories[subcategoryIndex] = {
          ...updatedCategories[categoryIndex].subcategories[subcategoryIndex],
          [field]: value
        };
        setCategories(updatedCategories);
        return;
      }
    }

    updatedCategories[categoryIndex].subcategories[subcategoryIndex] = {
      ...updatedCategories[categoryIndex].subcategories[subcategoryIndex],
      [field]: value
    };

    // Clear error when name is provided and not a duplicate
    if (field === 'name' && value.trim()) {
      updatedCategories[categoryIndex].subcategories[subcategoryIndex].error = '';
    }

    setCategories(updatedCategories);
  };

  // Add function to validate categories and subcategories
  const validateCategories = (): boolean => {
    let isValid = true;
    const updatedCategories = [...categories];

    // If there are no categories at all, add an error to the first category
    if (updatedCategories.length === 0 || updatedCategories.every(cat => cat.name.trim() === "")) {
      if (updatedCategories.length === 0) {
        // Add a default category if none exists
        updatedCategories.push({
          name: "",
          description: "",
          error: "At least one category is required",
          subcategories: [{
            name: "",
            description: "",
            error: ""
          }]
        });
      } else {
        // Add error to the first category
        updatedCategories[0].error = "At least one category is required";
      }
      isValid = false;
      setIsCategorySectionExpanded(true);
    }

    // Validate each category
    updatedCategories.forEach((category, categoryIndex) => {
      // Skip empty categories except the first one
      if (categoryIndex > 0 && category.name.trim() === "" && !category.description?.trim()) {
        return;
      }

      // Check if category name is provided
      if (category.name.trim() === "") {
        updatedCategories[categoryIndex].error = "Category name is required";
        isValid = false;
      } else {
        // Check for duplicate category names
        const isDuplicate = updatedCategories.some(
          (cat, index) => index !== categoryIndex &&
            cat.name.trim() !== "" &&
            cat.name.toLowerCase() === category.name.toLowerCase()
        );

        if (isDuplicate) {
          updatedCategories[categoryIndex].error = "Category name already exists. Please use a unique name.";
          isValid = false;
        } else {
          // Clear error if name is provided and not a duplicate
          updatedCategories[categoryIndex].error = "";
        }
      }

      // Check if at least one subcategory exists with a name
      const hasValidSubcategory = category.subcategories.some(sub => sub.name.trim() !== "");

      if (!hasValidSubcategory) {
        if (category.name.trim() !== "") {
          // Only show this error if the category has a name
          updatedCategories[categoryIndex].error = "At least one subcategory is required";
          isValid = false;
        }

        // Add error to the first subcategory if it exists
        if (category.subcategories.length > 0) {
          updatedCategories[categoryIndex].subcategories[0].error = "Subcategory name is required";
        }
      }

      // Validate each subcategory
      category.subcategories.forEach((subcategory, subcategoryIndex) => {
        // Skip empty subcategories except the first one
        if (subcategoryIndex > 0 && subcategory.name.trim() === "" && !subcategory.description?.trim()) {
          return;
        }

        // Check if subcategory name is provided
        if (subcategory.name.trim() === "" && category.name.trim() !== "") {
          updatedCategories[categoryIndex].subcategories[subcategoryIndex].error = "Subcategory name is required";
          isValid = false;
        } else if (subcategory.name.trim() !== "") {
          // Check for duplicate subcategory names within this category
          const isDuplicate = category.subcategories.some(
            (subcat, index) => index !== subcategoryIndex &&
              subcat.name.trim() !== "" &&
              subcat.name.toLowerCase() === subcategory.name.toLowerCase()
          );

          if (isDuplicate) {
            updatedCategories[categoryIndex].subcategories[subcategoryIndex].error =
              "Subcategory name already exists in this category. Please use a unique name.";
            isValid = false;
          } else {
            // Clear error if name is provided and not a duplicate
            updatedCategories[categoryIndex].subcategories[subcategoryIndex].error = "";
          }
        }
      });
    });

    setCategories(updatedCategories);

    if (!isValid) {
      setIsCategorySectionExpanded(true);
    }

    return isValid;
  };

  // Create a memoized function for handling place selection
  const handlePlaceSelect = useCallback((place: GooglePlace) => {
    if (!place.address_components) return;

    let streetNumber = "";
    let route = "";
    let locality = "";
    let sublocality = "";
    let state = "";
    let postcode = "";

    place.address_components.forEach((component: GoogleAddressComponent) => {
      const types = component.types;

      if (types.includes("street_number")) {
        streetNumber = component.long_name;
      }
      else if (types.includes("route")) {
        route = component.long_name;
      }
      else if (types.includes("locality")) {
        locality = component.long_name;
      }
      else if (types.includes("sublocality") || types.includes("sublocality_level_1")) {
        sublocality = component.long_name;
      }
      else if (types.includes("administrative_area_level_1")) {
        state = component.short_name;
      }
      else if (types.includes("postal_code")) {
        postcode = component.long_name;
      }
    });

    // Set street address
    if (streetNumber && route) {
      setValue("address.streetAddress", `${streetNumber} ${route}`.trim(), { shouldValidate: true });
    } else if (route) {
      setValue("address.streetAddress", route, { shouldValidate: true });
    }

    // Set suburb/locality - prefer locality over sublocality if available
    const finalSuburb = locality || sublocality;
    if (finalSuburb) {
      setValue("address.suburb", finalSuburb, { shouldValidate: true });
    }

    // Map state abbreviations to full names
    const stateMapping: { [key: string]: string } = {
      'NSW': 'New South Wales',
      'VIC': 'Victoria',
      'QLD': 'Queensland',
      'WA': 'Western Australia',
      'SA': 'South Australia',
      'TAS': 'Tasmania',
      'ACT': 'Australian Capital Territory',
      'NT': 'Northern Territory'
    };

    // Set state using full name
    if (state && stateMapping[state]) {
      setValue("address.state", stateMapping[state], { shouldValidate: true });
    }

    // Set postcode
    if (postcode) {
      setValue("address.postcode", postcode, { shouldValidate: true });
    }
  }, [setValue]);

  // Effect for adding styles
  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.textContent = autocompleteStyles;
    document.head.appendChild(styleSheet);

    return () => {
      if (styleSheet.parentNode) {
        styleSheet.parentNode.removeChild(styleSheet);
      }
    };
  }, []);

  // Effect for loading Google Maps script
  useEffect(() => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    if (!apiKey) {
      console.error('Google Maps API key is not defined in environment variables');
      return;
    }

    // Check if script is already loaded
    if (document.querySelector('script[src*="maps.googleapis.com/maps/api"]')) {
      setScriptLoaded(true);
      return;
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = () => setScriptLoaded(true);
    script.onerror = (e) => console.error("Error loading Places API:", e);
    document.head.appendChild(script);

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  // Effect for initializing autocomplete
  useEffect(() => {
    if (!scriptLoaded) return;

    const streetAddressInput = document.getElementById("address.streetAddress") as HTMLInputElement;
    if (!streetAddressInput) return;

    try {
      const autocompleteInstance = new window.google.maps.places.Autocomplete(
        streetAddressInput,
        {
          componentRestrictions: { country: "AU" },
          fields: ["address_components", "formatted_address", "name"],
          types: ["address"]
        }
      );

      // Prevent form submission on enter
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      };

      // Add event listeners
      streetAddressInput.addEventListener('keydown', handleKeyDown);

      // Add place_changed listener
      autocompleteInstance.addListener("place_changed", () => {
        const place = autocompleteInstance.getPlace() as GooglePlace;
        handlePlaceSelect(place);
      });

      // Cleanup
      return () => {
        streetAddressInput.removeEventListener('keydown', handleKeyDown);
        if (autocompleteInstance) {
          window.google.maps.event.clearInstanceListeners(autocompleteInstance);
        }
      };
    } catch (error) {
      console.error('Error initializing Google Places Autocomplete:', error);
    }
  }, [scriptLoaded, handlePlaceSelect]);

  // Function to validate all required fields
  const validateAllFields = (): boolean => {
    // Trigger validation for all fields
    const formValues = getValues();

    // Check if required fields are filled
    if (!formValues.businessName?.trim()) {
      return false;
    }

    if (!formValues.abn?.trim() || formValues.abn.length !== 11) {
      return false;
    }

    if (!formValues.phoneNumber?.trim() || formValues.phoneNumber.length !== 10) {
      return false;
    }

    // Check address fields
    if (!formValues.address?.suburb?.trim() ||
      !formValues.address?.postcode?.trim() ||
      !formValues.address?.state?.trim()) {
      return false;
    }

    // Check vehicle details
    if (!formValues.vehicleDetails?.[0]?.registrationNo?.trim() ||
      !formValues.vehicleDetails?.[0]?.description?.trim()) {
      return false;
    }

    return true;
  };

  const onSubmit = (data: FormValues) => {
    console.log("Form submitted with data:", data);
    console.log("Form errors:", errors);

    // Validate all required fields
    if (!validateAllFields()) {
      console.log("Form validation failed");
      return;
    }

    // Validate payment methods
    let submitFlag: boolean = true;
    let tempPaymentMethods = [...paymentMethods];
    tempPaymentMethods = tempPaymentMethods.map((eachPaymentMethod: IPaymentMethod) => {
      let tempEachPaymentMethod = { ...eachPaymentMethod };
      if (!tempEachPaymentMethod.paymentType || tempEachPaymentMethod.paymentType === "Select") {
        tempEachPaymentMethod.paymentTypeErrorMessage = ERROR_BLANK_PAYMENT_TYPE;
        submitFlag = false;
      } else {
        tempEachPaymentMethod.paymentTypeErrorMessage = "";
      }
      if (!tempEachPaymentMethod.paymentAccountNumber.trim()) {
        tempEachPaymentMethod.paymentAccountNumberErrorMessage = ERROR_BLANK_PAYMENT_ACCOUNT_NUMBER;
        submitFlag = false;
      } else {
        tempEachPaymentMethod.paymentAccountNumberErrorMessage = "";
      }
      eachPaymentMethod = tempEachPaymentMethod;
      return eachPaymentMethod;
    });

    setPaymentMethods(tempPaymentMethods);
    if (!submitFlag) {
      setIsPaymentSectionExpanded(true);
      return;
    }

    // Validate categories and subcategories
    const categoriesValid = validateCategories();
    if (!categoriesValid) {
      return;
    }

    // Validate required form fields
    if (Object.keys(errors).length > 0) {
      // Expand vehicle section if there are errors in vehicle details
      if (errors.vehicleDetails || errors.vehicleDetails2) {
        setIsVehicleSectionExpanded(true);
      }
      return;
    }

    // Filter out incomplete objects from vehicleDetails2
    const validVehicleDetails2 = (data.vehicleDetails2 ?? []).filter(
      (item) => item.registrationNo && item.description
    ) as { registrationNo: string; description: string }[];

    // Combine vehicleDetails and valid items from vehicleDetails2
    const combinedVehicleDetails = [
      ...data.vehicleDetails,
      ...validVehicleDetails2,
    ];

    // Prepare categories data - filter out empty categories and subcategories
    const validCategories = categories
      .filter(cat => cat.name.trim() !== "")
      .map(cat => ({
        name: cat.name.trim(),
        description: cat.description?.trim() || undefined,
        subcategories: cat.subcategories
          .filter(sub => sub.name.trim() !== "")
          .map(sub => ({
            name: sub.name.trim(),
            description: sub.description?.trim() || undefined
          }))
      }));

    // Assign combined vehicle details to vehicleDetails
    data.vehicleDetails = combinedVehicleDetails;
    data.vehicleDetails2 = [];
    data.emailId = authEmailId || user?.email;
    let payload: any = { ...data };
    payload.paymentSources = paymentMethods;
    payload.categories = validCategories;
    console.log("Submitting payload:", payload);
    dispatch(addUserDetails(payload)).then((res: any) => {
      console.log("Response: ", res);
      if (res.payload.success || res.payload.abn) {
        reset();
        dispatch(systemValidAuthZero());
        navigate("/");
      }
    });
  };

  const onlyDigits = (e: any, fieldName: keyof FormValues) => {
    let check = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    setValue(fieldName, check, {
      shouldValidate: true, // Trigger validation after setting the value
    });
  };

  return (
    <>
      <div className="bg-image"></div>
      <div
        className="modal fade show"
        id="incomeExpenseModal1"
        aria-labelledby="incomeExpenseModal1"
        style={{ display: "block" }}
        aria-hidden="true"
      >
        <div className="modal-dialog systemSetUp-form">
          <div className="modal-content" style={{ background: "transparent" }}>
            <div className="modal-header px-5 py-4 justify-content-center">
              <h1 className="modal-title gradient-text" id="addIncomeExpense">
                System Setup
              </h1>
            </div>
            <div className="modal-body px-5 px-sm-0 px-md-0 py-5">
              <div className="d-flex justify-content-center">
                <div className="col-12">
                  <form onSubmit={handleSubmit(
                    (data) => onSubmit(data),
                    (errors) => {
                      console.log("Form validation errors:", errors);

                      // Expand sections with errors
                      if (errors.vehicleDetails || errors.vehicleDetails2) {
                        setIsVehicleSectionExpanded(true);
                      }

                      // Validate categories
                      validateCategories();

                      // Validate payment methods
                      let tempPaymentMethods = [...paymentMethods];
                      let hasPaymentErrors = false;

                      tempPaymentMethods = tempPaymentMethods.map((eachPaymentMethod: IPaymentMethod) => {
                        let tempEachPaymentMethod = { ...eachPaymentMethod };
                        if (!tempEachPaymentMethod.paymentType || tempEachPaymentMethod.paymentType === "Select") {
                          tempEachPaymentMethod.paymentTypeErrorMessage = ERROR_BLANK_PAYMENT_TYPE;
                          hasPaymentErrors = true;
                        }
                        if (!tempEachPaymentMethod.paymentAccountNumber.trim()) {
                          tempEachPaymentMethod.paymentAccountNumberErrorMessage = ERROR_BLANK_PAYMENT_ACCOUNT_NUMBER;
                          hasPaymentErrors = true;
                        }
                        return tempEachPaymentMethod;
                      });

                      if (hasPaymentErrors) {
                        setPaymentMethods(tempPaymentMethods);
                        setIsPaymentSectionExpanded(true);
                      }
                    }
                  )}>
                    <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                      {/* Business Name */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="businessName"
                          className="col-form-label custom-label-popup mandatory"
                        >
                          Business Name
                        </label>
                        <input
                          className="form-control custom-input-popup"
                          id="businessName"
                          placeholder="Enter Business Name"
                          {...register("businessName")}
                        />
                        {errors.businessName && (
                          <p className="error error-text">
                            {errors.businessName.message}
                          </p>
                        )}
                      </div>

                      {/* Email Address */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="emailId"
                          className="col-form-label custom-label-popup"
                        >
                          Email Address
                        </label>
                        <input
                          type="email"
                          className="form-control custom-input-popup"
                          id="emailId"
                          value={authEmailId || ""}
                          disabled
                          placeholder="Enter Email Address"
                          {...register("emailId")}
                        />
                      </div>

                      {/* ABN */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="abn"
                          className="col-form-label custom-label-popup mandatory"
                        >
                          ABN
                        </label>
                        <input
                          minLength={11}
                          maxLength={11}
                          placeholder="Enter ABN"
                          className="form-control custom-input-popup"
                          value={getValues("abn")}
                          onInput={(e: any) => onlyDigits(e, "abn")}
                          {...register("abn")}
                        />
                        {errors.abn && (
                          <p className="error error-text">
                            {errors.abn.message}
                          </p>
                        )}
                      </div>

                      {/* ACN */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="acn"
                          className="col-form-label custom-label-popup"
                        >
                          ACN
                        </label>
                        <input
                          className="form-control custom-input-popup"
                          id="acn"
                          placeholder="Enter ACN"
                          value={getValues("acn")}
                          maxLength={9}
                          onInput={(e: any) => onlyDigits(e, "acn")}
                          {...register("acn")}
                        />
                        {errors.acn && (
                          <p className="error error-text">
                            {errors.acn.message}
                          </p>
                        )}
                      </div>

                      {/* Office Phone Number */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="phoneNumber"
                          className="col-form-label custom-label-popup mandatory"
                        >
                          Office Phone Number
                        </label>
                        <input
                          className="form-control custom-input-popup"
                          minLength={10}
                          maxLength={10}
                          value={getValues("phoneNumber")}
                          onInput={(e: any) => onlyDigits(e, "phoneNumber")}
                          placeholder="Enter Office Phone Number"
                          {...register("phoneNumber")}
                        />
                        {errors.phoneNumber && (
                          <p className="error error-text">
                            {errors.phoneNumber.message}
                          </p>
                        )}
                      </div>
                      <div className="col-md-5"></div>
                    </div>
                    <div className="col-12 d-flex justify-content-center pt-3">
                      <hr className="custom-hr" />
                    </div>

                    <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                      {/* Street Address */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="address.streetAddress"
                          className="col-form-label custom-label-popup"
                        >
                          Street Address
                        </label>
                        <input
                          className="form-control custom-input-popup"
                          id="address.streetAddress"
                          placeholder="Enter Street Address"
                          {...register("address.streetAddress")}
                        />
                        {errors.address?.streetAddress && (
                          <p className="error error-text">
                            {errors.address.streetAddress.message}
                          </p>
                        )}
                      </div>

                      {/* Postbox Code */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="address.poBoxAddress"
                          className="col-form-label custom-label-popup"
                        >
                          PO Box Address
                        </label>
                        <input
                          type="text"
                          className="form-control custom-input-popup"
                          id="address.poBoxAddress"
                          placeholder="Enter PO box address"
                          {...register("address.poBoxAddress")}
                        />
                        {errors.address?.poBoxAddress && (
                          <p className="error error-text">
                            {errors.address?.poBoxAddress.message}
                          </p>
                        )}
                      </div>

                      {/* Suburb or Locality */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="address.suburb"
                          className="col-form-label custom-label-popup mandatory"
                        >
                          Suburb or Locality
                        </label>
                        <input
                          type="text"
                          className="form-control custom-input-popup"
                          id="address.suburb"
                          placeholder="Enter Suburb or Locality"
                          {...register("address.suburb")}
                        />
                        {errors.address?.suburb && (
                          <p className="error error-text">
                            {errors.address.suburb.message}
                          </p>
                        )}
                      </div>

                      {/* Postcode */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="address.postcode"
                          className="col-form-label custom-label-popup mandatory"
                        >
                          Postcode
                        </label>
                        <input
                          className="form-control custom-input-popup"
                          minLength={4}
                          maxLength={4}
                          id="address.postcode"
                          placeholder="Enter Postcode"
                          onInput={(e: any) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            e.target.value = value; // Update the input value directly
                          }}
                          {...register("address.postcode")}
                        />
                        {errors.address?.postcode && (
                          <p className="error error-text">
                            {errors.address.postcode.message}
                          </p>
                        )}
                      </div>

                      {/* State */}
                      <div className="col-12 col-md-5">
                        <label
                          htmlFor="address.state"
                          className="col-form-label custom-label-popup mandatory"
                        >
                          State
                        </label>
                        <select
                          className="form-select custom-select-popup"
                          id="address.state"
                          {...register("address.state")}
                        >
                          <option value="">Select State</option>
                          <option value="New South Wales">
                            New South Wales
                          </option>
                          <option value="Victoria">Victoria</option>
                          <option value="Queensland">Queensland</option>
                          <option value="Western Australia">
                            Western Australia
                          </option>
                          <option value="South Australia">
                            South Australia
                          </option>
                          <option value="Tasmania">Tasmania</option>
                          <option value="Australian Capital Territory">
                            Australian Capital Territory
                          </option>
                          <option value="Northern Territory">
                            Northern Territory
                          </option>
                        </select>
                        {errors.address?.state && (
                          <p className="error error-text">
                            {errors.address.state.message}
                          </p>
                        )}
                      </div>
                      <div className="col-md-5"></div>
                    </div>

                    <div className="col-12 d-flex justify-content-center pt-3">
                      <hr className="custom-hr" />
                    </div>

                    {/* Value List Header */}
                    <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5">
                      <div className="col-12 col-md-5 mb-3">
                        <label className="col-form-label custom-label-title">
                          Value List
                        </label>
                      </div>
                      <div className="col-12 col-md-5"></div>
                    </div>

                    {/* Vehicle Details Section */}
                    <div className="col-12">
                      <div className="section-header d-flex justify-content-between align-items-center px-4 py-3"
                        onClick={() => setIsVehicleSectionExpanded(!isVehicleSectionExpanded)}
                        role="button"
                        aria-expanded={isVehicleSectionExpanded}>
                        <div className="d-flex align-items-center gap-2 w-100">
                          <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="d-flex align-items-center gap-2">
                              <span className="custom-label-title">Vehicle Details</span>
                              {!isVehicleSectionExpanded && (
                                <span className="text-muted small">
                                  ({getVehicleCountText(watch('vehicleDetails')?.length || 0)})
                                </span>
                              )}
                            </div>
                            <div className="expand-indicator" aria-expanded="false"
                              title={isVehicleSectionExpanded ? 'Click to collpase' : 'Click to expand'}>
                              <i className={`expand-icon fa-solid fa-chevron-${isVehicleSectionExpanded ? 'up' : 'down'}`}></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`collapse ${isVehicleSectionExpanded ? 'show' : ''}`}>
                        <div className="pt-3">
                          <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                            {/* Registration No  */}
                            <div className="col-12 col-md-5">
                              <label
                                htmlFor="registrationNo1"
                                className="col-form-label custom-label-popup mandatory"
                              >
                                Registration No
                              </label>

                              <input
                                className="form-control custom-input-popup"
                                minLength={6}
                                maxLength={17}
                                id="vehicleDetails[0].registrationNo1"
                                placeholder="Enter Registration Number"
                                {...register("vehicleDetails.0.registrationNo")}
                              />
                              {errors.vehicleDetails?.[0]?.registrationNo && (
                                <p className="error error-text">
                                  {errors.vehicleDetails[0].registrationNo.message}
                                </p>
                              )}
                            </div>

                            {/* Description1 */}
                            <div className="col-12 col-md-5">
                              <label
                                htmlFor="Description1"
                                className="col-form-label custom-label-popup mandatory"
                              >
                                Description
                              </label>
                              <input
                                className="form-control custom-input-popup"
                                id="Description1"
                                placeholder="Enter Description"
                                {...register("vehicleDetails.0.description")}
                              />
                              {errors.vehicleDetails?.[0]?.description && (
                                <p className="error error-text">
                                  {errors.vehicleDetails?.[0]?.description.message}
                                </p>
                              )}
                            </div>

                            {/* Registration No 2 */}
                            <div className="col-12 col-md-5">
                              <label
                                htmlFor="registrationNo2"
                                className="col-form-label custom-label-popup"
                              >
                                Registration No
                              </label>

                              <input
                                className="form-control custom-input-popup"
                                id="registrationNo2"
                                minLength={6}
                                maxLength={17}
                                placeholder="Enter Registration Number"
                                {...register("vehicleDetails2.0.registrationNo")}
                              />
                              {errors.vehicleDetails2?.[0]?.registrationNo && (
                                <p className="error error-text">
                                  {errors.vehicleDetails2?.[0]?.registrationNo.message}
                                </p>
                              )}
                            </div>

                            {/* Description2 */}
                            <div className="col-12 col-md-5">
                              <label
                                htmlFor="Description2"
                                className="col-form-label custom-label-popup"
                              >
                                Description
                              </label>
                              <input
                                className="form-control custom-input-popup"
                                id="Description2"
                                placeholder="Enter Description"
                                {...register("vehicleDetails2.0.description")}
                              />
                              {errors.vehicleDetails2?.[0]?.description && (
                                <p className="error error-text">
                                  {errors.vehicleDetails2?.[0]?.description.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Payment Methods Section */}
                    <div className="col-12">
                      <div className="section-header d-flex justify-content-between align-items-center px-4 py-3"
                        onClick={() => setIsPaymentSectionExpanded(!isPaymentSectionExpanded)}
                        role="button"
                        aria-expanded={isPaymentSectionExpanded}>
                        <div className="d-flex align-items-center gap-2 w-100">
                          <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="d-flex align-items-center gap-2">
                              <span className="custom-label-title">Payment Sources</span>
                              {!isPaymentSectionExpanded && (
                                <span className="text-muted small">
                                  ({paymentMethods.length} {paymentMethods.length === 1 ? 'method' : 'methods'})
                                </span>
                              )}
                            </div>
                            <div className="expand-indicator" aria-expanded="false"
                              title={isPaymentSectionExpanded ? 'Click to collpase' : 'Click to expand'}>
                              <i className={`expand-icon fa-solid fa-chevron-${isPaymentSectionExpanded ? 'up' : 'down'}`}></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`collapse ${isPaymentSectionExpanded ? 'show' : ''}`}>
                        <div className="pt-3">
                          {/* Plus Button */}
                          <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5">
                            <div className="col-12 col-md-5 mb-3 d-flex align-items-center">
                              {paymentMethods.length <= 3 && (
                                <img
                                  src={plusIcon}
                                  alt=""
                                  className="label-class"
                                  title="Add Payment Source"
                                  onClick={() => {
                                    const tempPaymentMethods = [...paymentMethods];
                                    tempPaymentMethods.push({
                                      paymentAccountNumber: "",
                                      paymentType: "",
                                      paymentTypeErrorMessage: "",
                                      paymentAccountNumberErrorMessage: ""
                                    });
                                    setPaymentMethods(tempPaymentMethods);
                                  }}
                                />
                              )}
                            </div>
                            <div className="col-12 col-md-5"></div>
                          </div>

                          {/* Payment Method Fields */}
                          {paymentMethods.map((item: IPaymentMethod, index: number) => {
                            return (
                              <div key={index} className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                                {/* Payment Source */}
                                <div className="col-12 col-md-5 d-flex align-items-start gap-2">
                                  <div className="flex-grow-1">
                                    <label
                                      htmlFor={item.paymentAccountNumber}
                                      className="col-form-label custom-label-popup mandatory"
                                    >
                                      Payment Source
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control custom-input-popup"
                                      id={item.paymentAccountNumber}
                                      placeholder="1234 - bank"
                                      value={paymentMethods[index].paymentAccountNumber}
                                      onChange={(event: any) => {
                                        const tempPaymentMethods = [...paymentMethods];
                                        tempPaymentMethods[index].paymentAccountNumber = event.target.value;
                                        tempPaymentMethods[index].paymentAccountNumberErrorMessage = !event.target.value ?
                                          "Payment source is required" : "";
                                        setPaymentMethods(tempPaymentMethods);
                                      }}
                                    />
                                    {paymentMethods[index].paymentAccountNumberErrorMessage && (
                                      <p className="error error-text">
                                        {paymentMethods[index].paymentAccountNumberErrorMessage}
                                      </p>
                                    )}
                                  </div>
                                  {/* Minus Button - Only show for rows after the first one */}
                                  {index > 0 && (
                                    <div className="d-flex align-items-center" style={{ marginTop: '32px' }}>
                                      <img
                                        src={minusIcon}
                                        alt=""
                                        className="label-class"
                                        title="Remove Payment Source"
                                        onClick={() => {
                                          const tempPaymentMethods = [...paymentMethods];
                                          tempPaymentMethods.splice(index, 1);
                                          setPaymentMethods(tempPaymentMethods);
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>

                                {/* Payment Description */}
                                <div className="col-12 col-md-5">
                                  <label
                                    htmlFor={item.paymentType}
                                    className="col-form-label custom-label-popup mandatory"
                                  >
                                    Payment Description
                                  </label>
                                  <select
                                    className="form-select custom-select-popup"
                                    id={item.paymentType}
                                    value={paymentMethods[index].paymentType}
                                    onChange={(event: any) => {
                                      const tempPaymentMethods = [...paymentMethods];
                                      tempPaymentMethods[index].paymentType = event.target.value;
                                      tempPaymentMethods[index].paymentTypeErrorMessage = event.target.value
                                        ? "" : ERROR_BLANK_PAYMENT_TYPE;
                                      setPaymentMethods(tempPaymentMethods);
                                    }}
                                  >
                                    <option value="">Select</option>
                                    <option value="Business Cheque Account">Business Cheque Account</option>
                                    <option value="Business Credit Card">Business Credit Card</option>
                                    <option value="Cash">Cash</option>
                                  </select>
                                  {paymentMethods[index].paymentTypeErrorMessage && (
                                    <p className="error error-text">
                                      {paymentMethods[index].paymentTypeErrorMessage}
                                    </p>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    {/* Categories Section */}
                    <div className="col-12">
                      <div className="section-header d-flex justify-content-between align-items-center px-4 py-3"
                        onClick={() => setIsCategorySectionExpanded(!isCategorySectionExpanded)}
                        role="button"
                        aria-expanded={isCategorySectionExpanded}>
                        <div className="d-flex align-items-center gap-2 w-100">
                          <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="d-flex align-items-center gap-2">
                              <span className="custom-label-title">Categories</span>
                              {!isCategorySectionExpanded && (
                                <span className="text-muted small">
                                  ({getCategoryCountText(categories.filter(cat => cat.name.trim() !== "").length)})
                                </span>
                              )}
                            </div>
                            <div className="expand-indicator" aria-expanded="false"
                              title={isCategorySectionExpanded ? 'Click to collpase' : 'Click to expand'}>
                              <i className={`expand-icon fa-solid fa-chevron-${isCategorySectionExpanded ? 'up' : 'down'}`}></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`collapse ${isCategorySectionExpanded ? 'show' : ''}`}>
                        <div className="pt-3">
                          {/* Plus Button for Categories */}
                          <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5">
                            <div className="col-12 col-md-5 mb-3 d-flex align-items-center">
                              <img
                                src={plusIcon}
                                alt=""
                                className="label-class"
                                title="Add Category"
                                onClick={addCategory}
                              />
                              <span className="ms-2">Add Category</span>
                            </div>
                            <div className="col-12 col-md-5"></div>
                          </div>

                          {/* Categories and Subcategories */}
                          {categories.map((category, categoryIndex) => (
                            <div key={categoryIndex} className="mb-4">
                              <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                                {/* Category Name */}
                                <div className="col-12 col-md-5 d-flex align-items-start gap-2">
                                  <div className="flex-grow-1">
                                    <label
                                      className="col-form-label custom-label-popup mandatory"
                                    >
                                      Category Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control custom-input-popup"
                                      placeholder="Enter Category Name"
                                      value={category.name}
                                      onChange={(e) => updateCategory(categoryIndex, 'name', e.target.value)}
                                    />
                                    {category.error && (
                                      <p className="error error-text">
                                        {category.error}
                                      </p>
                                    )}
                                  </div>
                                  {/* Minus Button - Only show for categories after the first one */}
                                  {categoryIndex > 0 && (
                                    <div className="d-flex align-items-center" style={{ marginTop: '32px' }}>
                                      <img
                                        src={minusIcon}
                                        alt=""
                                        className="label-class"
                                        title="Remove Category"
                                        onClick={() => removeCategory(categoryIndex)}
                                      />
                                    </div>
                                  )}
                                </div>

                                {/* Category Description */}
                                <div className="col-12 col-md-5">
                                  <label
                                    className="col-form-label custom-label-popup"
                                  >
                                    Category Description
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control custom-input-popup"
                                    placeholder="Enter Category Description (Optional)"
                                    value={category.description || ""}
                                    onChange={(e) => updateCategory(categoryIndex, 'description', e.target.value)}
                                  />
                                </div>
                              </div>

                              {/* Subcategories Section */}
                              <div className="mt-3">
                                <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5">
                                  <div className="col-12 col-md-5 mb-3 d-flex align-items-center">
                                    <img
                                      src={plusIcon}
                                      alt=""
                                      className="label-class ms-4"
                                      title="Add Subcategory"
                                      onClick={() => addSubcategory(categoryIndex)}
                                    />
                                    <span className="ms-2">Add Subcategory</span>
                                  </div>
                                  <div className="col-12 col-md-5"></div>
                                </div>

                                {/* Subcategories */}
                                {category.subcategories.map((subcategory, subcategoryIndex) => (
                                  <div key={subcategoryIndex} className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3 ms-4">
                                    {/* Subcategory Name */}
                                    <div className="col-12 col-md-5 d-flex align-items-start gap-2">
                                      <div className="flex-grow-1">
                                        <label
                                          className="col-form-label custom-label-popup mandatory"
                                        >
                                          Subcategory Name
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control custom-input-popup"
                                          placeholder="Enter Subcategory Name"
                                          value={subcategory.name}
                                          onChange={(e) => updateSubcategory(categoryIndex, subcategoryIndex, 'name', e.target.value)}
                                        />
                                        {subcategory.error && (
                                          <p className="error error-text">
                                            {subcategory.error}
                                          </p>
                                        )}
                                      </div>
                                      {/* Minus Button - Only show for subcategories after the first one */}
                                      {subcategoryIndex > 0 && (
                                        <div className="d-flex align-items-center" style={{ marginTop: '32px' }}>
                                          <img
                                            src={minusIcon}
                                            alt=""
                                            className="label-class"
                                            title="Remove Subcategory"
                                            onClick={() => removeSubcategory(categoryIndex, subcategoryIndex)}
                                          />
                                        </div>
                                      )}
                                    </div>

                                    {/* Subcategory Description */}
                                    <div className="col-12 col-md-5">
                                      <label
                                        className="col-form-label custom-label-popup"
                                      >
                                        Subcategory Description
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control custom-input-popup"
                                        placeholder="Enter Subcategory Description (Optional)"
                                        value={subcategory.description || ""}
                                        onChange={(e) => updateSubcategory(categoryIndex, subcategoryIndex, 'description', e.target.value)}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 d-flex justify-content-center pt-3">
                      <hr className="custom-hr" />
                    </div>

                    <div className="col-12 d-flex justify-content-center ">
                      <button
                        type="submit" className="submit-btn">
                        SUBMIT
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  );
};

export default SystemSetUp;
