import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "./popUp.css";
import { useAppDispatch, useAppSelector } from "../../../features/hook";
import {
  addTransaction,
  clearTransactionDetails,
  getTransactionList,
  getUserPayment,
  showAlert,
  getYearToDateTotals,
  getUserCategories,
} from "../../../features/redux-toolkit/transactionSlice";
import { closeModal } from "../../../utils/modalUtils";
import { DisabledField } from "../../constant/fields/DisabledField";
import { formatDate, today } from "../../../utils/dateUtils";
import { IPaymentdetails } from "../../../features/redux-toolkit/authSlice";
import RegularTransactionTable from "../../data-table/RegularTransactionTable";
import apiClient from "../../../services/FetchClient";

/*
 * Name: AddNewTransaction.tsx
 * Description: Component for adding a new transaction (Income or Expense) with form validation and dynamic categories/subcategories.
 * Version: 1.0.0
 */

interface ValidationData {
  documentNumber: string;
  transactionType: string;
  description: string;
  date: string;
  amount: number;
  gst: number;
  payUsing: string;
  category: {
    id: number;
    name: string;
  };
  subCategory: {
    id: number;
    name: string;
  };
  crossReference?: string;
  receiptFile?: File;
}

// Validation schema using yup for form fields
const schema = yup.object().shape({
  transactionType: yup.string().required("Type is required"),
  date: yup
    .date()
    .required("Date is required")
    .typeError("Date is required")
    .max(new Date(), "Date cannot be in the future")
    .test("valid-date", "Invalid date", (value) => {
      return value instanceof Date && !isNaN(value.getTime());
    }),
  category: yup
    .object()
    // .required("Category is required")
    .shape({
      id: yup.number().typeError("Category is required"),
      name: yup.string().required("Category name is required"),
    }),
  subCategory: yup
    .object()
    .required("Subcategory is required")
    .shape({
      id: yup
        .number()
        .required("Subcategory ID is required")
        .typeError("No Subcategory selected."),
      name: yup.string().required("Subcategory name is required"),
    }),
  amount: yup
    .number()
    .typeError("Amount should be a digit.")
    .required("Amount is required")
    .positive("Amount should be a positive digit.")
    .test(
      "maxDecimalPlaces",
      "Amount should have at most two decimal places.",
      (value: any) => {
        return /^\d+(\.\d{1,2})?$/.test(value); // Ensuring the amount has at most two decimal places
      }
    ),

  documentNumber: yup.string().required("Document No is required"),
  gst: yup
    .number()
    .typeError("GST Component should be a digit.")
    .required("GST Component is required")
    .min(0, "GST Component should be 0 or a positive number.")
    .test(
      "maxDecimalPlaces",
      "GST Component should have at most two decimal places.",
      (value: any) => {
        return /^\d+(\.\d{1,2})?$/.test(value); // Ensuring the GST has at most two decimal places
      }
    )
    .when("amount", (amount: any, schema) => {
      if (amount) {
        return schema.test(
          "greaterThanOrEqual",
          "GST should be less than or equal to the Amount.",
          (gst: any) => {
            if (gst === undefined) return true; // Pass validation if GST is empty
            const amountNum = parseFloat(amount);
            const gstNum = parseFloat(gst);
            if (isNaN(amountNum) || isNaN(gstNum)) return true; // Skip validation for invalid numbers
            return gstNum <= amountNum; // Ensure GST is at most 50% of Amount
          }
        );
      }
      return schema; // Return the original schema if amount is not provided
    }),
  payUsing: yup.string().when('transactionType', (transactionType: string[], schema) => {
    return transactionType[0] !== 'INCOME'
      ? schema.required('Paid Using is required')
      : schema.optional();
  }),
  crossReference: yup.string().optional(),
  description: yup.string().required("Description is required"),
  receiptFile: yup.mixed().optional(),
});

export const AddNewTransaction = () => {
  const dispatch = useAppDispatch();
  const { categories, userPayment } =
    useAppSelector((state: any) => state.transaction) || []; // Access categories from the store
  const { transactionDetails } = useAppSelector(
    (state: any) => state.transaction
  );
  const closeRef: any = useRef(null);
  const [file, setFile] = useState(null); // State for handling file input
  const [subCategories, setSubCategories] = useState(
    [{ id: 0, name: "" }] // Default subcategories from the first category
  );
  const [transactionType, setTransactionType] = useState("");
  const [showRegularTransactionTable, setShowRegularTransactionTable] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState<Date | null>(null); // Explicitly type the state
  const [clickedButton, setClickedButton] = useState<string | null>("");
  // States for plain dropdown - no longer needed but keeping the structure
  const [filteredPaymentOptions, setFilteredPaymentOptions] = useState<IPaymentdetails[]>([]);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema), // Using yup validation schema for the form
    defaultValues: {},
  });

  // Handle file input changes (receipt upload)
  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  useEffect(() => {
    dispatch(getUserPayment());
  }, []);

  // Fetch user-specific categories
  useEffect(() => {
    dispatch(getUserCategories());
  }, []);

  // Capture the clicked button
  const handleButtonClick = (buttonName: string) => {
    setClickedButton(buttonName);
  };

  // Handle category change and update subcategories based on the selected category
  const handleCategoryChange = (event: any) => {
    const selectedCategoryId = parseInt(event.target.value);
    const selectedCategory = categories.find(
      (cat: any) => cat.id === selectedCategoryId
    );

    if (selectedCategory) {
      setValue("category", {
        id: selectedCategory.id,
        name: selectedCategory.name,
      });
      clearErrors("category");

      // Set the subcategory based on the selected category
      setSubCategories(selectedCategory.subCategory || []);

      // Set the default subcategory based on the first subcategory of the selected category
      if (
        selectedCategory.subCategory &&
        selectedCategory.subCategory.length > 0
      ) {
        setValue("subCategory", {
          id: selectedCategory.subCategory[0].id,
          name: selectedCategory.subCategory[0].name,
        });
        clearErrors("subCategory.id");
        clearErrors("subCategory.name");
      }
    } else {
      setSubCategories([]);
      setValue("subCategory", {
        id: 0,
        name: "",
      });
    }
  };

  // Handle transaction type change
  const handleTransactionTypeChange = (event: any) => {
    console.log("event.target.value : ", event.target.value);

    // const selectedCategoryId = parseInt(event.target.value);
    const selectedTransactionType = event.target.value;
    setTransactionType(selectedTransactionType);

    if (selectedTransactionType) {
      setValue("transactionType", selectedTransactionType);
      clearErrors("transactionType");
    };
  }

  // Handle subcategory change and update the subcategory value
  const handleSubCategoryChange = (event: any) => {
    const selectedSubCategoryId = parseInt(event.target.value);

    const selectedSubCategory = subCategories.find(
      (subCat: any) => subCat.id === selectedSubCategoryId
    );

    if (selectedSubCategory) {
      setValue("subCategory", {
        id: selectedSubCategory.id,
        name: selectedSubCategory.name,
      });
      clearErrors("subCategory.name");
    }
  };

  // Handle the input precision for fields that require max two decimal places (Amount, GST)
  const handleTwoPrecision = (e: any, fieldName: keyof ValidationData) => {
    let value = e.target.value;

    // If the value is empty, allow it (this allows the user to clear the field)
    if (value === "") {
      setValue(fieldName, value, { shouldValidate: true });
      if (fieldName === "amount") {
        setValue("gst", 0, { shouldValidate: true });
      }
      return;
    }

    // Regular expression to allow up to two decimal places
    const regex = /^\d+(\.\d{0,2})?$/; // Matches integers or numbers with 1 or 2 decimal places

    // If the value matches the regex, proceed and set the value
    if (regex.test(value)) {
      setValue(fieldName, value, { shouldValidate: true });

      // Auto calculate GST when amount changes
      if (fieldName === "amount") {
        const amount = parseFloat(value);
        if (!isNaN(amount)) {
          const gstRate = 0.10;
          const gst = Number((amount * (gstRate * 100) / (100 + (gstRate * 100))).toFixed(2));
          setValue("gst", gst, { shouldValidate: true });
        }
      }
    }
  };

  // Handle GST field changes
  const handleGSTChange = (e: any) => {
    let value = e.target.value;

    // Allow empty value
    if (value === "") {
      setValue("gst", 0, { shouldValidate: true });
      return;
    }

    // Allow numbers with up to 2 decimal places
    const regex = /^\d+(\.\d{0,2})?$/;
    if (regex.test(value)) {
      const numValue = parseFloat(value);
      setValue("gst", numValue, { shouldValidate: true });
    }
  };

  // Handles success after adding a transaction
  const addedSuccessfully = (responseData: any) => {
    console.log("In addedSuccessfully with responseData:", responseData);

    // Always show a success message, regardless of the response structure
    closeRef.current.click(); // Close the form
    const successAlert = {
      icon: "success",
      title: "Added successfully",
      text: responseData.message || "Transaction added successfully",
      confirmButtonText: "OK",
    };
    reset(); // Reset the form
    setShowRegularTransactionTable(false);
    dispatch(showAlert(successAlert)); // Show success alert
    dispatch(getTransactionList()); // Refresh transaction list
    dispatch(getYearToDateTotals()); // Refresh year-to-date totals
  };

  // Handles errors during transaction process
  const handleError = (success: boolean, error: string) => {
    if (!success) {
      closeRef.current.click(); // Close the form
      reset(); // Reset the form
      const errorAlert = {
        icon: "error",
        title: `${error}`,
        text: "Something went wrong. Please try again.",
        confirmButtonText: "Cancel",
      };
      dispatch(showAlert(errorAlert)); // Show error alert
    }
  }

  const onClickUseRegularTransaction = () => {
    setShowRegularTransactionTable(true);
  }

  // Function to handle form submission
  const onSubmit = (data: any) => {
    // Prepare the data for submission
    const formData: any = {
      transactionType: data.transactionType,
      description: data.description,
      date: new Date(data.date),
      documentNumber: data.documentNumber,
      category: {
        id: data.category?.id || 0,
        name: data.category?.name || ""
      },
      subCategory: {
        id: data.subCategory?.id || 0,
        name: data.subCategory?.name || ""
      },
      crossReference: data.crossReference,
      payUsing: data.payUsing,
      isRegular: clickedButton === "AddRegularTransaction"
    };

    // Add amount and GST based on transaction type
    if (data.transactionType === "EXPENSE") {
      // Convert to cents as a number
      formData.expenseAmount = parseFloat(data.amount) * 100;
      formData.expenseGst = parseFloat(data.gst) * 100; // Convert GST to cents for consistency
    } else {
      // Convert to cents as a number
      formData.incomeAmount = parseFloat(data.amount) * 100;
      formData.incomeGst = parseFloat(data.gst) * 100; // Convert GST to cents for consistency
    }

    // Submit the form data
    console.log("Submitting transaction with clickedButton:", clickedButton);
    console.log("isRegularTransaction:", clickedButton === "AddRegularTransaction");
    console.log("Form data:", formData);

    dispatch(addTransaction({
      body: formData,
      isRegularTransaction: clickedButton === "AddRegularTransaction"
    }))
      .then((response: any) => {
        console.log("Transaction response:", response);
        console.log("Response payload:", response.payload);
        // Check if the response is successful
        if (response.payload && response.payload.success) {
          console.log("Transaction successful, calling addedSuccessfully");
          addedSuccessfully(response.payload);
        } else {
          console.log("Transaction failed, calling handleError");
          handleError(false, response.payload?.error || "Failed to create transaction");
        }
      })
      .catch((error: any) => {
        console.error("Error submitting transaction:", error);
        handleError(false, error.message || "An error occurred");
      });
  };

  // Add a useEffect to handle populating form fields when transactionDetails changes
  useEffect(() => {
    if (transactionDetails && Object.keys(transactionDetails).length > 0) {
      // Set transaction type
      if (transactionDetails.transactionType) {
        setValue("transactionType", transactionDetails.transactionType);
      }

      // Set document number
      if (transactionDetails.documentNumber) {
        setValue("documentNumber", transactionDetails.documentNumber);
      }

      // Set amount
      if (transactionDetails.incomeAmount) {
        setValue("amount", transactionDetails.incomeAmount, { shouldValidate: true });
      } else if (transactionDetails.expenseAmount) {
        setValue("amount", transactionDetails.expenseAmount, { shouldValidate: true });
      }

      // Set GST - this was missing before
      // For income transactions
      if (transactionDetails.transactionType === "INCOME" && transactionDetails.incomeGst !== undefined) {
        // GST values are already converted to dollars in the Redux slice
        setValue("gst", transactionDetails.incomeGst, { shouldValidate: true });
      }
      // For expense transactions
      else if (transactionDetails.transactionType === "EXPENSE" && transactionDetails.expenseGst !== undefined) {
        // GST values are already converted to dollars in the Redux slice
        setValue("gst", transactionDetails.expenseGst, { shouldValidate: true });
      }
      // For fuel transactions or other types
      else if (transactionDetails.gst !== undefined) {
        // GST values are already converted to dollars in the Redux slice
        setValue("gst", transactionDetails.gst, { shouldValidate: true });
      }

      // Set payment method - make it editable for expenses
      if (transactionDetails.payUsing) {
        setValue("payUsing", transactionDetails.payUsing);
      }

      // Set cross reference
      if (transactionDetails.crossReference) {
        setValue("crossReference", transactionDetails.crossReference);
      }

      // Set description
      if (transactionDetails.description) {
        setValue("description", transactionDetails.description);
      }

      // Set date
      if (transactionDetails.date) {
        // Convert the date string to a format compatible with the date input
        const dateStr = typeof transactionDetails.date === 'string'
          ? transactionDetails.date.split('T')[0] // Extract just the date part if it's a string with time
          : formatDate(transactionDetails.date);
        setValue("date", dateStr);
      }

      // Set category
      if (transactionDetails.category) {
        setValue("category", {
          id: transactionDetails.category.categoryId || transactionDetails.category.id,
          name: transactionDetails.category.category || transactionDetails.category.name
        });

        // Find the category to get its subcategories
        const selectedCategory = categories.find(
          (cat: any) => cat.id === (transactionDetails.category.categoryId || transactionDetails.category.id)
        );

        if (selectedCategory) {
          setSubCategories(selectedCategory.subCategory || []);
        }
      }

      // Set subcategory
      if (transactionDetails.subCategory) {
        setValue("subCategory", {
          id: transactionDetails.subCategory.subCategoryId || transactionDetails.subCategory.id,
          name: transactionDetails.subCategory.subCategory || transactionDetails.subCategory.name
        });
      }
    }
  }, [transactionDetails, setValue, categories]);

  // console.log("transactionDetails : ", transactionDetails);

  // These functions are no longer needed with a standard dropdown
  // But keeping them commented out in case we need to revert

  /*
  const handlePaymentInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Function removed - using standard dropdown instead
  };

  const selectPaymentOption = (payment: IPaymentdetails) => {
    // Function removed - using standard dropdown instead
  };
  */

  return (
    <>
      <div
        className="modal fade"
        id="incomeExpenseModal"
        aria-labelledby="incomeExpenseModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header px-5 py-4 custom-modal-header">
              <h5 className="modal-title gradient-text" id="addIncomeExpense">
                {transactionDetails?.id ? (
                  <>
                    {transactionDetails.transactionType
                      ? transactionDetails.transactionType
                        .charAt(0)
                        .toUpperCase() +
                      transactionDetails.transactionType
                        .slice(1)
                        .toLowerCase()
                      : ""}{" "}
                    Transaction ID: {transactionDetails.id}
                  </>
                ) : (
                  <> Add Income or Expense Transaction </>
                )}
              </h5>

              <button
                type="button"
                className="custom-gradient-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
                onClick={() => {
                  dispatch(clearTransactionDetails());
                  closeModal("incomeExpenseModal");
                  setShowRegularTransactionTable(false);
                  reset();
                  setSubCategories([]);
                }}
              ><i className="fa-solid fa-xmark" /> Exit</button>
            </div>
            {/* <hr className="border-top-only pb-3" /> */}
            <div className="modal-body px-5 py-4">
              {!showRegularTransactionTable ?
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row row-gap-4">
                    {/* Type */}
                    <div className="col-md-6">
                      <label
                        htmlFor="transactionType"
                        className={`col-form-label custom-label-popup ${transactionDetails?.id ? "" : "mandatory"
                          }`}
                      >
                        Type (Income or Expense)
                      </label>
                      {transactionDetails?.transactionType ? (
                        <>
                          <DisabledField
                            value={transactionDetails.transactionType || ""}
                          />
                        </>
                      ) : (
                        <>
                          <div className="d-flex gap-3">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="incomeType"
                                value="INCOME"
                                {...register("transactionType", { required: "Type is required" })}
                                onChange={handleTransactionTypeChange}
                              />
                              <label className="custom-label-popup" htmlFor="incomeType">
                                Income
                              </label>
                            </div>

                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="expenseType"
                                value="EXPENSE"
                                {...register("transactionType", { required: "Type is required" })}
                                onChange={handleTransactionTypeChange}
                              />
                              <label className="custom-label-popup" htmlFor="expenseType">
                                Expense
                              </label>
                            </div>
                          </div>
                          {/* <select
                          className="form-select custom-select-popup"
                          id="transactionType"
                          {...register("transactionType")}
                          onChange={handleTransactionTypeChange}
                        >
                          <option value="">Select Type</option>
                          <option value="INCOME">Income</option>
                          <option value="EXPENSE">Expense</option>
                        </select> */}
                          {errors.transactionType && (
                            <p className="error-text">
                              {errors.transactionType.message}
                            </p>
                          )}
                        </>
                      )}
                    </div>

                    {/* Document No */}
                    <div className="col-md-6">
                      <label
                        htmlFor="documentNumber"
                        className={`col-form-label custom-label-popup ${transactionDetails?.id ? "" : "mandatory"
                          }`}
                      >
                        Document No
                      </label>
                      {transactionDetails?.documentNumber ? (
                        <>
                          <DisabledField
                            value={transactionDetails.documentNumber || ""}
                          />
                        </>
                      ) : (
                        <>
                          <input
                            className="form-control custom-input-popup"
                            id="documentNumber"
                            placeholder="Enter Document No"
                            {...register("documentNumber")}
                          />
                          {errors.documentNumber && (
                            <p className="error-text">
                              {errors.documentNumber.message}
                            </p>
                          )}
                        </>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label
                        htmlFor="amount"
                        className={`col-form-label custom-label-popup ${transactionDetails?.id ? "" : "mandatory"
                          }`}
                      >
                        Amount ($) (Inc GST If Applicable)
                      </label>
                      {transactionDetails?.incomeAmount ||
                        transactionDetails?.expenseAmount ? (
                        <>
                          <DisabledField
                            value={
                              transactionDetails.incomeAmount ||
                              transactionDetails.expenseAmount ||
                              ""
                            }
                          />
                        </>
                      ) : (
                        <>
                          <div className="input-group">
                            <span className="input-group-text">$</span>
                            <input
                              value={getValues("amount")}
                              className="form-control custom-input-popup"
                              id="amount"
                              onInput={(e: any) => handleTwoPrecision(e, "amount")}
                              placeholder="0"
                              {...register("amount")}
                            />
                          </div>
                          {errors.amount && (
                            <p className="error-text">{errors.amount.message}</p>
                          )}
                        </>

                      )}
                    </div>

                    {/* GST Component */}
                    <div className="col-md-6">
                      <label
                        htmlFor="gst"
                        className={`col-form-label custom-label-popup ${transactionDetails?.id ? "" : "mandatory"
                          }`}
                      >
                        GST Component ($)
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">$</span>
                        <input
                          className="form-control custom-input-popup"
                          id="gst"
                          placeholder="0"
                          onInput={handleGSTChange}
                          {...register("gst")}
                        />
                      </div>
                      {errors.gst && (
                        <p className="error-text">{errors.gst.message}</p>
                      )}
                    </div>
                    {/* Paid Using */}
                    <div className="col-md-6">
                      <label
                        htmlFor="payUsing"
                        className="col-form-label custom-label-popup mandatory"
                      >
                        Paid Using
                      </label>
                      {transactionType !== "INCOME" ? (
                        <>
                          <select
                            className="form-select custom-select-popup"
                            id="payUsing"
                            {...register("payUsing")}
                          >
                            <option value="">Select Payment Method</option>
                            {userPayment.map((payment: IPaymentdetails) => (
                              <option key={payment.id} value={payment.id}>
                                {`${payment.paymentAccountNumber} - ${payment.paymentType}`}
                              </option>
                            ))}
                          </select>
                          {errors.payUsing && (
                            <p className="error-text">{errors.payUsing.message}</p>
                          )}
                        </>
                      ) : (
                        <>
                          <DisabledField value="N/A for Income" />
                        </>
                      )}
                    </div>

                    {/* Cross Reference */}
                    <div className="col-md-6">
                      <label
                        htmlFor="crossReference"
                        className="col-form-label custom-label-popup"
                      >
                        Cross Reference
                      </label>
                      {transactionDetails?.crossReference ? (
                        <>
                          <DisabledField
                            value={transactionDetails.crossReference || ""}
                          />
                        </>
                      ) : transactionDetails?.crossReference === null ||
                        transactionDetails?.crossReference === "" ? null : (
                        <>
                          <input
                            type="text"
                            className="form-control custom-input-popup"
                            id="crossReference"
                            {...register("crossReference")}
                            placeholder="Enter Cross Reference"
                          />
                          {errors.crossReference && (
                            <p className="error-text">
                              {errors.crossReference.message}
                            </p>
                          )}
                        </>
                      )}
                    </div>

                    {/* Description */}
                    <div className="col-lg-12 col-md-12">
                      <label
                        htmlFor="description"
                        className={`col-form-label custom-label-popup ${transactionDetails?.id ? "" : "mandatory"
                          }`}
                      >
                        Description
                      </label>
                      {transactionDetails?.description ? (
                        <>
                          <DisabledField
                            value={transactionDetails.description || ""}
                          />
                        </>
                      ) : (
                        <>
                          <textarea
                            className="form-control custom-description"
                            id="description"
                            spellCheck
                            {...register("description")}
                            placeholder="Enter Description"
                            rows={4}
                          />
                          {errors.description && (
                            <p className="error-text">
                              {errors.description.message}
                            </p>
                          )}
                        </>
                      )}
                    </div>

                    {/* Date */}
                    <div className="col-md-6">
                      <label
                        htmlFor="date"
                        className={`col-form-label custom-label-popup ${transactionDetails?.id ? "" : "mandatory"
                          }`}
                      >
                        Date
                      </label>
                      {transactionDetails?.date ? (
                        <>
                          <DisabledField
                            value={formatDate(transactionDetails.date) || ""}
                          />
                        </>
                      ) : (
                        <>
                          <input
                            type="date"
                            className="form-control custom-input-popup date-input-color"
                            id="date"
                            {...register("date")}
                            onChange={(e) => {
                              const selectedDate = new Date(e.target.value);
                              if (!isNaN(selectedDate.getTime())) {
                                // Get the current time
                                const now = new Date();
                                selectedDate.setHours(
                                  now.getHours(),
                                  now.getMinutes(),
                                  now.getSeconds(),
                                  now.getMilliseconds()
                                );
                                setCurrentDate(selectedDate);
                                clearErrors("date"); // Clear the error message if the date is valid
                              }
                            }}
                            max={today}
                          />
                          {errors.date && (
                            <p className="error-text">{errors.date.message}</p>
                          )}
                        </>
                      )}
                    </div>

                    {/* Category */}
                    <div className="col-md-6">
                      <label
                        htmlFor="category"
                        className={`col-form-label custom-label-popup ${transactionDetails?.id ? "" : "mandatory"
                          }`}
                      >
                        Category
                      </label>{" "}
                      {transactionDetails?.category?.category ? (
                        <>
                          <DisabledField
                            value={transactionDetails?.category?.category || ""}
                          />
                        </>
                      ) : (
                        <>
                          <select
                            className="form-select custom-select-popup"
                            id="category"
                            {...register("category.id")}
                            onChange={handleCategoryChange} // Trigger category change handler
                          >
                            <option value="">Select Category</option>
                            {categories.map((cat: any) => (
                              <option key={cat.id} value={cat.id}>
                                {cat.name}
                              </option>
                            ))}
                          </select>
                          {errors.category && (
                            <p className="error-text">
                              {errors.category.message}
                            </p>
                          )}
                          {errors.category?.id && (
                            <p className="error-text">
                              {errors.category.id.message}
                            </p>
                          )}
                        </>
                      )}
                    </div>

                    {/* Sub-Category */}
                    <div className="col-md-6">
                      <label
                        htmlFor="subCategory"
                        className={`col-form-label custom-label-popup ${transactionDetails?.id ? "" : "mandatory"
                          }`}
                      >
                        Sub-Category
                      </label>{" "}
                      {transactionDetails?.subCategory?.subCategory ? (
                        <>
                          <DisabledField
                            value={
                              transactionDetails.subCategory.subCategory || ""
                            }
                          />
                        </>
                      ) : (
                        <>
                          <select
                            className="form-select custom-select-popup"
                            id="subCategory"
                            {...register("subCategory.id")} // Register subcategory ID
                            onChange={handleSubCategoryChange} // Trigger subcategory change handler
                          >
                            <option value="" disabled>
                              Select Sub-Category
                            </option>
                            {subCategories.length > 0 ? (
                              <>
                                {subCategories.map((subCat: any) => (
                                  <option key={subCat.id} value={subCat.id}>
                                    {subCat.name}
                                  </option>
                                ))}
                              </>
                            ) : (
                              <option value="">Select Sub-Category</option>
                            )}
                          </select>
                          {errors.subCategory?.name && (
                            <p className="error-text">
                              {errors.subCategory.name.message}
                            </p>
                          )}
                        </>
                      )}
                    </div>

                    {/* Upload Receipt */}
                    <div className="col-md-6">
                      {/* Upload Receipt */}
                      <div className="col-md-12">
                        <label
                          // htmlFor="receipt-file"
                          className="col-form-label custom-label-popup"
                        >
                          Upload Receipt
                        </label>
                        {transactionDetails?.id ? (
                          <>
                            <DisabledField
                              value={
                                transactionDetails?.receiptFile || "Not Available"
                              }
                            />
                          </>
                        ) : (
                          <>
                            <div className="d-flex">
                              <input
                                type="file"
                                className="form-control custom-input-popup centered-file-input"
                                id="receipt-file"
                                onChange={handleFileChange}
                              />
                            </div>
                            {errors.receiptFile && (
                              <p className="error-text">
                                {errors.receiptFile.message}
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                  </div>

                  {transactionDetails?.id ? (
                    <>
                      <div className="modal-footer p-0 pt-4 pb-4">
                        <div
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          ref={closeRef}
                          className="custom-gradient-btn text-center d-flex align-items-center"
                          onClick={() => {
                            dispatch(clearTransactionDetails());
                            closeModal("incomeExpenseModal");
                            reset();
                            setShowRegularTransactionTable(false);
                            setSubCategories([]);
                          }}
                          style={{
                            padding: "7px 56px",
                            backgroundColor: "#00429B",
                            border: "none",
                            height: "44px",
                          }}
                        >
                          Close
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="modal-footer trasnaction-modal p-0 pt-4 pb-4">
                        <div>
                          <button type="submit" name="btn-save-transaction" className="custom-gradient-btn"
                            onClick={() => handleButtonClick("Save")}>
                            <i className="fa-solid fa-floppy-disk"></i> Save
                          </button>
                        </div>
                        <div>
                          <button type="submit" name="btn-add-regular-transaction" className="custom-ghost-btn"
                            onClick={() => handleButtonClick("AddRegularTransaction")}>
                            <i className="fa-solid fa-add"></i> Add to Regular Transaction
                          </button>
                          <button type="button" name="btn-use-regular-transaction" className="custom-ghost-btn ms-2"
                            onClick={() => onClickUseRegularTransaction()}>
                            <i className="fa-solid fa-use"></i> Use a Regular Transaction
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </form>
                :
                <RegularTransactionTable
                  setShowRegularTransactionTable={setShowRegularTransactionTable}
                  closeRef={closeRef}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
