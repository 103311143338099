import "./popUp.css"; // Importing custom CSS for styling the user details modal
import userImg from "../../../assets/temp-image/userImage.svg"; // Importing a user profile image
import { useEffect, useRef, useState } from "react"; // Importing React hooks for state management and side effects
import plusIcon from "../../../assets/icons/Plus-Icon.svg";
import exitIcon from "./../../../assets/icons/exitIcon.svg";
import * as yup from "yup"; // Import Yup for form validation schema
import { yupResolver } from "@hookform/resolvers/yup"; // Resolver to connect Yup schema to react-hook-form
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../features/hook";
import {
  addPaymentDetails,
  addUserCategory,
  addUserSubcategory,
  addVehicleDetails,
  getUserCategories,
  getUserDetails,
  getUserPayment,
  getUserVehicle,
  hideAlert,
  showAlert,
  updateCategory,
  deleteCategory,
  updateSubcategory,
  deleteSubcategory,
  deleteVehicle,
  deletePaymentSource,
} from "../../../features/redux-toolkit/transactionSlice";
import { IPaymentMethod } from "../../../features/redux-toolkit/authSlice";
// interface VehicleInfo {
//   registrationNo: string;
//   description: string;
// }
interface VehicleInfoAPI {
  registrationNo: string;
  description: string;
}
type VehicleDetailsPayload = {
  vehicleDetails: VehicleInfoAPI[];
}

interface PaymentSourceAPI {
  paymentType: string;
  paymentAccountNumber: string;
}
type PaymentSourcePayload = {
  paymentSourceDetails: PaymentSourceAPI[];
};

interface SubcategoryData {
  id: number;
  name: string;
  description?: string;
}

interface CategoryData {
  id: number;
  name: string;
  description?: string;
  subCategory: SubcategoryData[];
}

// Validation schema for the form using Yup
const schema = yup.object().shape({
  registrationNo: yup.string().required("Registration Number is required."),
  description: yup.string().required("Description is required"),
});

export const UserDetails = () => {
  const [isAddVehicleClicked, setIsAddVehicleClicked] = useState(false);
  const [isAddPaymentMethodClicked, setIsAddPaymentMethodClicked] = useState(false);
  const [isVehicleSectionExpanded, setIsVehicleSectionExpanded] = useState(false);
  const [isPaymentSectionExpanded, setIsPaymentSectionExpanded] = useState(false);
  const [isCategorySectionExpanded, setIsCategorySectionExpanded] = useState(false);
  const [isAddCategoryClicked, setIsAddCategoryClicked] = useState(false);
  const [isAddSubcategoryClicked, setIsAddSubcategoryClicked] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  const [categoryName, setCategoryName] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [subcategoryName, setSubcategoryName] = useState("");
  const [subcategoryDescription, setSubcategoryDescription] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [subcategoryError, setSubcategoryError] = useState("");
  
  // Add loading states for delete operations
  const [deletingVehicleId, setDeletingVehicleId] = useState<number | null>(null);
  const [deletingPaymentSourceId, setDeletingPaymentSourceId] = useState<number | null>(null);
  
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod>(
    {
      paymentAccountNumber: "",
      paymentType: "",
      paymentAccountNumberErrorMessage: "",
      paymentTypeErrorMessage: ""
    }
  );
  const dispatch = useAppDispatch(); // Get dispatch function from custom hook
  const { profileInfo, categories, isPending } =
    useAppSelector((state: any) => state.transaction) || false; // Access categories from the store
  const closeRef: any = useRef(null);
  // Use the form hook for handling form state and validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema), // Apply Yup validation schema
  });

  // New state variables for editing categories
  const [isEditCategoryClicked, setIsEditCategoryClicked] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState<number | null>(null);
  const [editCategoryName, setEditCategoryName] = useState("");
  const [editCategoryDescription, setEditCategoryDescription] = useState("");
  
  // New state variables for editing subcategories
  const [isEditSubcategoryClicked, setIsEditSubcategoryClicked] = useState(false);
  const [editSubcategoryId, setEditSubcategoryId] = useState<number | null>(null);
  const [editSubcategoryName, setEditSubcategoryName] = useState("");
  const [editSubcategoryDescription, setEditSubcategoryDescription] = useState("");
  
  const addVehicle = () => {
    setIsAddVehicleClicked(true); // Open the form and disable the Add Vehicle button
    setIsAddPaymentMethodClicked(false); // Open the form and disable the Add Vehicle button
  };

  const addPaymentMethod = () => {
    setIsAddVehicleClicked(false);
    setIsAddPaymentMethodClicked(true);
    // Reset payment method state when adding new payment method
    setPaymentMethod({
      paymentAccountNumber: "",
      paymentType: "",
      paymentAccountNumberErrorMessage: "",
      paymentTypeErrorMessage: ""
    });
  };

  const createdSuccessfully = (success: boolean, type: 'vehicle' | 'payment' = 'vehicle') => {
    if (success) {
      const successAlert = {
        icon: "success",
        title: "Added successfully",
        text: type === 'vehicle' 
          ? "The Vehicle was Added successfully." 
          : "The Payment Source was Added successfully.",
        confirmButtonText: "OK",
      };
      dispatch(showAlert(successAlert));
      reset();
      // Reset payment method state to initial empty values
      setPaymentMethod({
        paymentAccountNumber: "",
        paymentType: "",
        paymentAccountNumberErrorMessage: "",
        paymentTypeErrorMessage: ""
      });
      dispatch(getUserDetails());
      dispatch(getUserVehicle()); // Ensure vehicle data is refreshed in Redux
      dispatch(getUserPayment()); // Ensure payment data is refreshed in Redux
      setIsAddVehicleClicked(false);
      setIsAddPaymentMethodClicked(false);
    }
  };

  const handleError = (success: boolean, error: string) => {
    if (!success) {
      closeRef.current.click();
      reset();
      setIsAddVehicleClicked(false);
      setIsAddPaymentMethodClicked(false);
      const errorAlert = {
        icon: "error",
        title: `${error}`,
        text: "Something went wrong. Please try again.",
        confirmButtonText: "Cancel",
      };
      dispatch(showAlert(errorAlert));
    }
  };

  const onSubmit = (data: VehicleInfoAPI) => {
    dispatch(hideAlert());
    const vehicleDetails: VehicleDetailsPayload = { vehicleDetails: [data] };
    dispatch(addVehicleDetails(vehicleDetails)).then((res: any) => {
      // console.log("response : ", res.payload.status);
      if (res.payload.status === 201 || res.payload.status === 200) {
        createdSuccessfully(res.payload.status);
      } else {
        handleError(res.payload.success, res.payload.error);
      }
    });
  };

  const onSubmitPaymentMethod = () => {
    dispatch(hideAlert());
    
    // Validate payment fields
    let hasErrors = false;
    const tempPaymentMethod = { ...paymentMethod };

    // Validate Payment Source
    if (!tempPaymentMethod.paymentAccountNumber.trim()) {
      tempPaymentMethod.paymentAccountNumberErrorMessage = "Payment source is required";
      hasErrors = true;
    } else {
      tempPaymentMethod.paymentAccountNumberErrorMessage = "";
    }

    // Validate Payment Description
    if (!tempPaymentMethod.paymentType || tempPaymentMethod.paymentType === "Select") {
      tempPaymentMethod.paymentTypeErrorMessage = "Payment description is required";
      hasErrors = true;
    } else {
      tempPaymentMethod.paymentTypeErrorMessage = "";
    }

    setPaymentMethod(tempPaymentMethod);

    if (hasErrors) {
      return;
    }

    const paymentSourcePayload: PaymentSourcePayload = {
      paymentSourceDetails: [{
        paymentAccountNumber: paymentMethod.paymentAccountNumber,
        paymentType: paymentMethod.paymentType,
      }]
    };
    dispatch(addPaymentDetails(paymentSourcePayload)).then((res: any) => {
      // console.log("response : ", res.payload.status);
      if (res.payload.status === 201 || res.payload.status === 200) {
        createdSuccessfully(res.payload.status, 'payment');
      } else {
        handleError(res.payload.success, res.payload.error);
      }
    });
  };

  const addCategory = () => {
    setIsAddCategoryClicked(true);
    setIsAddSubcategoryClicked(false);
    setCategoryName("");
    setCategoryDescription("");
    setCategoryError("");
  };

  const handleAddSubcategoryClick = (categoryId: number) => {
    setSelectedCategoryId(categoryId);
    setIsAddSubcategoryClicked(true);
    setIsAddCategoryClicked(false);
    setSubcategoryName("");
    setSubcategoryDescription("");
    setSubcategoryError("");
  };

  const onSubmitCategory = () => {
    // Validate category name
    if (!categoryName.trim()) {
      setCategoryError("Category name is required");
      return;
    }

    // Check if category name already exists
    const categoryExists = categories.some(
      (cat: CategoryData) => cat.name.toLowerCase() === categoryName.trim().toLowerCase()
    );
    
    if (categoryExists) {
      setCategoryError("Category name already exists. Please use a unique name.");
      return;
    }

    // Create payload
    const payload = {
      name: categoryName.trim(),
      description: categoryDescription.trim() || undefined
    };

    // console.log("Adding category:", payload);

    // Dispatch action to add category
    dispatch(addUserCategory(payload))
      .unwrap()
      .then(() => {
        // console.log("Category added successfully");
        // Show success message
        const successAlert = {
          icon: "success",
          title: "Added successfully",
          text: "The Category was added successfully.",
          confirmButtonText: "OK",
        };
        dispatch(showAlert(successAlert));
        
        // Reset form
        setCategoryName("");
        setCategoryDescription("");
        setIsAddCategoryClicked(false);
        
        // Refresh categories
        dispatch(getUserCategories());
      })
      .catch((error) => {
        console.error("Error adding category:", error);
        // Show error message
        const errorAlert = {
          icon: "error",
          title: "Error",
          text: "Failed to add category. Please try again.",
          confirmButtonText: "OK",
        };
        dispatch(showAlert(errorAlert));
      });
  };

  const onSubmitSubcategory = () => {
    // Validate subcategory name
    if (!subcategoryName.trim()) {
      setSubcategoryError("Subcategory name is required");
      return;
    }

    if (!selectedCategoryId) {
      setSubcategoryError("Please select a category first");
      return;
    }
    
    // Find the selected category
    const selectedCategory = categories.find((cat: CategoryData) => cat.id === selectedCategoryId);
    
    // Check if subcategory name already exists in the selected category
    if (selectedCategory && selectedCategory.subCategory) {
      const subcategoryExists = selectedCategory.subCategory.some(
        (subcat: SubcategoryData) => subcat.name.toLowerCase() === subcategoryName.trim().toLowerCase()
      );
      
      if (subcategoryExists) {
        setSubcategoryError("Subcategory name already exists in this category. Please use a unique name.");
        return;
      }
    }

    // Create payload
    const payload = {
      name: subcategoryName.trim(),
      description: subcategoryDescription.trim() || undefined,
      userCategoryId: selectedCategoryId
    };

    // console.log("Adding subcategory:", payload);

    // Dispatch action to add subcategory
    dispatch(addUserSubcategory(payload))
      .unwrap()
      .then(() => {
        // console.log("Subcategory added successfully");
        // Show success message
        const successAlert = {
          icon: "success",
          title: "Added successfully",
          text: "The Subcategory was added successfully.",
          confirmButtonText: "OK",
        };
        dispatch(showAlert(successAlert));
        
        // Reset form
        setSubcategoryName("");
        setSubcategoryDescription("");
        setIsAddSubcategoryClicked(false);
        setSelectedCategoryId(null);
        
        // Refresh categories
        dispatch(getUserCategories());
      })
      .catch((error) => {
        console.error("Error adding subcategory:", error);
        // Show error message
        const errorAlert = {
          icon: "error",
          title: "Error",
          text: "Failed to add subcategory. Please try again.",
          confirmButtonText: "OK",
        };
        dispatch(showAlert(errorAlert));
      });
  };

  // Function to handle edit category click
  const handleEditCategory = (category: CategoryData) => {
    setEditCategoryId(category.id);
    setEditCategoryName(category.name);
    setEditCategoryDescription(category.description || "");
    setIsEditCategoryClicked(true);
    setIsAddCategoryClicked(false);
    setIsAddSubcategoryClicked(false);
    setIsEditSubcategoryClicked(false);
  };
  
  // Function to submit category edit
  const onSubmitCategoryEdit = () => {
    if (!editCategoryName.trim()) {
      setCategoryError("Category name is required");
      return;
    }
    
    const editedCategory = {
      id: editCategoryId,
      name: editCategoryName,
      description: editCategoryDescription
    };
    
    dispatch(updateCategory(editedCategory))
      .then((response: any) => {
        if (response.payload && response.payload.success) {
          // Reset form and fetch updated categories
          setIsEditCategoryClicked(false);
          setEditCategoryId(null);
          setEditCategoryName("");
          setEditCategoryDescription("");
          setCategoryError("");
          dispatch(getUserCategories());
        } else {
          setCategoryError("Failed to update category");
        }
      })
      .catch(() => {
        setCategoryError("An error occurred while updating the category");
      });
  };
  
  // Function to handle delete category
  const handleDeleteCategory = (categoryId: number) => {
    if (window.confirm("Are you sure you want to delete this category? This will also delete all subcategories and regular transactions associated with this category.")) {
      dispatch(deleteCategory(categoryId))
        .then((response: any) => {
          if (response.payload && response.payload.success) {
            dispatch(getUserCategories());
          } else {
            setCategoryError("Failed to delete category");
          }
        })
        .catch(() => {
          setCategoryError("An error occurred while deleting the category");
        });
    }
  };
  
  // Function to handle edit subcategory click
  const handleEditSubcategory = (subcategory: SubcategoryData, categoryId: number) => {
    setSelectedCategoryId(categoryId);
    setEditSubcategoryId(subcategory.id);
    setEditSubcategoryName(subcategory.name);
    setEditSubcategoryDescription(subcategory.description || "");
    setIsEditSubcategoryClicked(true);
    setIsAddCategoryClicked(false);
    setIsAddSubcategoryClicked(false);
    setIsEditCategoryClicked(false);
  };
  
  // Function to submit subcategory edit
  const onSubmitSubcategoryEdit = () => {
    if (!editSubcategoryName.trim()) {
      setSubcategoryError("Subcategory name is required");
      return;
    }
    
    const editedSubcategory = {
      id: editSubcategoryId,
      categoryId: selectedCategoryId,
      name: editSubcategoryName,
      description: editSubcategoryDescription
    };
    
    dispatch(updateSubcategory(editedSubcategory))
      .then((response: any) => {
        if (response.payload && response.payload.success) {
          // Reset form and fetch updated categories
          setIsEditSubcategoryClicked(false);
          setEditSubcategoryId(null);
          setEditSubcategoryName("");
          setEditSubcategoryDescription("");
          setSubcategoryError("");
          dispatch(getUserCategories());
        } else {
          setSubcategoryError("Failed to update subcategory");
        }
      })
      .catch(() => {
        setSubcategoryError("An error occurred while updating the subcategory");
      });
  };
  
  // Function to handle delete subcategory
  const handleDeleteSubcategory = (subcategoryId: number) => {
    if (window.confirm("Are you sure you want to delete this subcategory? This will also delete all regular transactions associated with this subcategory.")) {
      dispatch(deleteSubcategory(subcategoryId))
        .then((response: any) => {
          if (response.payload && response.payload.success) {
            dispatch(getUserCategories());
          } else {
            setSubcategoryError("Failed to delete subcategory");
          }
        })
        .catch(() => {
          setSubcategoryError("An error occurred while deleting the subcategory");
        });
    }
  };

  // Function to handle delete vehicle
  const handleDeleteVehicle = (vehicleId: number) => {
    if (window.confirm("Are you sure you want to delete this vehicle?")) {
      setDeletingVehicleId(vehicleId);
      dispatch(deleteVehicle(vehicleId))
        .then((response: any) => {
          if (response.payload && response.payload.success) {
            dispatch(getUserVehicle()); // Refresh vehicle list
            dispatch(getUserDetails()); // Also refresh profile info to update the UI
            const successAlert = {
              icon: "success",
              title: "Deleted successfully",
              text: "The vehicle was deleted successfully.",
              confirmButtonText: "OK",
            };
            dispatch(showAlert(successAlert));
          } else {
            const errorAlert = {
              icon: "error",
              title: "Delete Failed",
              text: "Failed to delete the vehicle. Please try again.",
              confirmButtonText: "OK",
            };
            dispatch(showAlert(errorAlert));
          }
          setDeletingVehicleId(null);
        })
        .catch((error) => {
          const errorAlert = {
            icon: "error",
            title: "Delete Failed",
            text: "An error occurred while deleting the vehicle.",
            confirmButtonText: "OK",
          };
          dispatch(showAlert(errorAlert));
          setDeletingVehicleId(null);
        });
    }
  };

  // Function to handle delete payment source
  const handleDeletePaymentSource = (paymentSourceId: number) => {
    if (window.confirm("Are you sure you want to delete this payment source?")) {
      setDeletingPaymentSourceId(paymentSourceId);
      dispatch(deletePaymentSource(paymentSourceId))
        .then((response: any) => {
          if (response.payload && response.payload.success) {
            dispatch(getUserPayment()); // Refresh payment source list
            dispatch(getUserDetails()); // Also refresh profile info to update the UI
            const successAlert = {
              icon: "success",
              title: "Deleted successfully",
              text: "The payment source was deleted successfully.",
              confirmButtonText: "OK",
            };
            dispatch(showAlert(successAlert));
          } else {
            const errorAlert = {
              icon: "error",
              title: "Delete Failed",
              text: "Failed to delete the payment source. Please try again.",
              confirmButtonText: "OK",
            };
            dispatch(showAlert(errorAlert));
          }
          setDeletingPaymentSourceId(null);
        })
        .catch((error) => {
          const errorAlert = {
            icon: "error",
            title: "Delete Failed",
            text: "An error occurred while deleting the payment source.",
            confirmButtonText: "OK",
          };
          dispatch(showAlert(errorAlert));
          setDeletingPaymentSourceId(null);
        });
    }
  };

  const {
    abn,
    acn,
    businessName,
    phoneNumber,
    userAddress,
    userId,
    vehicleDetails,
    paymentSources
  } = profileInfo || {};

  // console.log("profileInfo : ", profileInfo);

  // useEffect hook to fetch user profile when the component mounts
  useEffect(() => {
    dispatch(getUserDetails()); // Fetching the profile data on mount
    dispatch(getUserVehicle()); // Fetching the vehicle data on mount
    dispatch(getUserCategories()); // Fetching the categories data on mount
  }, [dispatch]); // Empty dependency array means this effect runs only once on mount
  
  // Log vehicle and payment source details when they change
  useEffect(() => {
    if (vehicleDetails) {
      console.log("Vehicle Details:", vehicleDetails);
    }
    if (paymentSources) {
      console.log("Payment Sources:", paymentSources);
    }
  }, [vehicleDetails, paymentSources]);

  const getVehicleCountText = (count: number) => {
    return `${count} ${count === 1 ? 'vehicle' : 'vehicles'}`;
  };

  const getPaymentCountText = (count: number) => {
    return `${count} ${count === 1 ? 'method' : 'methods'}`;
  };

  const getCategoryCountText = (count: number) => {
    return `${count} ${count === 1 ? 'category' : 'categories'}`;
  };

  return (
    <>
      {/* Modal for displaying user details */}
      <div
        className="modal fade"
        id="userDetailsModal"
        aria-labelledby="userDetailsModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header px-5 py-4 ml-1 align-items-start custom-modal-header">
              <div className="d-flex align-items-center column-gap-3">
                <div className="profile-img-container">
                  <img src={userImg} alt="profile-img" />
                </div>
                <div className="d-flex flex-column">
                  <div className="profile-name">{localStorage.getItem("username")?.replaceAll(".", " ").toUpperCase()}</div>
                  <div className="profile-email">{userId?.emailid || ""}</div>
                </div>
              </div>
              <button
                type="button"
                className="custom-gradient-btn"
                ref={closeRef}
                onClick={() => {
                  setIsAddVehicleClicked(false);
                  setIsAddPaymentMethodClicked(false);
                  reset();
                }}
                data-bs-dismiss="modal"
                aria-label="Close"
              ><img src={exitIcon} alt="" /> Exit</button>
            </div>
            {/* <hr className="border-top-only" /> */}
            <div className="modal-body px-5 px-sm-0 px-md-0 py-5">
              <div className="d-flex justify-content-center">
                <div className="col-12">
                  <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5">
                    {/* Business Name */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="businessName"
                        className="col-form-label custom-label-popup"
                      >
                        Business Name
                      </label>
                      <div className="disable-profile-input">
                        {businessName || ""}
                      </div>
                    </div>

                    {/* Email Address */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="emailAddress"
                        className="col-form-label custom-label-popup"
                      >
                        Email Address
                      </label>
                      <div className="disable-profile-input">
                        {userId?.emailid}
                      </div>
                    </div>

                    {/* ABN */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="ABN"
                        className="col-form-label custom-label-popup"
                      >
                        ABN
                      </label>
                      <div className="disable-profile-input">{abn || ""}</div>
                    </div>

                    {/* ACN */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="ACN"
                        className="col-form-label custom-label-popup"
                      >
                        ACN
                      </label>
                      <div className="disable-profile-input">{acn || ""}</div>
                    </div>

                    {/* Office Phone Number */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="costPerLiter"
                        className="col-form-label custom-label-popup"
                      >
                        Office Phone Number
                      </label>
                      <div className="disable-profile-input">
                        {phoneNumber || ""}
                      </div>
                    </div>
                    <div className="col-md-5"></div>
                  </div>
                  <div className="col-12 d-flex justify-content-center pb-3">
                    <hr className="custom-hr" />
                  </div>

                  <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5">
                    {/* Street Address */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="streetAddress"
                        className="col-form-label custom-label-popup"
                      >
                        Street Address
                      </label>
                      <div className="disable-profile-input">
                        {userAddress?.streetAddress || ""}
                      </div>
                    </div>

                    {/* Postbox Code */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="postboxCode"
                        className="col-form-label custom-label-popup"
                      >
                        PO Box Address
                      </label>
                      <div className="disable-profile-input">
                        {userAddress?.poBoxAddress || ""}
                      </div>
                    </div>

                    {/* Suburb or Locality */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="suburbOrLocatily"
                        className="col-form-label custom-label-popup"
                      >
                        Suburb or Locality
                      </label>
                      <div className="disable-profile-input">
                        {userAddress?.suburb || ""}
                      </div>
                    </div>

                    {/* Postcode */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="postCodeNum"
                        className="col-form-label custom-label-popup"
                      >
                        Postcode
                      </label>
                      <div className="disable-profile-input">
                        {" "}
                        {userAddress?.postCode || ""}
                      </div>
                    </div>

                    {/* State */}
                    <div className="col-12 col-md-5">
                      <label
                        htmlFor="state"
                        className="col-form-label custom-label-popup"
                      >
                        State
                      </label>
                      <div className="disable-profile-input">
                        {userAddress?.state || ""}
                      </div>
                    </div>
                    <div className="col-md-5"></div>
                  </div>

                  <div className="col-12 d-flex justify-content-center pt-3">
                    <hr className="custom-hr" />
                  </div>

                  {/* Value List Header */}
                  <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5">
                    <div className="col-12 col-md-5 mb-3">
                      <label className="col-form-label custom-label-title">
                        Value List
                      </label>
                    </div>
                    <div className="col-12 col-md-5"></div>
                  </div>

                  {/* Vehicle Details Section */}
                  <div className="col-12">
                    <div className="section-header d-flex justify-content-between align-items-center px-4 py-3"
                         onClick={() => setIsVehicleSectionExpanded(!isVehicleSectionExpanded)}
                         role="button"
                         aria-expanded={isVehicleSectionExpanded}>
                      <div className="d-flex align-items-center gap-2 w-100">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-center gap-2">
                            <span className="custom-label-title">Vehicle Details</span>
                            {!isVehicleSectionExpanded && (
                              <span className="text-muted small">
                                ({getVehicleCountText(vehicleDetails?.length || 0)})
                              </span>
                            )}
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            {isVehicleSectionExpanded && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  addVehicle();
                                }}
                                className={isAddVehicleClicked ? "custom-gradient-btn opacity-70 default" : "custom-gradient-btn"}
                                disabled={isAddVehicleClicked}
                              >
                                <i className="fa-solid fa-add"></i> Add Vehicle
                              </button>
                            )}
                            <div className="expand-indicator" aria-expanded="false"
                              title={isVehicleSectionExpanded ? 'Click to collpase' : 'Click to expand'}>
                              <i className={`expand-icon fa-solid fa-chevron-${isVehicleSectionExpanded ? 'up' : 'down'}`}></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`collapse ${isVehicleSectionExpanded ? 'show' : ''}`}>
                      <div className="pt-3">
                        {/* Existing vehicle details content */}
                        <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                          {vehicleDetails && vehicleDetails.length > 0 ? (
                            vehicleDetails.map((e: any, index: any) => {
                              console.log("Vehicle object:", e); 
                              console.log("Vehicle ID:", e.id);
                              console.log("Vehicle properties:", Object.keys(e));
                              return (
                              <div key={index} className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                                <div className="col-12 col-md-5">
                                  <label
                                    htmlFor={`registrationNumber${index}`}
                                    className="col-form-label custom-label-popup"
                                  >
                                    Registration No
                                  </label>
                                  <div className="disable-profile-input">
                                    {e.registrationNumber}
                                  </div>
                                </div>
                                <div className="col-12 col-md-5">
                                  <label
                                    htmlFor={`description${index}`}
                                    className="col-form-label custom-label-popup"
                                  >
                                    Description
                                  </label>
                                  <div className="disable-profile-input">
                                    {e.description}
                                  </div>
                                </div>
                                <div className="col-12 d-flex justify-content-center mt-2">
                                  <button 
                                    type="button" 
                                    className="btn btn-danger btn-sm"
                                    onClick={() => handleDeleteVehicle(e.id)}
                                    disabled={deletingVehicleId === e.id}
                                  >
                                    {deletingVehicleId === e.id ? (
                                      <>
                                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                        Deleting...
                                      </>
                                    ) : (
                                      <>
                                        <i className="fas fa-trash-alt me-1"></i> Delete Vehicle
                                      </>
                                    )}
                                  </button>
                                </div>
                              </div>
                            );
                          })
                          ) : (
                            <div className="col-12 d-flex justify-content-center">
                              <p>No vehicles available</p>
                            </div>
                          )}
                        </div>
                        {/* Add Vehicle Form */}
                        {isAddVehicleClicked && (
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                              <div className="col-12 col-md-5">
                                <label
                                  htmlFor="registrationNo"
                                  className="col-form-label custom-label-popup mandatory"
                                >
                                  Registration No
                                </label>
                                <input
                                  className="form-control custom-input-popup"
                                  minLength={6}
                                  maxLength={17}
                                  id="registrationNo"
                                  placeholder="Enter Registration Number"
                                  {...register("registrationNo")}
                                />
                                {errors.registrationNo && (
                                  <p className="error error-text">
                                    {errors.registrationNo.message}
                                  </p>
                                )}
                              </div>

                              <div className="col-12 col-md-5">
                                <label
                                  htmlFor="description"
                                  className="col-form-label custom-label-popup mandatory"
                                >
                                  Description
                                </label>
                                <input
                                  className="form-control custom-input-popup"
                                  id="description"
                                  placeholder="Enter Description"
                                  {...register("description")}
                                />
                                {errors.description && (
                                  <p className="error error-text">
                                    {errors.description.message}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-12 d-flex justify-content-center pt-3">
                              <hr className="custom-hr" />
                            </div>

                            <div className="col-12 d-flex justify-content-center">
                              <button type="button" className="submit-btn me-3" style={{ background: "grey", border: "none" }} onClick={() => {
                                setIsAddVehicleClicked(false);
                                reset();
                              }}>
                                CANCEL
                              </button>
                              <button type="submit" className="submit-btn" disabled={isPending}>
                                {isPending ? (
                                  <>
                                    <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                    SUBMITTING...
                                  </>
                                ) : (
                                  "SUBMIT"
                                )}
                              </button>
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Payment Details Section */}
                  <div className="col-12">
                    <div className="section-header d-flex justify-content-between align-items-center px-4 py-3"
                         onClick={() => setIsPaymentSectionExpanded(!isPaymentSectionExpanded)}
                         role="button"
                         aria-expanded={isPaymentSectionExpanded}>
                      <div className="d-flex align-items-center gap-2 w-100">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-center gap-2">
                            <span className="custom-label-title">Payment Sources</span>
                            {!isPaymentSectionExpanded && (
                              <span className="text-muted small">
                                ({getPaymentCountText(paymentSources?.length || 0)})
                              </span>
                            )}
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            {isPaymentSectionExpanded && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  addPaymentMethod();
                                }}
                                className={isAddPaymentMethodClicked ? "custom-gradient-btn opacity-70 default" : "custom-gradient-btn"}
                                disabled={isAddPaymentMethodClicked}
                              >
                                <i className="fa-solid fa-add"></i>  Add Payment Source
                              </button>
                            )}
                            <div className="expand-indicator" aria-expanded="false"
                              title={isPaymentSectionExpanded ? 'Click to collpase' : 'Click to expand'}>
                              <i className={`expand-icon fa-solid fa-chevron-${isPaymentSectionExpanded ? 'up' : 'down'}`}></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`collapse ${isPaymentSectionExpanded ? 'show' : ''}`}>
                      <div className="pt-3">
                        {/* Existing payment details content */}
                        <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                          {paymentSources && paymentSources.length > 0 ? (
                            paymentSources.map((e: any, index: any) => {
                              console.log("Payment source object:", e);
                              console.log("Payment source ID:", e.id);
                              console.log("Payment source properties:", Object.keys(e));
                              return (
                              <div key={index} className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                                <div className="col-12 col-md-5">
                                  <label
                                    htmlFor={`paymentAccountNumber${index}`}
                                    className="col-form-label custom-label-popup"
                                  >
                                    Payment Source
                                  </label>
                                  <div className="disable-profile-input">
                                    {e.paymentAccountNumber}
                                  </div>
                                </div>
                                <div className="col-12 col-md-5">
                                  <label
                                    htmlFor={`paymentType${index}`}
                                    className="col-form-label custom-label-popup"
                                  >
                                    Payment Description
                                  </label>
                                  <div className="disable-profile-input">
                                    {e.paymentType}
                                  </div>
                                </div>
                                <div className="col-12 d-flex justify-content-center mt-2">
                                  <button 
                                    type="button" 
                                    className="btn btn-danger btn-sm"
                                    onClick={() => handleDeletePaymentSource(e.id)}
                                    disabled={deletingPaymentSourceId === e.id}
                                  >
                                    {deletingPaymentSourceId === e.id ? (
                                      <>
                                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                        Deleting...
                                      </>
                                    ) : (
                                      <>
                                        <i className="fas fa-trash-alt me-1"></i> Delete Payment Source
                                      </>
                                    )}
                                  </button>
                                </div>
                              </div>
                            );
                          })
                          ) : (
                            <div className="col-12 d-flex justify-content-center">
                              <p>No payment source available</p>
                            </div>
                          )}
                        </div>
                        {/* Add Payment Form */}
                        {isAddPaymentMethodClicked && (
                          <>
                            <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                              <div className="col-12 col-md-5">
                                <label
                                  htmlFor="paymentAccountNumber"
                                  className="col-form-label custom-label-popup mandatory"
                                >
                                  Payment Source
                                </label>
                                <input
                                  className="form-control custom-input-popup"
                                  id="paymentAccountNumber"
                                  placeholder="1234 - Bank"
                                  value={paymentMethod.paymentAccountNumber}
                                  onChange={(event: any) => {
                                    const tempPaymentMethod = { ...paymentMethod };
                                    tempPaymentMethod.paymentAccountNumber = event.target.value;
                                    tempPaymentMethod.paymentAccountNumberErrorMessage = !event.target.value ? 
                                      "Payment source is required" : "";
                                    setPaymentMethod(tempPaymentMethod);
                                  }}
                                />
                                {paymentMethod.paymentAccountNumberErrorMessage && (
                                  <p className="error error-text">
                                    {paymentMethod.paymentAccountNumberErrorMessage}
                                  </p>
                                )}
                              </div>

                              <div className="col-12 col-md-5">
                                <label
                                  htmlFor="paymentType"
                                  className="col-form-label custom-label-popup mandatory"
                                >
                                  Payment Description
                                </label>
                                <select
                                  className="form-select custom-select-popup"
                                  id="paymentType"
                                  value={paymentMethod.paymentType}
                                  onChange={(event: any) => {
                                    const tempPaymentMethods = { ...paymentMethod };
                                    tempPaymentMethods.paymentType = event.target.value;
                                    tempPaymentMethods.paymentTypeErrorMessage = event.target.value
                                      ? "" : "Payment description is required";
                                    setPaymentMethod(tempPaymentMethods);
                                  }}
                                >
                                  <option value="">Select</option>
                                  <option value="Business Cheque Account">Business Cheque Account</option>
                                  <option value="Business Credit Card">Business Credit Card</option>
                                  <option value="Cash">Cash</option>
                                </select>
                                {paymentMethod.paymentTypeErrorMessage && (
                                  <p className="error error-text">
                                    {paymentMethod.paymentTypeErrorMessage}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-12 d-flex justify-content-center pt-3">
                              <hr className="custom-hr" />
                            </div>

                            <div className="col-12 d-flex justify-content-center">
                              <button type="button" className="submit-btn me-3" style={{ background: "grey", border: "none" }} onClick={() => {
                                setIsAddPaymentMethodClicked(false);
                                setPaymentMethod({
                                  paymentAccountNumber: "",
                                  paymentType: "",
                                  paymentAccountNumberErrorMessage: "",
                                  paymentTypeErrorMessage: ""
                                });
                              }}>
                                CANCEL
                              </button>
                              <button type="button" className="submit-btn" onClick={() => onSubmitPaymentMethod()} disabled={isPending}>
                                {isPending ? (
                                  <>
                                    <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                    SUBMITTING...
                                  </>
                                ) : (
                                  "SUBMIT"
                                )}
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Categories Section */}
                  <div className="col-12">
                    <div className="section-header d-flex justify-content-between align-items-center px-4 py-3"
                         onClick={() => setIsCategorySectionExpanded(!isCategorySectionExpanded)}
                         role="button"
                         aria-expanded={isCategorySectionExpanded}>
                      <div className="d-flex align-items-center gap-2 w-100">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-center gap-2">
                            <span className="custom-label-title">Categories</span>
                            {!isCategorySectionExpanded && (
                              <span className="text-muted small">
                                ({getCategoryCountText(categories?.length || 0)})
                              </span>
                            )}
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            {isCategorySectionExpanded && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  addCategory();
                                }}
                                className={isAddCategoryClicked ? "custom-gradient-btn opacity-70 default" : "custom-gradient-btn"}
                                disabled={isAddCategoryClicked}
                              >
                                <i className="fa-solid fa-add"></i> Add Category
                              </button>
                            )}
                            <div className="expand-indicator" aria-expanded="false"
                              title={isCategorySectionExpanded ? 'Click to collpase' : 'Click to expand'}>
                              <i className={`expand-icon fa-solid fa-chevron-${isCategorySectionExpanded ? 'up' : 'down'}`}></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`collapse ${isCategorySectionExpanded ? 'show' : ''}`}>
                      <div className="pt-3">
                        {/* Add Category Form */}
                        {isAddCategoryClicked && (
                          <>
                            <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                              <div className="col-12 col-md-5">
                                <label
                                  htmlFor="categoryName"
                                  className="col-form-label custom-label-popup mandatory"
                                >
                                  Category Name
                                </label>
                                <input
                                  className="form-control custom-input-popup"
                                  id="categoryName"
                                  placeholder="Enter Category Name"
                                  value={categoryName}
                                  onChange={(e) => {
                                    setCategoryName(e.target.value);
                                    if (e.target.value.trim()) {
                                      setCategoryError("");
                                    }
                                  }}
                                />
                                {categoryError && (
                                  <p className="error error-text">
                                    {categoryError}
                                  </p>
                                )}
                              </div>

                              <div className="col-12 col-md-5">
                                <label
                                  htmlFor="categoryDescription"
                                  className="col-form-label custom-label-popup"
                                >
                                  Description (Optional)
                                </label>
                                <input
                                  className="form-control custom-input-popup"
                                  id="categoryDescription"
                                  placeholder="Enter Description"
                                  value={categoryDescription}
                                  onChange={(e) => setCategoryDescription(e.target.value)}
                                />
                              </div>
                            </div>

                            <div className="col-12 d-flex justify-content-center pt-3">
                              <hr className="custom-hr" />
                            </div>

                            <div className="col-12 d-flex justify-content-center">
                              <button type="button" className="submit-btn me-3" style={{background: "grey", border: "none"}} onClick={() => {
                                setIsAddCategoryClicked(false);
                                setCategoryName("");
                                setCategoryDescription("");
                                setCategoryError("");
                              }}>
                                CANCEL
                              </button>
                              <button type="button" className="submit-btn" onClick={onSubmitCategory}>
                                SUBMIT
                              </button>
                            </div>
                          </>
                        )}

                        {/* Existing Categories */}
                        {!isAddCategoryClicked && (
                          <div className="col-12 d-flex flex-wrap justify-content-center column-gap-5 row-gap-3">
                            {categories && categories.length > 0 ? (
                              categories.map((category: CategoryData) => (
                                <div key={category.id} className="mb-4 w-100">
                                  <div className="card w-100">
                                    {isEditCategoryClicked && editCategoryId === category.id ? (
                                      // Edit Category Form (in-place)
                                      <div className="card-body">
                                        <h5 className="mb-3">Edit Category</h5>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label htmlFor="editCategoryName" className="form-label custom-label-popup">
                                              Category Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control custom-input-popup"
                                              id="editCategoryName"
                                              value={editCategoryName}
                                              onChange={(e) => setEditCategoryName(e.target.value)}
                                            />
                                            {categoryError && (
                                              <p className="error error-text">
                                                {categoryError}
                                              </p>
                                            )}
                                          </div>
                                          <div className="col-md-6">
                                            <label htmlFor="editCategoryDescription" className="form-label custom-label-popup">
                                              Description (Optional)
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control custom-input-popup"
                                              id="editCategoryDescription"
                                              value={editCategoryDescription}
                                              onChange={(e) => setEditCategoryDescription(e.target.value)}
                                            />
                                          </div>
                                          <div className="col-12 d-flex justify-content-end mt-3">
                                            <button
                                              type="button"
                                              className="btn btn-secondary me-2"
                                              onClick={() => {
                                                setIsEditCategoryClicked(false);
                                                setEditCategoryId(null);
                                                setEditCategoryName("");
                                                setEditCategoryDescription("");
                                                setCategoryError("");
                                              }}
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={onSubmitCategoryEdit}
                                            >
                                              Update
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      // Normal Category Display
                                      <>
                                        <div className="card-header d-flex justify-content-between align-items-center w-100">
                                          <h5 className="mb-0">{category.name}</h5>
                                          <div className="btn-group">
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-outline-primary"
                                              onClick={() => handleEditCategory(category)}
                                            >
                                              Edit
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-outline-danger"
                                              onClick={() => handleDeleteCategory(category.id)}
                                            >
                                              Delete
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-outline-success"
                                              onClick={() => handleAddSubcategoryClick(category.id)}
                                            >
                                              Add Subcategory
                                            </button>
                                          </div>
                                        </div>
                                        <div className="card-body w-100">
                                          {category.description && (
                                            <p className="text-muted mb-3">{category.description}</p>
                                          )}
                                        
                                          {/* Subcategories */}
                                          {isAddSubcategoryClicked && selectedCategoryId === category.id ? (
                                            // Add Subcategory Form
                                            <div className="mt-3">
                                              <h6 className="mb-3">Add Subcategory</h6>
                                              <div className="row">
                                                <div className="col-md-6">
                                                  <label htmlFor="subcategoryName" className="form-label custom-label-popup">
                                                    Subcategory Name
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control custom-input-popup"
                                                    id="subcategoryName"
                                                    placeholder="Enter Subcategory Name"
                                                    value={subcategoryName}
                                                    onChange={(e) => {
                                                      setSubcategoryName(e.target.value);
                                                      if (e.target.value.trim()) {
                                                        setSubcategoryError("");
                                                      }
                                                    }}
                                                  />
                                                  {subcategoryError && (
                                                    <p className="error error-text">
                                                      {subcategoryError}
                                                    </p>
                                                  )}
                                                </div>
                                                <div className="col-md-6">
                                                  <label htmlFor="subcategoryDescription" className="form-label custom-label-popup">
                                                    Description (Optional)
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control custom-input-popup"
                                                    id="subcategoryDescription"
                                                    placeholder="Enter Description"
                                                    value={subcategoryDescription}
                                                    onChange={(e) => setSubcategoryDescription(e.target.value)}
                                                  />
                                                </div>
                                                <div className="col-12 d-flex justify-content-end mt-3">
                                                  <button
                                                    type="button"
                                                    className="btn btn-secondary me-2"
                                                    onClick={() => {
                                                      setIsAddSubcategoryClicked(false);
                                                      setSelectedCategoryId(null);
                                                    }}
                                                  >
                                                    Cancel
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={onSubmitSubcategory}
                                                  >
                                                    Add
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="mt-2">
                                              {category.subCategory && category.subCategory.length > 0 ? (
                                                <div className="row row-cols-1 row-cols-md-2 g-3 w-100">
                                                  {category.subCategory.map((subcategory: SubcategoryData) => (
                                                    <div key={subcategory.id} className="col">
                                                      <div className="card h-100 w-100">
                                                        {isEditSubcategoryClicked && editSubcategoryId === subcategory.id ? (
                                                          // Edit Subcategory Form (in-place)
                                                          <div>
                                                            <h6 className="mb-3">Edit Subcategory</h6>
                                                            <div className="mb-3">
                                                              <label htmlFor="editSubcategoryName" className="form-label">
                                                                Subcategory Name
                                                              </label>
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                id="editSubcategoryName"
                                                                value={editSubcategoryName}
                                                                onChange={(e) => setEditSubcategoryName(e.target.value)}
                                                              />
                                                              {subcategoryError && (
                                                                <p className="error error-text">
                                                                  {subcategoryError}
                                                                </p>
                                                              )}
                                                            </div>
                                                            <div className="mb-3">
                                                              <label htmlFor="editSubcategoryDescription" className="form-label">
                                                                Description (Optional)
                                                              </label>
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                id="editSubcategoryDescription"
                                                                value={editSubcategoryDescription}
                                                                onChange={(e) => setEditSubcategoryDescription(e.target.value)}
                                                              />
                                                            </div>
                                                            <div className="d-flex justify-content-end">
                                                              <button
                                                                type="button"
                                                                className="btn btn-secondary me-2"
                                                                onClick={() => {
                                                                  setIsEditSubcategoryClicked(false);
                                                                  setEditSubcategoryId(null);
                                                                  setEditSubcategoryName("");
                                                                  setEditSubcategoryDescription("");
                                                                  setSubcategoryError("");
                                                                }}
                                                              >
                                                                Cancel
                                                              </button>
                                                              <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={onSubmitSubcategoryEdit}
                                                              >
                                                                Update
                                                              </button>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          // Normal Subcategory Display
                                                          <>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                              <h6 className="card-title">{subcategory.name}</h6>
                                                              <div className="btn-group">
                                                                <button
                                                                  type="button"
                                                                  className="btn btn-sm btn-outline-primary"
                                                                  onClick={() => handleEditSubcategory(subcategory, category.id)}
                                                                >
                                                                  Edit
                                                                </button>
                                                                <button
                                                                  type="button"
                                                                  className="btn btn-sm btn-outline-danger"
                                                                  onClick={() => handleDeleteSubcategory(subcategory.id)}
                                                                >
                                                                  Delete
                                                                </button>
                                                              </div>
                                                            </div>
                                                            {subcategory.description && (
                                                              <p className="card-text text-muted small">{subcategory.description}</p>
                                                            )}
                                                          </>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ))}
                                                </div>
                                              ) : (
                                                <p className="text-muted">No subcategories available</p>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="col-12 d-flex justify-content-center">
                                <p>No categories available</p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
