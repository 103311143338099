import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'; // Importing necessary functions from Redux Toolkit
import apiClient from '../../services/FetchClient'; // Importing the API client to handle HTTP requests
import { Address, IPaymentdetails } from './authSlice';

/*
 * Name: transactionSlice.ts
 * Description: Configuration of the Redux store for the application, incorporating slices for state management.
 * Version: 1.1.0
 */


interface TransactionById {
    id: number;

}
/*
// Define the interfaces for category and subcategory
interface Category {
    id: number;
    name: string;
}

interface SubCategory {
    id: number | null;
    name: string;
}
    */

// Define the CategoryData interface, which now includes an array of subCategories
interface CategoryData {
    id: number;
    name: string;
    description?: string;
    subCategory: SubcategoryData[]; // Updated to handle multiple subCategories
}
interface vehicleDetails {
    vehicleId: number;
    registrationNo: string;
    description: string;
}
interface VehicleInfoAPI {
    registrationNo: string;
    description: string;
}
type VehicleDetailsPayload = {
    vehicleDetails: VehicleInfoAPI[];
}

interface PaymentSourceAPI {
    paymentType: string;
    paymentAccountNumber: string;
}
type PaymentSourcePayload = {
    paymentSourceDetails: PaymentSourceAPI[];
};
interface AlertData {
    icon?: string;
    title?: string;
    text?: string;
    timer?: number;
    position?: string;
    confirmButtonText?: string; // Optional, to customize button text
}
interface profileInfo {
    businessName: string;
    nickname: string;
    imageURL: string;
    businessEmail: string;
    authEmail: string;
    authName: string;
    abn: number;
    acn: number;
    offNum: number;
    address: Address;
    vehicleDetails: vehicleDetails[];
}
// Define the structure for the transaction state in the Redux store
interface TransactionState {
    alertStatus: boolean;
    alertData: AlertData;
    profileInfo: profileInfo | null;
    validAuthZero: boolean;  // Represents if the system has a valid auth state
    transactionList: any[];  // List of transactions (could be more specific than 'any[]')
    regularTransactionList: any[];  // List of transactions (could be more specific than 'any[]')
    categories: CategoryData[];  // List of categories, each with subcategories
    userVehicle: vehicleDetails[];
    userPayment: IPaymentdetails[];
    status: boolean;  // Status flag for loading or success
    error: string;  // Error message in case of failure
    isPending: boolean;
    transactionDetails: {},
    yearToDateTotals: {
        totalIncome: number;
        totalExpenses: number;
    };
}

// Define the structure for a single transaction's data
/*
interface TransactionValue {
    documentNumber: string;  // Unique identifier for the transaction
    transactionType: "EXPENSE" | "INCOME";  // Type of transaction: either EXPENSE or INCOME
    description: string;  // Description of the transaction
    date: string;  // Transaction date (can be adjusted to Date type if needed)
    expenseAmount: number;  // Amount for expense type transaction
    expenseGst: number;  // GST for expense transaction
    incomeAmount: number;  // Amount for income type transaction
    incomeGst: number;  // GST for income transaction
    payUsing: string;  // Payment method used (could be an enum)
    category: Category;  // Associated category of the transaction
    subCategory: SubCategory;  // Associated subcategory of the transaction
}
    */

// Define the structure for a Fuel Transaction data
interface FuelTransaction {
    vehicle: string;
    fuelType: string;
    fuelRetailer: string;
    location: string;
    costPerLitre: number;
    date: string | Date;
    fuelQuantity: number;
    odometer?: number;
    totalCost: number;
    gst?: number;
    payUsing: string;
    suburb?: string;
    description?: string;
}

interface FindData {
    transactionType?: string;
    documentNumber?: string;
    fromDate?: Date;
    toDate?: Date;
    state?: string;
    description?: string;
    categoryId?: number;
    subCategoryId?: number;
    category?: string
    subCategory?: string
    fromAmount?: number;
    toAmount?: number;
    paidUsing?: string;
}

// Define the SubcategoryData type at the top of the file with other type definitions
export interface SubcategoryData {
    id: number;
    name: string;
    description?: string;
}

// Define the initial state for the transaction slice
const initialState: TransactionState = {
    alertData: {},
    profileInfo: null,
    transactionDetails: {},
    alertStatus: false,
    userVehicle: [],
    validAuthZero: false,  // Initial auth state is false
    transactionList: [],  // Empty transaction list at start
    regularTransactionList: [],  // Empty transaction list at start
    userPayment: [],
    categories: [  // Default categories structure with one placeholder category
        {
            id: 2,
            name: "",  // Empty name, should be populated when categories are fetched
            subCategory: [
                {
                    id: 0,
                    name: ""  // Empty subcategory, will be filled with actual data
                },
            ]
        }
    ],
    status: false,  // Status flag for loading or success
    error: "",  // Error message, initially empty
    isPending: false,
    yearToDateTotals: {
        totalIncome: 0,
        totalExpenses: 0,
    },
};

// Async thunk to fetch the transaction list from the API
export const getTransactionList = createAsyncThunk(
    'transaction/getList',  // Action type
    async (_, thunkAPI) => {
        try {
            const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/transaction`);  // Sending GET request to fetch transactions
            if (response.data) {
                return response.data;  // Return fetched data as payload
            } else {
                return thunkAPI.rejectWithValue('No data received');  // Reject if no data found
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error || 'Failed to fetch transactions');  // Reject on error
        }
    }
);


// Async thunk to fetch the regular transaction list from the API
export const getRegularTransactionList = createAsyncThunk(
    'transaction/getRegularTransactionList',  // Action type
    async (_, thunkAPI) => {
        try {
            const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/transaction?isRegular=True`);  // Sending GET request to fetch transactions
            if (response.data) {
                return response.data;  // Return fetched data as payload
            } else {
                return thunkAPI.rejectWithValue('No data received');  // Reject if no data found
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error || 'Failed to fetch regualar transactions');  // Reject on error
        }
    }
);

// Async thunk to fetch categories from the API
export const getCategories = createAsyncThunk(
    'transaction/getCategories',  // Action type
    async (_, thunkAPI) => {
        try {
            const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/category`);  // Sending GET request to fetch categories
            if (response.data) {
                return response.data;  // Return fetched category data as payload
            } else {
                return thunkAPI.rejectWithValue('No data received');  // Reject if no data found
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error || 'Failed to fetch categories');  // Reject on error
        }
    }
);

// Async thunk to fetch getUserVehicle from the API
export const getUserVehicle = createAsyncThunk(
    'transaction/getUserVehicle',  // Action type
    async (_, thunkAPI) => {
        try {
            const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/user-vehicle `);  // Sending GET request to fetch categories
            if (response.data) {
                return response.data;  // Return fetched category data as payload
            } else {
                return thunkAPI.rejectWithValue('No data received');  // Reject if no data found
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error || 'Failed to fetch user-vehicle');  // Reject on error
        }
    }
);

// Async thunk to fetch getUserPayment from the API
export const getUserPayment = createAsyncThunk(
    'transaction/getUserPayment',  // Action type
    async (_, thunkAPI) => {
        try {
            const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/user-payments`);  // Sending GET request to fetch payments
            if (response.data) {
                return response.data;  // Return fetched payments data as payload
            } else {
                return thunkAPI.rejectWithValue('No data received');  // Reject if no data found
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error || 'Failed to fetch user-payments');  // Reject on error
        }
    }
);


// Async thunk to fetch getUserDetails from the API
export const getUserDetails = createAsyncThunk(
    'transaction/getUserDetails',  // Action type
    async (_, thunkAPI) => {
        try {
            const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/userprofile`);  // Sending GET request to fetch categories
            if (response.data) {
                return response.data;  // Return fetched category data as payload
            } else {
                return thunkAPI.rejectWithValue('No data received');  // Reject if no data found
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error || 'Failed to fetch user-vehicle');  // Reject on error
        }
    }
);

// Async thunk to add a new transaction to the system
export const addTransaction = createAsyncThunk(
    "transaction/addTransaction",  // Action type
    async (body: { body: any, isRegularTransaction: boolean }, thunkAPI) => {  // Async function to send transaction data
        try {
            const response = await apiClient.post(
                `${process.env.REACT_APP_API_URL}/transaction${body.isRegularTransaction ? "?isRegular=True" : ""}`, body.body  // Sending POST request to add transaction
            );

            if (response.data) {
                // Return only the serializable parts of the response
                return {
                    data: response.data,
                    status: response.status,
                    statusText: response.statusText,
                    success: true
                };
            } else {
                return thunkAPI.rejectWithValue(response.data);  // Reject with error data if unsuccessful
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });  // Reject with error message if request fails
        }
    }
);
// Async thunk to add a new payments to the system
export const addPaymentDetails = createAsyncThunk(
    "transaction/addPaymentDetails",  // Action type
    async (body: PaymentSourcePayload, thunkAPI) => {  // Async function to send transaction data
        try {
            const response = await apiClient.post(
                `${process.env.REACT_APP_API_URL}/addPaymentDetails`, body  // Sending POST request to add transaction
            );

            if (response.data) {
                // Return only the serializable parts of the response
                return {
                    data: response.data,
                    status: response.status,
                    statusText: response.statusText,
                    success: true
                };
            } else {
                return thunkAPI.rejectWithValue(response.data);  // Reject with error data if unsuccessful
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });  // Reject with error message if request fails
        }
    }
);
// Async thunk to add a new transaction to the system
export const addVehicleDetails = createAsyncThunk(
    "transaction/addVehicleDetails",  // Action type
    async (body: VehicleDetailsPayload, thunkAPI) => {  // Async function to send transaction data
        try {
            const response = await apiClient.post(
                `${process.env.REACT_APP_API_URL}/addVehicleDetails`, body  // Sending POST request to add transaction
            );

            if (response.data) {
                // Return only the serializable parts of the response
                return {
                    data: response.data,
                    status: response.status,
                    statusText: response.statusText,
                    success: true
                };
            } else {
                return thunkAPI.rejectWithValue(response.data);  // Reject with error data if unsuccessful
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });  // Reject with error message if request fails
        }
    }
);
// Async thunk to add a new transaction to the system
export const transactionById = createAsyncThunk(
    "transaction/transactionById",  // Action type
    async (body: TransactionById, thunkAPI) => {  // Async function to send transaction data
        const { id } = body;
        try {
            const response = await apiClient.get(
                `${process.env.REACT_APP_API_URL}/transaction/${id}`
            );
            if (response.data) {
                // Return only the serializable parts of the response
                return {
                    data: response.data,
                    status: response.status,
                    statusText: response.statusText
                };
            } else {
                return thunkAPI.rejectWithValue(response.data);  // Reject with error data if unsuccessful
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });  // Reject with error message if request fails
        }
    }
);

// Async thunk to creaet-fuel-transaction to the system
export const createFuelTransaction = createAsyncThunk(
    "transaction/createFuelTransaction",  // Action type
    async (body: FuelTransaction, thunkAPI) => {  // Async function to send transaction data
        try {
            const response = await apiClient.post(
                `${process.env.REACT_APP_API_URL}/fuel/transaction`, body  // Sending POST request to add transaction
            );

            if (response.data) {
                // Return only the serializable parts of the response
                return {
                    data: response.data,
                    status: response.status,
                    statusText: response.statusText,
                    success: true
                };
            } else {
                return thunkAPI.rejectWithValue(response.data);  // Reject with error data if unsuccessful
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });  // Reject with error message if request fails
        }
    }
);

// Async thunk to creaet-fuel-transaction to the system
export const findTransaction = createAsyncThunk(
    "transaction/findTransaction",  // Action type
    async (body: FindData, thunkAPI) => {  // Async function to send transaction data
        try {
            const response = await apiClient.post(
                `${process.env.REACT_APP_API_URL}/transactions/find`, body  // Sending POST request to add transaction
            );

            if (response.data) {
                return response.data;  // Return response data if transaction is added successfully
            } else {
                return thunkAPI.rejectWithValue(response.data);  // Reject with error data if unsuccessful
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });  // Reject with error message if request fails
        }
    }
);

// Async thunk to fetch year-to-date totals
export const getYearToDateTotals = createAsyncThunk(
    "transaction/getYearToDateTotals",
    async (_, thunkAPI) => {
        try {
            const response = await apiClient.get(
                `${process.env.REACT_APP_API_URL}/transactions/year-to-date-totals`
            );
            if (response.data) {
                return response.data;
            } else {
                return thunkAPI.rejectWithValue('No data received');
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error || 'Failed to fetch year-to-date totals');
        }
    }
);

// Async thunk to fetch user-specific categories from the API
export const getUserCategories = createAsyncThunk(
    'transaction/getUserCategories',  // Action type
    async (_, thunkAPI) => {
        try {
            const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/user/category`);  // Sending GET request to fetch user categories
            if (response.data) {
                return response.data;  // Return fetched category data as payload
            } else {
                return thunkAPI.rejectWithValue('No data received');  // Reject if no data found
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error || 'Failed to fetch user categories');  // Reject on error
        }
    }
);

// Async thunk to fetch user-specific subcategories from the API
export const getUserSubcategories = createAsyncThunk(
    'transaction/getUserSubcategories',  // Action type
    async (categoryId: number, thunkAPI) => {
        try {
            const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/user/subcategory/${categoryId}`);  // Sending GET request to fetch user subcategories for a specific category
            if (response.data) {
                return { categoryId, subcategories: response.data };  // Return fetched subcategory data as payload
            } else {
                return thunkAPI.rejectWithValue('No data received');  // Reject if no data found
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error || 'Failed to fetch user subcategories');  // Reject on error
        }
    }
);

// Async thunk to add a new user category
export const addUserCategory = createAsyncThunk(
    'transaction/addUserCategory',
    async (categoryData: { name: string; description?: string }, thunkAPI) => {
        try {
            const response = await apiClient.post(
                `${process.env.REACT_APP_API_URL}/user/category`,
                categoryData
            );
            if (response.data) {
                return response.data;
            } else {
                return thunkAPI.rejectWithValue('No data received');
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data || 'Failed to add user category');
        }
    }
);

// Async thunk to add a new user subcategory
export const addUserSubcategory = createAsyncThunk(
    'transaction/addUserSubcategory',
    async (subcategoryData: { name: string; description?: string; userCategoryId: number }, thunkAPI) => {
        try {
            const response = await apiClient.post(
                `${process.env.REACT_APP_API_URL}/user/subcategory`,
                subcategoryData
            );
            if (response.data) {
                return response.data;
            } else {
                return thunkAPI.rejectWithValue('No data received');
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data || 'Failed to add user subcategory');
        }
    }
);

// Async thunk to update a category
export const updateCategory = createAsyncThunk(
    "transaction/updateCategory",
    async (category: { id: number | null, name: string, description: string }, thunkAPI) => {
        try {
            const response = await apiClient.put(
                `${process.env.REACT_APP_API_URL}/user/category/update/${category.id}`, 
                { name: category.name, description: category.description }
            );

            if (response.data) {
                return { ...response.data, success: true };
            } else {
                return thunkAPI.rejectWithValue({ success: false, error: "Failed to update category" });
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });
        }
    }
);

// Async thunk to delete a category
export const deleteCategory = createAsyncThunk(
    "transaction/deleteCategory",
    async (categoryId: number, thunkAPI) => {
        try {
            const response = await apiClient.delete(
                `${process.env.REACT_APP_API_URL}/user/category/delete/${categoryId}`
            );

            if (response.status === 200 || response.status === 204) {
                return { success: true };
            } else {
                return thunkAPI.rejectWithValue({ success: false, error: "Failed to delete category" });
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });
        }
    }
);

// Async thunk to update a subcategory
export const updateSubcategory = createAsyncThunk(
    "transaction/updateSubcategory",
    async (subcategory: { id: number | null, categoryId: number | null, name: string, description: string }, thunkAPI) => {
        try {
            const response = await apiClient.put(
                `${process.env.REACT_APP_API_URL}/user/subcategory/update/${subcategory.id}`, 
                { 
                    name: subcategory.name, 
                    description: subcategory.description,
                    categoryId: subcategory.categoryId
                }
            );

            if (response.data) {
                return { ...response.data, success: true };
            } else {
                return thunkAPI.rejectWithValue({ success: false, error: "Failed to update subcategory" });
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });
        }
    }
);

// Async thunk to delete a subcategory
export const deleteSubcategory = createAsyncThunk(
    "transaction/deleteSubcategory",
    async (subcategoryId: number, thunkAPI) => {
        try {
            const response = await apiClient.delete(
                `${process.env.REACT_APP_API_URL}/user/subcategory/delete/${subcategoryId}`
            );

            if (response.status === 200 || response.status === 204) {
                return { success: true };
            } else {
                return thunkAPI.rejectWithValue({ success: false, error: "Failed to delete subcategory" });
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });
        }
    }
);

// Async thunk to delete a vehicle
export const deleteVehicle = createAsyncThunk(
    "transaction/deleteVehicle",
    async (vehicleId: number, thunkAPI) => {
        try {
            const response = await apiClient.delete(
                `${process.env.REACT_APP_API_URL}/user-vehicle/${vehicleId}`
            );

            if (response.status === 200 || response.status === 204) {
                return { success: true };
            } else {
                return thunkAPI.rejectWithValue({ success: false, error: "Failed to delete vehicle" });
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });
        }
    }
);

// Async thunk to delete a payment source
export const deletePaymentSource = createAsyncThunk(
    "transaction/deletePaymentSource",
    async (paymentSourceId: number, thunkAPI) => {
        try {
            const response = await apiClient.delete(
                `${process.env.REACT_APP_API_URL}/user-payment/${paymentSourceId}`
            );

            if (response.status === 200 || response.status === 204) {
                return { success: true };
            } else {
                return thunkAPI.rejectWithValue({ success: false, error: "Failed to delete payment source" });
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ success: false, error: error.message });
        }
    }
);

// Define the slice with reducers
export const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        // Action to mark the system as authenticated (validAuthZero)
        systemValidAuthZero: (state) => {
            state.validAuthZero = true;  // Set validAuthZero to true
            localStorage.setItem("systemFormStatus", state.validAuthZero.toString());  // Store the authentication state in localStorage
            return state;
        },
        // Action to clear transaction details
        clearTransactionDetails: (state) => {
            state.transactionDetails = {};  // Clear transaction details
        },
        // Action to show an alert
        showAlert: (state, action: PayloadAction<{}>) => {
            state.alertStatus = true;  // Set validAuthZero to true
            state.alertData = action.payload
        },
        // Action to hide an alert
        hideAlert: (state) => {
            state.alertStatus = false;  // Set validAuthZero to true
        },
    },
    extraReducers: (builder) => {
        // Handle the async thunk actions for fetching transaction list
        builder
            .addCase(getTransactionList.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(getTransactionList.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.status = true;  // Set status to success
                state.error = '';  // Reset any previous errors
                state.isPending = false;
                
                // Convert amounts from cents to dollars for display
                const processedTransactions = action.payload.map(transaction => ({
                    ...transaction,
                    incomeAmount: transaction.incomeAmount ? transaction.incomeAmount / 100 : transaction.incomeAmount,
                    expenseAmount: transaction.expenseAmount ? transaction.expenseAmount / 100 : transaction.expenseAmount,
                    incomeGst: transaction.incomeGst ? transaction.incomeGst / 100 : transaction.incomeGst,
                    expenseGst: transaction.expenseGst ? transaction.expenseGst / 100 : transaction.expenseGst
                }));
                
                state.transactionList = processedTransactions;  // Update the transaction list with the processed data
            })
            .addCase(getTransactionList.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get transaction list';  // Set error message
                state.isPending = false;
            });
        builder
            .addCase(getRegularTransactionList.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(getRegularTransactionList.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.status = true;  // Set status to success
                state.error = '';  // Reset any previous errors
                state.isPending = false;
                
                // Convert amounts from cents to dollars for display
                const processedTransactions = action.payload.map(transaction => ({
                    ...transaction,
                    incomeAmount: transaction.incomeAmount ? transaction.incomeAmount / 100 : transaction.incomeAmount,
                    expenseAmount: transaction.expenseAmount ? transaction.expenseAmount / 100 : transaction.expenseAmount,
                    incomeGst: transaction.incomeGst ? transaction.incomeGst / 100 : transaction.incomeGst,
                    expenseGst: transaction.expenseGst ? transaction.expenseGst / 100 : transaction.expenseGst
                }));
                
                state.regularTransactionList = processedTransactions;  // Update the transaction list with the processed data
            })
            .addCase(getRegularTransactionList.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get transaction list';  // Set error message
                state.isPending = false;
            });

        // Handle the async thunk actions for fetching categories
        builder
            .addCase(getCategories.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(getCategories.fulfilled, (state, action: PayloadAction<CategoryData[]>) => {
                state.status = true;  // Set status to success
                state.categories = action.payload;
                state.isPending = false;
            })
            .addCase(getCategories.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get categories';  // Set error message
                state.isPending = false;
            });
        // Handle the async thunk actions for fetching vehicles
        builder
            .addCase(getUserVehicle.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(getUserVehicle.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = true;  // Set status to success
                state.userVehicle = action.payload.vehicleDetails;
                state.isPending = false;
            })
            .addCase(getUserVehicle.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get categories';  // Set error message
                state.isPending = false;
            });
        // Handle the async thunk actions for fetching payments
        builder
            .addCase(getUserPayment.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(getUserPayment.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = true;  // Set status to success
                state.userPayment = action.payload.paymentSourceEntities;
                state.isPending = false;
            })
            .addCase(getUserPayment.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get categories';  // Set error message
                state.isPending = false;
            });
        // Handle the async thunk actions for fetching categories
        builder
            .addCase(getUserDetails.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(getUserDetails.fulfilled, (state, action: PayloadAction<profileInfo>) => {
                state.status = true;  // Set status to success
                state.profileInfo = action.payload;
                state.isPending = false;
            })
            .addCase(getUserDetails.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get categories';  // Set error message
                state.isPending = false;
            });
        // Handle the async thunk actions for fetching categories
        builder
            .addCase(transactionById.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(transactionById.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = true;  // Set status to success
                
                // Process transaction details - convert amounts from cents to dollars
                const transactionData = action.payload.data;
                if (transactionData) {
                    // For regular transactions
                    if (transactionData.incomeAmount) {
                        transactionData.incomeAmount = transactionData.incomeAmount / 100;
                    }
                    if (transactionData.expenseAmount) {
                        transactionData.expenseAmount = transactionData.expenseAmount / 100;
                    }
                    if (transactionData.incomeGst) {
                        transactionData.incomeGst = transactionData.incomeGst / 100;
                    }
                    if (transactionData.expenseGst) {
                        transactionData.expenseGst = transactionData.expenseGst / 100;
                    }
                    
                    // For fuel transactions
                    if (transactionData.totalCost) {
                        transactionData.totalCost = transactionData.totalCost / 100;
                    }
                    if (transactionData.gst) {
                        transactionData.gst = transactionData.gst / 100;
                    }
                }
                
                state.transactionDetails = transactionData;
                state.isPending = false;
            })
            .addCase(transactionById.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get categories';  // Set error message
                state.isPending = false;
            });
        // Handle the async thunk actions for fetching categories
        builder
            .addCase(addVehicleDetails.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(addVehicleDetails.fulfilled, (state) => {
                state.status = true;  // Set status to success
                state.isPending = false;
            })
            .addCase(addVehicleDetails.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get categories';  // Set error message
                state.isPending = false;
            })

        // Handle the async thunk actions for adding a new transaction
        builder
            .addCase(addTransaction.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(addTransaction.fulfilled, (state) => {
                state.status = true;  // Set status to success
                state.error = '';  // Reset error on success
                state.isPending = false;
            })
            .addCase(addTransaction.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in add transaction';  // Set error message
                state.isPending = false;
            });

        // Handle the async thunk actions for Create Fuel Transaction
        builder
            .addCase(createFuelTransaction.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(createFuelTransaction.fulfilled, (state, action: any) => {
                state.status = true;  // Set status to success
                state.error = '';  // Reset error on success
                state.isPending = false;
            })
            .addCase(createFuelTransaction.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in create Fuel Transaction';  // Set error message
                state.isPending = false;
            });
        // Handle the async thunk actions for Create Fuel Transaction
        builder
            .addCase(findTransaction.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(findTransaction.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.status = true;  // Set status to success
                state.error = '';  // Reset any previous errors
                state.isPending = false;
                
                // Convert amounts from cents to dollars for display
                const processedTransactions = action.payload.map(transaction => ({
                    ...transaction,
                    incomeAmount: transaction.incomeAmount ? transaction.incomeAmount / 100 : transaction.incomeAmount,
                    expenseAmount: transaction.expenseAmount ? transaction.expenseAmount / 100 : transaction.expenseAmount,
                    incomeGst: transaction.incomeGst ? transaction.incomeGst / 100 : transaction.incomeGst,
                    expenseGst: transaction.expenseGst ? transaction.expenseGst / 100 : transaction.expenseGst
                }));
                
                state.transactionList = processedTransactions;  // Update the transaction list with the processed data
            })
            .addCase(findTransaction.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in find Transaction';  // Set error message
                state.isPending = false;
            });
        // Handle the async thunk actions for fetching year-to-date totals
        builder.addCase(getYearToDateTotals.fulfilled, (state, action: PayloadAction<any>) => {
            state.status = true;
            state.error = '';
            state.isPending = false;
            
            // Convert amounts from cents to dollars for display
            const totals = { ...action.payload };
            
            // Convert all monetary values from cents to dollars
            if (totals.totalIncomeWithGst) totals.totalIncomeWithGst = totals.totalIncomeWithGst / 100;
            if (totals.totalIncomeWithoutGst) totals.totalIncomeWithoutGst = totals.totalIncomeWithoutGst / 100;
            if (totals.totalExpensesWithGst) totals.totalExpensesWithGst = totals.totalExpensesWithGst / 100;
            if (totals.totalExpensesWithoutGst) totals.totalExpensesWithoutGst = totals.totalExpensesWithoutGst / 100;
            if (totals.totalIncomeGst) totals.totalIncomeGst = totals.totalIncomeGst / 100;
            if (totals.totalExpensesGst) totals.totalExpensesGst = totals.totalExpensesGst / 100;
            if (totals.netIncome) totals.netIncome = totals.netIncome / 100;
            
            state.yearToDateTotals = totals;
        });
        // Handle the async thunk actions for fetching user categories
        builder
            .addCase(getUserCategories.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(getUserCategories.fulfilled, (state, action: PayloadAction<CategoryData[]>) => {
                state.status = true;  // Set status to success
                if (action.payload && action.payload.length > 0) {
                    state.categories = action.payload;
                } else {
                    // If no user categories, set an empty array
                    state.categories = [];
                }
                state.isPending = false;
            })
            .addCase(getUserCategories.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get user categories';  // Set error message
                state.isPending = false;
                // If there's an error, set an empty array
                state.categories = [];
            });
        // Handle the async thunk actions for fetching user subcategories
        builder
            .addCase(getUserSubcategories.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(getUserSubcategories.fulfilled, (state, action: PayloadAction<{categoryId: number, subcategories: SubcategoryData[]}>) => {
                state.status = true;  // Set status to success
                // If user has custom subcategories for this category, use those
                if (action.payload.subcategories && action.payload.subcategories.length > 0) {
                    // Find the category and update its subcategories
                    const categoryIndex = state.categories.findIndex(cat => cat.id === action.payload.categoryId);
                    if (categoryIndex !== -1) {
                        state.categories[categoryIndex].subCategory = action.payload.subcategories;
                    }
                }
                state.isPending = false;
            })
            .addCase(getUserSubcategories.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred in get user subcategories';  // Set error message
                state.isPending = false;
            });
        // Handle the async thunk actions for adding user categories
        builder
            .addCase(addUserCategory.pending, (state) => {
                state.status = false;
                state.error = '';
                state.isPending = true;
            })
            .addCase(addUserCategory.fulfilled, (state) => {
                state.status = true;
                state.error = '';
                state.isPending = false;
            })
            .addCase(addUserCategory.rejected, (state, action) => {
                state.status = false;
                state.error = action.payload as string || 'An error occurred adding user category';
                state.isPending = false;
            });
        // Handle the async thunk actions for adding user subcategories
        builder
            .addCase(addUserSubcategory.pending, (state) => {
                state.status = false;
                state.error = '';
                state.isPending = true;
            })
            .addCase(addUserSubcategory.fulfilled, (state) => {
                state.status = true;
                state.error = '';
                state.isPending = false;
            })
            .addCase(addUserSubcategory.rejected, (state, action) => {
                state.status = false;
                state.error = action.payload as string || 'An error occurred adding user subcategory';
                state.isPending = false;
            });
        // Handle the async thunk actions for deleting a subcategory
        builder
            .addCase(deleteSubcategory.pending, (state) => {
                state.status = false;
                state.error = '';
                state.isPending = true;
            })
            .addCase(deleteSubcategory.fulfilled, (state) => {
                state.status = true;
                state.isPending = false;
            })
            .addCase(deleteSubcategory.rejected, (state) => {
                state.status = false;
                state.error = 'An error occurred while deleting subcategory';
                state.isPending = false;
            });
            
        // Handle the async thunk actions for deleting a vehicle
        builder
            .addCase(deleteVehicle.pending, (state) => {
                state.status = false;
                state.error = '';
                state.isPending = true;
            })
            .addCase(deleteVehicle.fulfilled, (state) => {
                state.status = true;
                state.isPending = false;
            })
            .addCase(deleteVehicle.rejected, (state) => {
                state.status = false;
                state.error = 'An error occurred while deleting vehicle';
                state.isPending = false;
            });
            
        // Handle the async thunk actions for deleting a payment source
        builder
            .addCase(deletePaymentSource.pending, (state) => {
                state.status = false;
                state.error = '';
                state.isPending = true;
            })
            .addCase(deletePaymentSource.fulfilled, (state) => {
                state.status = true;
                state.isPending = false;
            })
            .addCase(deletePaymentSource.rejected, (state) => {
                state.status = false;
                state.error = 'An error occurred while deleting payment source';
                state.isPending = false;
            })
            // Handle the async thunk actions for adding payment details
            .addCase(addPaymentDetails.pending, (state) => {
                state.status = false;  // Set status to loading
                state.error = '';  // Reset error while loading
                state.isPending = true;
            })
            .addCase(addPaymentDetails.fulfilled, (state) => {
                state.status = true;  // Set status to success
                state.isPending = false;
            })
            .addCase(addPaymentDetails.rejected, (state) => {
                state.status = false;  // Set status to failure
                state.error = 'An error occurred while adding payment details';  // Set error message
                state.isPending = false;
            });
    },
});

// Exporting actions to be used in components
export const { systemValidAuthZero, hideAlert, showAlert, clearTransactionDetails } = transactionSlice.actions;
// Export the reducer for integration with the Redux store
export default transactionSlice.reducer;
